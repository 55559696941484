// fr.hiji.metier.referential.services.back.QuizBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter, MultilingualString } from '@ic2/ic2-lib';
import { QuizItemBODTO } from '../entities/QuizItemBODTO';
import { TagForTradBODTO } from '../entities/TagForTradBODTO';
import { QuizBODTO } from '../entities/QuizBODTO';

@Injectable({
  providedIn: 'root',
})
export class QuizBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getQuizs(filter: GenericFilter): RpcRequestBuilder<ListWithCount<QuizItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'QuizBOService.getQuizs';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  quizNameAlreadyExists(name: MultilingualString, idQuiz: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'QuizBOService.quizNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'com.ic2.sc.MultilingualString', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idQuiz, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getQuiz(idQuiz: number): RpcRequestBuilder<QuizBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'QuizBOService.getQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idQuiz, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.back.QuizBODTO');
  }

  updateTranslationQuiz(quiz: QuizBODTO, tags: TagForTradBODTO[], langToUpdate: string): RpcRequestBuilder<QuizBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'QuizBOService.updateTranslationQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(quiz, 'fr.hiji.metier.referential.dto.back.QuizBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(tags, 'java.util.List<fr.hiji.metier.referential.dto.back.TagForTradBODTO>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langToUpdate, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.back.QuizBODTO');
  }

  saveQuiz(q: QuizBODTO): RpcRequestBuilder<QuizBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'QuizBOService.saveQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(q, 'fr.hiji.metier.referential.dto.back.QuizBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.back.QuizBODTO');
  }

}
