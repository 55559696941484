// ENTITY fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModalityQuiz } from './ModalityQuiz';
import { CertificationExam } from './CertificationExam';
import { ModalityQuizQuestionQCMAnswer } from './ModalityQuizQuestionQCMAnswer';
import { ModalityQuizQuestionOpenEndedAnswer } from './ModalityQuizQuestionOpenEndedAnswer';
import { ModalityDTO } from './ModalityDTO';
import { ModalityQuizQuestionDTO } from './ModalityQuizQuestionDTO';

export class ModalityQuizDTO {

  exam: CertificationExam | null = null;
  modality: ModalityDTO | null = null;
  modalityQuiz: ModalityQuiz | null = null;
  questions: ModalityQuizQuestionDTO[] | null = [];
  startAnsweringDate: Date | null = null;
  draft: boolean | null = false;
  openEndedAnswers: ModalityQuizQuestionOpenEndedAnswer[] | null = [];
  qcmSelectedAnswers: ModalityQuizQuestionQCMAnswer[] | null = [];

  constructor(init?:Partial<ModalityQuizDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizDTO'] = ModalityQuizDTO;

reverseMapping[ModalityQuizDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizDTO';

fields['fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizDTO']  = {
    exam: 'fr.hiji.metier.certif.entities.session.CertificationExam',
    modality: 'fr.hiji.metier.certif.dto.front.modality.ModalityDTO',
    modalityQuiz: 'fr.hiji.metier.certif.entities.modality.quiz.ModalityQuiz',
    questions: 'java.util.List<fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizQuestionDTO>',
    startAnsweringDate: 'java.time.Instant',
    draft: 'java.lang.Boolean',
    openEndedAnswers: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionOpenEndedAnswer>',
    qcmSelectedAnswers: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionQCMAnswer>'
};
