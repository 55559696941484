// fr.hiji.metier.mif.services.common.FormationSessionEmailServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { HijiEmail } from '../entities/HijiEmail';
import { FormationSessionEmailConfig } from '../entities/FormationSessionEmailConfig';

@Injectable({
  providedIn: 'root',
})
export class FormationSessionEmailService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  sendRelanceEmail(emailType: HijiEmail, idFormationSession: number): RpcRequestBuilder<FormationSessionEmailConfig> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionEmailService.sendRelanceEmail';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(emailType, 'fr.hiji.metier.tools.email.HijiEmail', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.entities.session.FormationSessionEmailConfig');
  }

  sendRelanceEmailQuiz(emailType: HijiEmail, idFormationSession: number, idFormationQuiz: number): RpcRequestBuilder<FormationSessionEmailConfig> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionEmailService.sendRelanceEmailQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(emailType, 'fr.hiji.metier.tools.email.HijiEmail', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationQuiz, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.entities.session.FormationSessionEmailConfig');
  }

}
