// ENTITY fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizResultDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModalityQuizTarget } from './ModalityQuizTarget';
import { ModalityQuizExam } from './ModalityQuizExam';
import { ModalityQuiz } from './ModalityQuiz';
import { CertificationExam } from './CertificationExam';
import { ModalityQuizQuestionQCMAnswer } from './ModalityQuizQuestionQCMAnswer';
import { ModalityQuizQuestionResultDTO } from './ModalityQuizQuestionResultDTO';
import { ModalityQuizQuestionOpenEndedAnswer } from './ModalityQuizQuestionOpenEndedAnswer';
import { ModalityDTO } from './ModalityDTO';
import { ModalityQuizExamSkillAvg } from './ModalityQuizExamSkillAvg';

export class ModalityQuizResultDTO {

  modality: ModalityDTO | null = null;
  modalityQuiz: ModalityQuiz | null = null;
  questions: ModalityQuizQuestionResultDTO[] | null = [];
  quizExam: ModalityQuizExam | null = null;
  exam: CertificationExam | null = null;
  averageSkills: ModalityQuizExamSkillAvg[] | null = [];
  targetSkills: ModalityQuizTarget[] | null = [];
  openEndedAnswers: ModalityQuizQuestionOpenEndedAnswer[] | null = [];
  qcmSelectedAnswers: ModalityQuizQuestionQCMAnswer[] | null = [];

  constructor(init?:Partial<ModalityQuizResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizResultDTO'] = ModalityQuizResultDTO;

reverseMapping[ModalityQuizResultDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizResultDTO';

fields['fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizResultDTO']  = {
    modality: 'fr.hiji.metier.certif.dto.front.modality.ModalityDTO',
    modalityQuiz: 'fr.hiji.metier.certif.entities.modality.quiz.ModalityQuiz',
    questions: 'java.util.List<fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizQuestionResultDTO>',
    quizExam: 'fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExam',
    exam: 'fr.hiji.metier.certif.entities.session.CertificationExam',
    averageSkills: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExamSkillAvg>',
    targetSkills: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizTarget>',
    openEndedAnswers: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionOpenEndedAnswer>',
    qcmSelectedAnswers: 'java.util.List<fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizQuestionQCMAnswer>'
};
