// ENTITY fr.hiji.metier.referential.dto.RepositoryBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { RepositoryObservableGestureBODTO } from './RepositoryObservableGestureBODTO';
import { Repository } from './Repository';
import { Campaign } from './Campaign';
import { Criteria } from './Criteria';
import { EMATemplateItemBODTO } from './EMATemplateItemBODTO';
import { Formation } from './Formation';
import { Modality } from './Modality';

export class RepositoryBODTO {

  repository: Repository | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  observableGestures: RepositoryObservableGestureBODTO[] | null = [];
  criterias: Criteria[] | null = [];
  campaigns: Campaign[] | null = [];
  emaTemplates: EMATemplateItemBODTO[] | null = [];
  formations: Formation[] | null = [];
  modalities: Modality[] | null = [];

  constructor(init?:Partial<RepositoryBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.RepositoryBODTO'] = RepositoryBODTO;

reverseMapping[RepositoryBODTO.name] = 'fr.hiji.metier.referential.dto.RepositoryBODTO';

fields['fr.hiji.metier.referential.dto.RepositoryBODTO']  = {
    repository: 'fr.hiji.metier.referential.entities.Repository',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    observableGestures: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryObservableGestureBODTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    campaigns: 'java.util.List<fr.hiji.metier.campaign.entities.Campaign>',
    emaTemplates: 'java.util.List<fr.hiji.metier.ema.dto.back.EMATemplateItemBODTO>',
    formations: 'java.util.List<fr.hiji.metier.mif.entities.formation.Formation>',
    modalities: 'java.util.List<fr.hiji.metier.certif.entities.modality.Modality>'
};
