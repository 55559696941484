// ENTITY fr.hiji.metier.home.dto.front.FormationEVADTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class FormationEVADTO {

  idFormation: number | null = 0;
  formationName: MultilingualString | null = new MultilingualString();
  idFormationSession: number | null = 0;
  formationSessionName: string | null = '';
  dateEVA: Date | null = null;
  user: UserFuncAvatarDTO | null = null;

  constructor(init?:Partial<FormationEVADTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.FormationEVADTO'] = FormationEVADTO;

reverseMapping[FormationEVADTO.name] = 'fr.hiji.metier.home.dto.front.FormationEVADTO';

fields['fr.hiji.metier.home.dto.front.FormationEVADTO']  = {
    idFormation: 'java.lang.Integer',
    formationName: 'com.ic2.sc.MultilingualString',
    idFormationSession: 'java.lang.Integer',
    formationSessionName: 'java.lang.String',
    dateEVA: 'java.time.Instant',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO'
};
