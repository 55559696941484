// ENTITY fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayExamOOG generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { HighlightType } from './HighlightType';
import { CriteriaType } from './CriteriaType';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class ModalityRoleplayExamOOG {

  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  observableGestureDescription: MultilingualString | null = new MultilingualString();
  idCriteria: number | null = 0;
  criteriaType: CriteriaType | null = null;
  value: number | null = 0;
  text: string | null = '';
  observed: boolean | null = false;
  answerDate: Date | null = null;
  order: number | null = 0;
  highlight: HighlightType | null = null;
  tags: ObservableGestureFlatTag[] | null = [];
  comment: string | null = '';

  constructor(init?:Partial<ModalityRoleplayExamOOG>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayExamOOG'] = ModalityRoleplayExamOOG;

reverseMapping[ModalityRoleplayExamOOG.name] = 'fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayExamOOG';

fields['fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayExamOOG']  = {
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    observableGestureDescription: 'com.ic2.sc.MultilingualString',
    idCriteria: 'java.lang.Integer',
    criteriaType: 'fr.hiji.metier.referential.entities.CriteriaType',
    value: 'java.lang.Integer',
    text: 'java.lang.String',
    observed: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    order: 'java.lang.Integer',
    highlight: 'fr.hiji.metier.referential.entities.HighlightType',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>',
    comment: 'java.lang.String'
};
