import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
//import { hijiVersion } from 'environments/version';
import { PreviousRouteService } from '../../services/previous-route.service';

@Component({
    selector: 'hiji-update-available',
    templateUrl: './update-available.component.html',
    styleUrls: ['./update-available.component.scss'],
    standalone: false
})
export class UpdateAvailableComponent implements OnInit {
  loading: boolean = false;
  //version = hijiVersion;
  constructor(private updates: SwUpdate, private previousRouteService: PreviousRouteService) {}

  ngOnInit(): void {}

  update() {
    if (this.loading) return;
    this.loading = true;
    this.updates.activateUpdate().then(() => {
      this.loading = false;
      let root = new RegExp('^.*//[^/]+').exec(window.location.href)[0];
      console.log('root is ', root);
      console.log('redirectUrl is ', localStorage.getItem('redirectUrl'));
      //if (this.previousRouteService.previousUrl !== null) root += this.previousRouteService.previousUrl;
      if (localStorage.getItem('redirectUrl') !== null) {
        root += localStorage.getItem('redirectUrl');
        localStorage.removeItem('redirectUrl');
      }
      window.location.assign(root); //go to root or previous page
    });
  }
}
