import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { EnvService, IRPC, L } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { Observable, Subject, Subscription } from 'rxjs';
import { ModalService } from '../components/modal/modal.service';
import { HijiBundle } from '../ic2/entities/HijiBundle';
import { HijiRight } from '../ic2/entities/HijiRight';
import { ModulesDTO } from '../ic2/entities/ModulesDTO';
import { UserBundle } from '../ic2/entities/UserBundle';
import { CoreService } from '../ic2/services/CoreService';
import { FormationEventCodeLoginService } from '../ic2/services/FormationEventCodeLoginService';
import { RefreshSessionService } from '../ic2/services/RefreshSessionService';
import { SurveyService } from '../ic2/services/SurveyService';
import { Config } from '../tools/Config';
import { LocalizedDatePipe } from '../tools/localized-date.pipe';
import { Ic2ToastrService } from './ic2-toastr.service';

export class LoginEvent {
  userBundle: UserBundle;
  permitLogin: boolean = true;
  redirectUrl: string = null;
  onLoginAction: () => Promise<any | any[]>;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static BACKOFFICE_ORIGIN: 'BACKOFFICE' = 'BACKOFFICE';
  public static FRONTOFFICE_ORIGIN: 'FRONTOFFICE' = 'FRONTOFFICE';
  userBundle: UserBundle = null;
  modules: ModulesDTO = null;
  hijiBundle: HijiBundle = null;
  hasPerimeter: boolean = false;
  admin: boolean = false;

  // store the URL so we can redirect after logging in
  loginEvent: Subject<LoginEvent> = new Subject<LoginEvent>();
  logoutEvent: Subject<void> = new Subject<void>();
  getBundleSubscription: Subscription = null;

  code: string = null;
  codeEvent: string = null;
  idRefreshSessionTimeout: any = null;

  tryToRetrieveBundleIfCookieIsStillOk = true;

  nbRetry = 0;

  static has(bundle: UserBundle, ...roles: HijiRight[]) {
    if (!bundle) return false;
    for (const role of roles) {
      for (const possessedRole of bundle.rights) {
        if (role.id === possessedRole.idRight) return true;
      }
    }
    return false;
  }
  static hasAccessToBackoffice(bundle: UserBundle) {
    return AuthService.has(bundle, HijiRight.ADMIN, HijiRight.GESTIONNAIRE, HijiRight.CONSULTATION_STATS, HijiRight.TRADUCTEUR);
  }

  constructor(
    private router: Router,
    public coreService: CoreService,
    public irpc: IRPC,
    private cookieService: CookieService,
    private surveyService: SurveyService,
    private ic2ToastrService: Ic2ToastrService,
    private translate: TranslateService,
    private env: EnvService<Config>,
    private refreshSessionService: RefreshSessionService,
    private formationEventCodeLoginService: FormationEventCodeLoginService,
    private modalService: ModalService,
    private localizedDatePipe: LocalizedDatePipe
  ) {}

  isLoggedIn(): Observable<boolean> {
    return new Observable((observer) => {
      if (this.getBundleSubscription !== null) {
        console.log('already connecting, adding to bundle subscription');
        this.getBundleSubscription.add(() => {
          console.log('bundle subscription done, running added observer');
          if (this.userBundle !== undefined && this.userBundle !== null) observer.next(true);
          else observer.next(false);
          observer.complete();
        });
        return;
      }

      if (this.code !== null) {
        console.log('code auth');
        this.getBundleSubscription = this.surveyService
          .loginWithCode(this.code)
          .onBusinessErrorCode('code_expired', (err) => {
            this.ic2ToastrService.show(this.translate.instant('common.auth.survey.Ce code à expiré car vous avez déjà répondu au questionnaire'), {
              classname: 'bg-warning text-white',
              delay: 10000,
            });
          })
          .onBusinessErrorCode('campaign_expired', (err) => {
            this.ic2ToastrService.show(
              this.translate.instant('common.auth.survey.Ce code corresponds à une campagne inactive (expirée ou pas encore démarée)'),
              {
                classname: 'bg-warning text-white',
                delay: 10000,
              }
            );
          })
          .onErrorAlwaysDo((err) => {
            this.code = null;
            this.getBundleSubscription = null;
            console.error(err);
            observer.next(false);
            observer.complete();
          })
          .execute((data) => {
            this.code = null;
            this.getBundleSubscription = null;
            this.loginWith(data.ub, () => {
              observer.next(true);
              observer.complete();
            });
          });
        return;
      }

      if (this.codeEvent !== null) {
        console.log('codeEvent auth');
        this.getBundleSubscription = this.formationEventCodeLoginService
          .loginWithCode(this.codeEvent)
          .onBusinessErrorCode('code_expired', (err) => {
            this.ic2ToastrService.show(this.translate.instant('common.auth.survey.Ce code à expiré car vous avez déjà répondu au questionnaire'), {
              classname: 'bg-warning text-white',
              delay: 10000,
            });
          })
          .onErrorAlwaysDo((err) => {
            this.codeEvent = null;
            this.getBundleSubscription = null;
            console.error(err);
            observer.next(false);
            observer.complete();
          })
          .execute((data) => {
            this.codeEvent = null;
            this.getBundleSubscription = null;
            this.loginWith(data.ub, () => {
              observer.next(true);
              observer.complete();
            });
          });
        return;
      }

      //console.log('COOKIES :: ', this.cookiesService.getAll());

      if (this.userBundle !== undefined && this.userBundle !== null) {
        observer.next(true);
        observer.complete();
        return;
      }

      if (this.tryToRetrieveBundleIfCookieIsStillOk) {
        console.log('tryToRetrieveBundleIfCookieIsStillOk');
        this.tryToRetrieveBundleIfCookieIsStillOk = false;
        //Recuperer le bundle
        this.getBundleSubscription = this.coreService
          .getBundle()
          .onError((err) => {
            this.getBundleSubscription = null;
            console.error(err);
            console.error('error on tryToRetrieveBundleIfCookieIsStillOk, cookie might have expired');
            observer.next(false);
            observer.complete();
            this.logout(); //logout on error to clean token
          })
          .execute((data) => {
            this.getBundleSubscription = null;
            const event = new LoginEvent();
            event.userBundle = data;
            event.redirectUrl = null;
            this.loginEvent.next(event);
            if (event.permitLogin) {
              //We directly refresh because we might be almost at the end
              //this.refreshSessionService.refreshSession().execute((data) => {});
              //We don't now because we have the time when the token expires in the HijiBundle and we'll refresh just before
              this.bundleReceived(data);
              if (event.redirectUrl !== null) {
                console.log('bundle auto redirect to ' + event.redirectUrl);
                this.router.navigate([event.redirectUrl]);
              }
              if (event.onLoginAction) {
                console.log('exec onLoginAction ', event);
                event.onLoginAction().then(() => {
                  console.log('exec onLoginAction done');
                  observer.next(true);
                  observer.complete();
                });
              } else {
                console.log('getBundle complete ', event);
                observer.next(true);
                observer.complete();
              }
            } else {
              L.v('AuthService', 'Login aborted because of the observer');
            }
          });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  loginWith(data: UserBundle, onLoginAction: () => void = null, useRedirectUrl: boolean = true) {
    const event = new LoginEvent();
    event.userBundle = data;
    event.redirectUrl = null;
    this.loginEvent.next(event);
    if (event.permitLogin) {
      this.bundleReceived(data);
      if (event.onLoginAction) {
        console.log('exec event onLoginAction ', event);
        event.onLoginAction().then(() => {
          console.log('exec event onLoginAction done');
          console.log('exec local onLoginAction');
          if (onLoginAction) onLoginAction();
          console.log('exec local onLoginAction done');
          if (event.redirectUrl !== null) {
            console.log('loginWith auto redirect to ' + event.redirectUrl);
            this.router.navigate([event.redirectUrl]);
          }
        });
      } else {
        console.log('exec local onLoginAction ', event);
        if (onLoginAction) onLoginAction();
        console.log('exec local onLoginAction done');
        console.log(event, useRedirectUrl);
        if (event.redirectUrl !== null && useRedirectUrl) {
          console.log('loginWith auto redirect to ' + event.redirectUrl);
          this.router.navigate([event.redirectUrl]);
        }
      }
    } else {
      L.v('AuthService', 'Login aborted because of the observer');
    }
  }

  private bundleReceived(data: UserBundle) {
    console.log('bundle received', data);
    this.userBundle = data;
    this.hijiBundle = this.userBundle.data as HijiBundle;
    this.modules = this.hijiBundle.modules;
    this.admin = this.has(HijiRight.ADMIN);
    this.hasPerimeter = !this.admin && this.hijiBundle.idsPerimetre.length > 0;

    this.tryToRetrieveBundleIfCookieIsStillOk = true;
    this.setupAutomaticRefresh();
  }

  private setupAutomaticRefresh() {
    if (!this.userBundle) {
      throw new Error('UserBundle is null when setupAutomaticRefresh');
    }
    const expDate = (this.userBundle.data as HijiBundle).tokenExpiration;
    if (expDate === null) {
      L.v('AuthService', 'setupAutomaticRefresh - token has no expiration date, ignore automatic refresh');
      return;
    }
    const expireInSecs = (expDate.getTime() - new Date().getTime()) / 1000;
    const nbSecBeforeRefresh = (this.nbRetry === 0 ? 0.8 : 0.5) * expireInSecs;
    //Si token dure 2h : refresh 1h30 après si rate, retry a 1h45 puis retry a 1h52.5 puis retry a 1h56.25 puis logout
    clearTimeout(this.idRefreshSessionTimeout);
    if (nbSecBeforeRefresh > 0) {
      L.v('AuthService', 'Setting up automatic refresh in ' + nbSecBeforeRefresh + ' seconds (retry ' + this.nbRetry + ', expire in ' + expireInSecs + ')');
      this.idRefreshSessionTimeout = setTimeout(() => {
        L.v('AuthService', 'Automatic Refresh token');
        //TODO au moment du refresh on devrait bloquer les autres query
        //ou s'assurer au moins que le mec est inactif ?
        //le risque c'est d'avoir côté serveur un check du cache sur l'ancien token alors qu'on viens de set le nouveau et donc déco
        this.refreshSessionService
          .refreshSession()
          .onError((err) => {
            L.e(err);
            this.nbRetry++;
            if (this.nbRetry > 3)
              this.modalService.openModal(
                'warning',
                this.translate.instant(
                  "common.auth.Votre session a expiré et n'a pas pu être renouvelée malgré les {{nb}} tentatives, vous serez déconnecté automatiquement à {{time}}, merci de vous reconnecter manuellement",
                  { nb: this.nbRetry, time: this.localizedDatePipe.transform(expDate, 'shortTime') }
                ),
                () => {}
              );
            else {
              this.setupAutomaticRefresh();
            }
          })
          .execute((data) => {
            this.nbRetry = 0;
            if (this.userBundle) (this.userBundle.data as HijiBundle).tokenExpiration = data;
            else console.error('userBundle is null when refreshing session');
            this.setupAutomaticRefresh();
          });
      }, nbSecBeforeRefresh * 1000);
    } else {
      L.e('AuthService', 'setupAutomaticRefresh - token already expired, logging out');
      console.error('Token expired ? ', expDate, new Date());
      this.logout(() => this.router.navigate(['/']));
    }
  }

  login(email: string, password: string, origin: 'BACKOFFICE' | 'FRONTOFFICE'): Observable<LoginEvent> {
    return new Observable((observer) => {
      this.coreService
        .loginWithOrigin(email, password, origin)
        .onError((err) => {
          observer.error(err);
        })
        .execute((data) => {
          const event = new LoginEvent();
          event.userBundle = data.ub;
          if (localStorage.getItem('redirectUrl') !== null) {
            event.redirectUrl = localStorage.getItem('redirectUrl');
            localStorage.removeItem('redirectUrl');
          }
          observer.next(event);
          observer.complete();
          this.loginEvent.next(event);
          if (event.permitLogin) {
            this.bundleReceived(data.ub);

            if (event.onLoginAction) {
              console.log('exec event onLoginAction ', event);
              event.onLoginAction().then(() => {
                console.log('exec event onLoginAction done');
                if (event.redirectUrl !== null) {
                  console.log('login auto redirect to ' + event.redirectUrl);
                  this.router.navigate([event.redirectUrl]);
                }
              });
            } else {
              if (event.redirectUrl !== null) {
                console.log('login auto redirect to ' + event.redirectUrl);
                this.router.navigate([event.redirectUrl]);
              }
            }
          } else {
            L.v('AuthService', 'Login aborted because of the observer');
          }
        });
    });
  }

  has(...roles: HijiRight[]) {
    return AuthService.has(this.userBundle, ...roles);
  }

  logout(onLogout: () => void = null): void {
    console.log('logging out');

    const afterLogout = () => {
      this.nbRetry = 0;
      clearTimeout(this.idRefreshSessionTimeout);
      //localStorage.removeItem('token');
      //this.cookieService.remove('token');
      //this.irpc.authToken = null;
      this.userBundle = null;
      this.hijiBundle = null;
      this.modules = null;
      this.admin = false;
      this.hasPerimeter = false;
      //('logged out');
      this.logoutEvent.next();
      //this.router.navigate(['connexion']);
    };
    if (this.userBundle) {
      console.log('disconnecting from server');
      this.coreService
        .disconnect()
        .defaultOnErrorAnd((err) => {
          //Still execute even if error so we can router.navigate and avoid null userbundle errors
          if (onLogout) onLogout();
          afterLogout(); //we might need to do it before onLogout because of the checks on guards or ctors for userBundle value
        })
        .execute((data) => {
          if (onLogout) onLogout();
          afterLogout(); //we might need to do it before onLogout because of the checks on guards or ctors for userBundle value
        });
    } else {
      if (onLogout) onLogout();
      afterLogout(); //we might need to do it before onLogout because of the checks on guards or ctors for userBundle value
    }
  }

  isUser(email: String): boolean {
    return this.userBundle && this.userBundle.user.email === email;
  }
}
