// DB ENUM fr.hiji.metier.campaign.entities.ObservationType generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class ObservationType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly AUTO_OBSERVATION = new ObservationType(1, "Auto-Observation", "AUTO_OBSERVATION");
  static readonly MANAGER = new ObservationType(2, "Manager", "MANAGER");
  static readonly PAIR = new ObservationType(3, "Pair", "PAIR");
  static readonly COLLABORATEUR = new ObservationType(4, "Collaborateur", "COLLABORATEUR");
  static readonly EXTERNE = new ObservationType(5, "Externe", "EXTERNE");

  static readonly values = [
    ObservationType.AUTO_OBSERVATION,
 
    ObservationType.MANAGER,
 
    ObservationType.PAIR,
 
    ObservationType.COLLABORATEUR,
 
    ObservationType.EXTERNE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ObservationType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ObservationType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.campaign.entities.ObservationType'] = ObservationType;
reverseMapping[ObservationType.name] = 'fr.hiji.metier.campaign.entities.ObservationType';
