// ENTITY fr.hiji.metier.conf.dto.front.submodules.FrontConfigurationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FrontConfigurationDTO {

  hideHomeTeamButtons: boolean | null = false;
  displayFunctionFront: boolean | null = false;
  hideProgressMenu: boolean | null = false;
  hideDiscoverMenu: boolean | null = false;
  hideVisualizeMenu: boolean | null = false;
  showContactHelp: boolean | null = false;
  hideHomeEncouragePictureCard: boolean | null = false;
  hideHierarchyInMyProfile: boolean | null = false;

  constructor(init?:Partial<FrontConfigurationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.submodules.FrontConfigurationDTO'] = FrontConfigurationDTO;

reverseMapping[FrontConfigurationDTO.name] = 'fr.hiji.metier.conf.dto.front.submodules.FrontConfigurationDTO';

fields['fr.hiji.metier.conf.dto.front.submodules.FrontConfigurationDTO']  = {
    hideHomeTeamButtons: 'java.lang.Boolean',
    displayFunctionFront: 'java.lang.Boolean',
    hideProgressMenu: 'java.lang.Boolean',
    hideDiscoverMenu: 'java.lang.Boolean',
    hideVisualizeMenu: 'java.lang.Boolean',
    showContactHelp: 'java.lang.Boolean',
    hideHomeEncouragePictureCard: 'java.lang.Boolean',
    hideHierarchyInMyProfile: 'java.lang.Boolean'
};
