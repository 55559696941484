// fr.hiji.metier.translate.services.mif.ImportExportTranslationMIFBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { LangueUpdatedChangedDTO } from '../entities/LangueUpdatedChangedDTO';

@Injectable({
  providedIn: 'root',
})
export class ImportExportTranslationMIFBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  exportMIFTranslations(idMIF: number, langues: string[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationMIFBOService.exportMIFTranslations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idMIF, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langues, 'java.util.List<java.lang.String>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  importMIFTranslations(): RpcRequestBuilder<LangueUpdatedChangedDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationMIFBOService.importMIFTranslations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
