// fr.hiji.metier.mif.services.back.event.FormationEventBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FormationEventObservationType } from '../entities/FormationEventObservationType';
import { FormationEventItemBODTO } from '../entities/FormationEventItemBODTO';
import { FormationEventDeployDTO } from '../entities/FormationEventDeployDTO';
import { FormationEventParticipantImportBODTO } from '../entities/FormationEventParticipantImportBODTO';
import { FormationEventBODTO } from '../entities/FormationEventBODTO';

@Injectable({
  providedIn: 'root',
})
export class FormationEventBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getDistinctFormationEventTags(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventBOService.getDistinctFormationEventTags';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  transformEmailCSVToUserList(): RpcRequestBuilder<FormationEventParticipantImportBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventBOService.transformEmailCSVToUserList';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.event.FormationEventParticipantImportBODTO');
  }

  getFormationEvents(filter: GenericFilter): RpcRequestBuilder<ListWithCount<FormationEventItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventBOService.getFormationEvents';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getFormationEvent(id: number): RpcRequestBuilder<FormationEventBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventBOService.getFormationEvent';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.event.FormationEventBODTO');
  }

  saveFormationEvent(dto: FormationEventBODTO): RpcRequestBuilder<FormationEventBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventBOService.saveFormationEvent';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.back.event.FormationEventBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.event.FormationEventBODTO');
  }

  deploy(idFormationEvent: number, type: FormationEventObservationType, relance: boolean, withMail: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventBOService.deploy';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationEvent, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(type, 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservationType', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(relance, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(withMail, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getFormationEventDeploy(idFormationEvent: number): RpcRequestBuilder<FormationEventDeployDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventBOService.getFormationEventDeploy';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationEvent, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
