// ENTITY fr.hiji.metier.mif.entities.event.FormationEventDiffusionList generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationEventDiffusionList {

  idFormationEvent: number | null = 0;
  idDiffusionList: number | null = 0;
  nbParticipantSlots: number | null = 0;
  nbQueueSlots: number | null = 0;

  constructor(init?:Partial<FormationEventDiffusionList>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.event.FormationEventDiffusionList'] = FormationEventDiffusionList;

reverseMapping[FormationEventDiffusionList.name] = 'fr.hiji.metier.mif.entities.event.FormationEventDiffusionList';

fields['fr.hiji.metier.mif.entities.event.FormationEventDiffusionList']  = {
    idFormationEvent: 'java.lang.Integer',
    idDiffusionList: 'java.lang.Integer',
    nbParticipantSlots: 'java.lang.Integer',
    nbQueueSlots: 'java.lang.Integer'
};
