// ENTITY fr.hiji.metier.certif.entities.modality.interview.ModalityInterviewOGPerTag generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityInterviewOGPerTag {

  idModality: number | null = 0;
  idTag: number | null = 0;
  nb: number | null = 0;

  constructor(init?:Partial<ModalityInterviewOGPerTag>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.interview.ModalityInterviewOGPerTag'] = ModalityInterviewOGPerTag;

reverseMapping[ModalityInterviewOGPerTag.name] = 'fr.hiji.metier.certif.entities.modality.interview.ModalityInterviewOGPerTag';

fields['fr.hiji.metier.certif.entities.modality.interview.ModalityInterviewOGPerTag']  = {
    idModality: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    nb: 'java.lang.Integer'
};
