// DB ENUM fr.hiji.metier.mif.entities.formation.FormationState generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FormationState {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly TO_BE_REGISTRED = new FormationState(1, "À effectuer", "TO_BE_REGISTRED");
  static readonly ENROLLED = new FormationState(2, "En cours", "ENROLLED");
  static readonly DONE_INSIDE_HIJI = new FormationState(3, "Terminée", "DONE_INSIDE_HIJI");
  static readonly DONE_OUTSIDE_HIJI = new FormationState(4, "Terminée", "DONE_OUTSIDE_HIJI");

  static readonly values = [
    FormationState.TO_BE_REGISTRED,
 
    FormationState.ENROLLED,
 
    FormationState.DONE_INSIDE_HIJI,
 
    FormationState.DONE_OUTSIDE_HIJI
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FormationState.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FormationState.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.formation.FormationState'] = FormationState;
reverseMapping[FormationState.name] = 'fr.hiji.metier.mif.entities.formation.FormationState';
