// ENTITY fr.hiji.metier.dashboard.dto.DashboardBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { DashboardFormationBODTO } from './DashboardFormationBODTO';
import { ParticipationStatsBySurveyBODTO } from './ParticipationStatsBySurveyBODTO';
import { DashboardCampaignBODTO } from './DashboardCampaignBODTO';
import { DashboardCertificationBODTO } from './DashboardCertificationBODTO';

export class DashboardBODTO {

  nbActiveSessions: number | null = 0;
  nbConnectedUserOnLast24H: number | null = 0;
  nbConnectedUserOnLast7Days: number | null = 0;
  nbConnectedUserInYear: number | null = 0;
  nbConnectedUserLastMonth: number | null = 0;
  nbConnectedUserFromStart: number | null = 0;
  participationStatsBySurvey: ParticipationStatsBySurveyBODTO[] | null = [];
  campaign180: DashboardCampaignBODTO[] | null = [];
  campaign360: DashboardCampaignBODTO[] | null = [];
  enquete: DashboardCampaignBODTO[] | null = [];
  certifications: DashboardCertificationBODTO[] | null = [];
  formations: DashboardFormationBODTO[] | null = [];
  warningMessage: string | null = '';

  constructor(init?:Partial<DashboardBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.dashboard.dto.DashboardBODTO'] = DashboardBODTO;

reverseMapping[DashboardBODTO.name] = 'fr.hiji.metier.dashboard.dto.DashboardBODTO';

fields['fr.hiji.metier.dashboard.dto.DashboardBODTO']  = {
    nbActiveSessions: 'java.lang.Integer',
    nbConnectedUserOnLast24H: 'java.lang.Integer',
    nbConnectedUserOnLast7Days: 'java.lang.Integer',
    nbConnectedUserInYear: 'java.lang.Integer',
    nbConnectedUserLastMonth: 'java.lang.Integer',
    nbConnectedUserFromStart: 'java.lang.Integer',
    participationStatsBySurvey: 'java.util.List<fr.hiji.metier.dashboard.dto.ParticipationStatsBySurveyBODTO>',
    campaign180: 'java.util.List<fr.hiji.metier.dashboard.dto.DashboardCampaignBODTO>',
    campaign360: 'java.util.List<fr.hiji.metier.dashboard.dto.DashboardCampaignBODTO>',
    enquete: 'java.util.List<fr.hiji.metier.dashboard.dto.DashboardCampaignBODTO>',
    certifications: 'java.util.List<fr.hiji.metier.dashboard.dto.DashboardCertificationBODTO>',
    formations: 'java.util.List<fr.hiji.metier.dashboard.dto.DashboardFormationBODTO>',
    warningMessage: 'java.lang.String'
};
