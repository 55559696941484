// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModuleTMSBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleTMSBODTO {

  moduleTMSEnabled: boolean | null = false;
  trainingPathEnabled: boolean | null = false;
  diffusionListEnabled: boolean | null = false;
  trainingPathVisibleInFront: boolean | null = false;

  constructor(init?:Partial<ModuleTMSBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModuleTMSBODTO'] = ModuleTMSBODTO;

reverseMapping[ModuleTMSBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModuleTMSBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModuleTMSBODTO']  = {
    moduleTMSEnabled: 'java.lang.Boolean',
    trainingPathEnabled: 'java.lang.Boolean',
    diffusionListEnabled: 'java.lang.Boolean',
    trainingPathVisibleInFront: 'java.lang.Boolean'
};
