import { Component, input } from '@angular/core';

@Component({
    selector: 'hiji-speech-bubble-comment',
    templateUrl: './speech-bubble-comment.component.html',
    styleUrl: './speech-bubble-comment.component.scss',
    standalone: false
})
export class SpeechBubbleCommentComponent {
  idUser = input<number>();
  firstName = input<string>();
  lastName = input<string>();
  avatarExtension = input<string>();
  creationDate = input<Date>();
  message = input<string>();
  title = input<string>();
}
