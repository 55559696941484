// ENTITY fr.hiji.metier.certif.dto.front.modality.ExamLiteDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ModalityType } from './ModalityType';

export class ExamLiteDTO {

  idExam: number | null = 0;
  idModality: number | null = 0;
  orderModality: number | null = 0;
  type: ModalityType | null = null;
  name: MultilingualString | null = new MultilingualString();
  needAccessGrantFromEvaluator: boolean | null = false;
  accessible: boolean | null = false;
  done: boolean | null = false;
  doneDate: Date | null = null;
  success: boolean | null = false;
  withInstructions: boolean | null = false;
  catchUp: boolean | null = false;
  roleplayAnswered: boolean | null = false;
  draft: boolean | null = false;
  shown: boolean | null = false;
  showCorrection: boolean | null = false;
  showScore: boolean | null = false;
  timerEnabled: boolean | null = false;
  timerDurationMinutes: number | null = 0;
  startAnsweringDate: Date | null = null;

  constructor(init?:Partial<ExamLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.ExamLiteDTO'] = ExamLiteDTO;

reverseMapping[ExamLiteDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.ExamLiteDTO';

fields['fr.hiji.metier.certif.dto.front.modality.ExamLiteDTO']  = {
    idExam: 'java.lang.Integer',
    idModality: 'java.lang.Integer',
    orderModality: 'java.lang.Integer',
    type: 'fr.hiji.metier.certif.entities.modality.ModalityType',
    name: 'com.ic2.sc.MultilingualString',
    needAccessGrantFromEvaluator: 'java.lang.Boolean',
    accessible: 'java.lang.Boolean',
    done: 'java.lang.Boolean',
    doneDate: 'java.time.Instant',
    success: 'java.lang.Boolean',
    withInstructions: 'java.lang.Boolean',
    catchUp: 'java.lang.Boolean',
    roleplayAnswered: 'java.lang.Boolean',
    draft: 'java.lang.Boolean',
    shown: 'java.lang.Boolean',
    showCorrection: 'java.lang.Boolean',
    showScore: 'java.lang.Boolean',
    timerEnabled: 'java.lang.Boolean',
    timerDurationMinutes: 'java.lang.Integer',
    startAnsweringDate: 'java.time.Instant'
};
