// ENTITY fr.hiji.metier.mif.entities.event.FormationEventParticipant generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationEventParticipant {

  idFormationEvent: number | null = 0;
  idUser: number | null = 0;
  idRegistration: number | null = 0;
  uniqueCode: string | null = '';
  lastRelanceDate: Date | null = null;
  receiveRelance: boolean | null = false;
  acquisitionBeforeDeployedDate: Date | null = null;
  acquisitionBeforeDeployed: boolean | null = false;
  satisfactionDeployedDate: Date | null = null;
  satisfactionDeployed: boolean | null = false;
  acquisitionAfterDeployedDate: Date | null = null;
  acquisitionAfterDeployed: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<FormationEventParticipant>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.event.FormationEventParticipant'] = FormationEventParticipant;

reverseMapping[FormationEventParticipant.name] = 'fr.hiji.metier.mif.entities.event.FormationEventParticipant';

fields['fr.hiji.metier.mif.entities.event.FormationEventParticipant']  = {
    idFormationEvent: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    idRegistration: 'java.lang.Integer',
    uniqueCode: 'java.lang.String',
    lastRelanceDate: 'java.time.Instant',
    receiveRelance: 'java.lang.Boolean',
    acquisitionBeforeDeployedDate: 'java.time.Instant',
    acquisitionBeforeDeployed: 'java.lang.Boolean',
    satisfactionDeployedDate: 'java.time.Instant',
    satisfactionDeployed: 'java.lang.Boolean',
    acquisitionAfterDeployedDate: 'java.time.Instant',
    acquisitionAfterDeployed: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
