import { Component, Input, OnInit } from '@angular/core';
import { OrganisationType } from '../../ic2/entities/OrganisationType';

@Component({
    selector: 'hiji-organisation-icon',
    templateUrl: './organisation-icon.component.html',
    styleUrls: ['./organisation-icon.component.scss'],
    standalone: false
})
export class OrganisationIconComponent implements OnInit {
  @Input()
  type: OrganisationType = null;
  OrganisationType: typeof OrganisationType = OrganisationType;

  constructor() {}

  ngOnInit(): void {}
}
