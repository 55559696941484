// ENTITY fr.hiji.metier.conf.dto.back.submodules.Module360BODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class Module360BODTO {

  moduleCampaign360: boolean | null = false;
  campain360FeaturePopulation: number | null = 0;
  campaign360ExternalEnabled: boolean | null = false;
  campaign360BackofficeRespectAnonymous: boolean | null = false;
  campaign360AllowOnlyOneAnonymousInATargetType: boolean | null = false;
  campaign360Allowed: boolean | null = false;
  campaign360CustomAllowed: boolean | null = false;
  campaign360InfoCollaborator: MultilingualString | null = new MultilingualString();
  campaign360InfoPair: MultilingualString | null = new MultilingualString();
  campaign360InfoManager: MultilingualString | null = new MultilingualString();
  campaign360InfoExternal: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<Module360BODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.Module360BODTO'] = Module360BODTO;

reverseMapping[Module360BODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.Module360BODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.Module360BODTO']  = {
    moduleCampaign360: 'java.lang.Boolean',
    campain360FeaturePopulation: 'java.lang.Integer',
    campaign360ExternalEnabled: 'java.lang.Boolean',
    campaign360BackofficeRespectAnonymous: 'java.lang.Boolean',
    campaign360AllowOnlyOneAnonymousInATargetType: 'java.lang.Boolean',
    campaign360Allowed: 'java.lang.Boolean',
    campaign360CustomAllowed: 'java.lang.Boolean',
    campaign360InfoCollaborator: 'com.ic2.sc.MultilingualString',
    campaign360InfoPair: 'com.ic2.sc.MultilingualString',
    campaign360InfoManager: 'com.ic2.sc.MultilingualString',
    campaign360InfoExternal: 'com.ic2.sc.MultilingualString'
};
