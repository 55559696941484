// ENTITY fr.hiji.metier.home.dto.front.FeedbackUserReactionDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FeedbackReaction } from './FeedbackReaction';

export class FeedbackUserReactionDTO {

  firstName: string | null = '';
  lastName: string | null = '';
  reaction: FeedbackReaction | null = null;
  message: string | null = '';

  constructor(init?:Partial<FeedbackUserReactionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.FeedbackUserReactionDTO'] = FeedbackUserReactionDTO;

reverseMapping[FeedbackUserReactionDTO.name] = 'fr.hiji.metier.home.dto.front.FeedbackUserReactionDTO';

fields['fr.hiji.metier.home.dto.front.FeedbackUserReactionDTO']  = {
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    reaction: 'fr.hiji.metier.fbs.entities.FeedbackReaction',
    message: 'java.lang.String'
};
