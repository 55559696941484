// ENTITY fr.hiji.metier.reporting.dto.back.ReportEMAConfigBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ReportEMAConfig } from './ReportEMAConfig';
import { UserOrganisationReportingEMADTO } from './UserOrganisationReportingEMADTO';
import { ReportEMAConfigTemplateBODTO } from './ReportEMAConfigTemplateBODTO';

export class ReportEMAConfigBODTO {

  config: ReportEMAConfig | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  templates: ReportEMAConfigTemplateBODTO[] | null = [];
  userOrganisation: UserOrganisationReportingEMADTO[] | null = [];

  constructor(init?:Partial<ReportEMAConfigBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.ReportEMAConfigBODTO'] = ReportEMAConfigBODTO;

reverseMapping[ReportEMAConfigBODTO.name] = 'fr.hiji.metier.reporting.dto.back.ReportEMAConfigBODTO';

fields['fr.hiji.metier.reporting.dto.back.ReportEMAConfigBODTO']  = {
    config: 'fr.hiji.metier.reporting.entities.ema.ReportEMAConfig',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    templates: 'java.util.List<fr.hiji.metier.reporting.dto.back.ReportEMAConfigTemplateBODTO>',
    userOrganisation: 'java.util.List<fr.hiji.metier.reporting.dto.back.UserOrganisationReportingEMADTO>'
};
