// ENTITY fr.hiji.metier.mif.dto.front.trainer.ParticipantQuizResults generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class ParticipantQuizResults {

  idFormationQuiz: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  done: boolean | null = false;

  constructor(init?:Partial<ParticipantQuizResults>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.ParticipantQuizResults'] = ParticipantQuizResults;

reverseMapping[ParticipantQuizResults.name] = 'fr.hiji.metier.mif.dto.front.trainer.ParticipantQuizResults';

fields['fr.hiji.metier.mif.dto.front.trainer.ParticipantQuizResults']  = {
    idFormationQuiz: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    done: 'java.lang.Boolean'
};
