// ENTITY fr.hiji.metier.verbatim.dto.OOGCategoriesDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class OOGCategoriesDTO {

  idObservation: number | null = 0;
  idObservableGesture: number | null = 0;
  idsVerbatimCategories: number[] | null = [];
  text: string | null = '';
  categorized: boolean | null = false;

  constructor(init?:Partial<OOGCategoriesDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.verbatim.dto.OOGCategoriesDTO'] = OOGCategoriesDTO;

reverseMapping[OOGCategoriesDTO.name] = 'fr.hiji.metier.verbatim.dto.OOGCategoriesDTO';

fields['fr.hiji.metier.verbatim.dto.OOGCategoriesDTO']  = {
    idObservation: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    idsVerbatimCategories: 'java.util.List<java.lang.Integer>',
    text: 'java.lang.String',
    categorized: 'java.lang.Boolean'
};
