// ENTITY fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserFormationResultBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CampaignPDIFormationBODTO } from './CampaignPDIFormationBODTO';
import { CampaignPDIUserFormation } from './CampaignPDIUserFormation';

export class CampaignPDIUserFormationResultBODTO {

  mailSent: boolean | null = false;
  formationsDispo: CampaignPDIFormationBODTO[] | null = [];
  formationsProposee: CampaignPDIFormationBODTO[] | null = [];
  formationsChoisies: CampaignPDIUserFormation[] | null = [];

  constructor(init?:Partial<CampaignPDIUserFormationResultBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserFormationResultBODTO'] = CampaignPDIUserFormationResultBODTO;

reverseMapping[CampaignPDIUserFormationResultBODTO.name] = 'fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserFormationResultBODTO';

fields['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserFormationResultBODTO']  = {
    mailSent: 'java.lang.Boolean',
    formationsDispo: 'java.util.List<fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIFormationBODTO>',
    formationsProposee: 'java.util.List<fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIFormationBODTO>',
    formationsChoisies: 'java.util.List<fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserFormation>'
};
