// ENTITY fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CertificationStatsTagValueBODTO } from './CertificationStatsTagValueBODTO';
import { CertificationStatsModalityInfoBODTO } from './CertificationStatsModalityInfoBODTO';
import { CertificationQuestionStatsBODTO } from './CertificationQuestionStatsBODTO';

export class CertificationQuizStatsBODTO {

  result: CertificationStatsModalityInfoBODTO | null = null;
  averageSkills: CertificationStatsTagValueBODTO[] | null = [];
  targetSkills: CertificationStatsTagValueBODTO[] | null = [];
  questions: CertificationQuestionStatsBODTO[] | null = [];
  averageScorePercent: number | null = null;

  constructor(init?:Partial<CertificationQuizStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizStatsBODTO'] = CertificationQuizStatsBODTO;

reverseMapping[CertificationQuizStatsBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizStatsBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizStatsBODTO']  = {
    result: 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityInfoBODTO',
    averageSkills: 'java.util.List<fr.hiji.metier.certif.dto.back.CertificationStatsTagValueBODTO>',
    targetSkills: 'java.util.List<fr.hiji.metier.certif.dto.back.CertificationStatsTagValueBODTO>',
    questions: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuestionStatsBODTO>',
    averageScorePercent: 'java.lang.Double'
};
