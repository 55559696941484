// ENTITY fr.hiji.metier.ema.entities.observation.EMAObservationRepository generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAObservationRepository {

  idEMAObservation: number | null = 0;
  idRepository: number | null = 0;

  constructor(init?:Partial<EMAObservationRepository>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.observation.EMAObservationRepository'] = EMAObservationRepository;

reverseMapping[EMAObservationRepository.name] = 'fr.hiji.metier.ema.entities.observation.EMAObservationRepository';

fields['fr.hiji.metier.ema.entities.observation.EMAObservationRepository']  = {
    idEMAObservation: 'java.lang.Integer',
    idRepository: 'java.lang.Integer'
};
