<div class="align-items-center d-flex flex-column justify-content-center" style="min-height: 100vh !important">
  <div class="row">
    <div class="col">
      <i class="fa-light fa-heart-broken fs-1"></i>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <h1>
        {{ 'common.notfound.404 Page non trouvée' | translate }}
      </h1>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col my-fs-2">
      {{ url }}
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <a routerLink="/">{{ "common.notfound.Revenir à l'accueil" | translate }}</a>
    </div>
  </div>
</div>
