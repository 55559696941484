// ENTITY fr.hiji.metier.user.dto.front.coach.UserInCoachPerimeterDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserInCoachPerimeterDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  avatarExtension: string | null = '';
  functionName: string | null = '';

  constructor(init?:Partial<UserInCoachPerimeterDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.coach.UserInCoachPerimeterDTO'] = UserInCoachPerimeterDTO;

reverseMapping[UserInCoachPerimeterDTO.name] = 'fr.hiji.metier.user.dto.front.coach.UserInCoachPerimeterDTO';

fields['fr.hiji.metier.user.dto.front.coach.UserInCoachPerimeterDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    functionName: 'java.lang.String'
};
