// fr.hiji.metier.fbs.services.FeedbackStatBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FeedbackStatBODTO } from '../entities/FeedbackStatBODTO';

@Injectable({
  providedIn: 'root',
})
export class FeedbackStatBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFeedbackStats(): RpcRequestBuilder<FeedbackStatBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackStatBOService.getFeedbackStats';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.fbs.dto.back.FeedbackStatBODTO');
  }

}
