// ENTITY fr.hiji.metier.ema.entities.template.EMATemplateRepository generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMATemplateRepository {

  idEMATemplate: number | null = 0;
  idRepository: number | null = 0;
  order: number | null = 0;
  complementary: boolean | null = false;
  usedByDefault: boolean | null = false;

  constructor(init?:Partial<EMATemplateRepository>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.template.EMATemplateRepository'] = EMATemplateRepository;

reverseMapping[EMATemplateRepository.name] = 'fr.hiji.metier.ema.entities.template.EMATemplateRepository';

fields['fr.hiji.metier.ema.entities.template.EMATemplateRepository']  = {
    idEMATemplate: 'java.lang.Integer',
    idRepository: 'java.lang.Integer',
    order: 'java.lang.Integer',
    complementary: 'java.lang.Boolean',
    usedByDefault: 'java.lang.Boolean'
};
