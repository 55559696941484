// ENTITY fr.hiji.metier.reporting.entities.ema.ReportEMAConfig generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FrequenceRapport } from './FrequenceRapport';

export class ReportEMAConfig {

  idReportEMAConfig: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  frequenceRapport: FrequenceRapport | null = null;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = 0;
  usePerimeterToSendReports: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<ReportEMAConfig>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities.ema.ReportEMAConfig'] = ReportEMAConfig;

reverseMapping[ReportEMAConfig.name] = 'fr.hiji.metier.reporting.entities.ema.ReportEMAConfig';

fields['fr.hiji.metier.reporting.entities.ema.ReportEMAConfig']  = {
    idReportEMAConfig: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    frequenceRapport: 'fr.hiji.metier.reporting.dto.pdf180.FrequenceRapport',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    usePerimeterToSendReports: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
