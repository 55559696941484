// ENTITY fr.hiji.metier.reporting.dto.back.Report180ConfigCampaignBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { Report180ConfigCampaign } from './Report180ConfigCampaign';

export class Report180ConfigCampaignBODTO {

  campaign: Report180ConfigCampaign | null = null;
  name: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;

  constructor(init?:Partial<Report180ConfigCampaignBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.Report180ConfigCampaignBODTO'] = Report180ConfigCampaignBODTO;

reverseMapping[Report180ConfigCampaignBODTO.name] = 'fr.hiji.metier.reporting.dto.back.Report180ConfigCampaignBODTO';

fields['fr.hiji.metier.reporting.dto.back.Report180ConfigCampaignBODTO']  = {
    campaign: 'fr.hiji.metier.reporting.entities._180.Report180ConfigCampaign',
    name: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean'
};
