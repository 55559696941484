// ENTITY fr.hiji.metier.ema.dto.front.EMATeamMemberObjectiveResultDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMATeamMemberObjectiveResultDTO {

  manager: string[] | null = [];
  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  objective: number | null = null;
  result: number | null = null;
  rank: number | null = 0;
  creationDate: Date | null = null;

  constructor(init?:Partial<EMATeamMemberObjectiveResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMATeamMemberObjectiveResultDTO'] = EMATeamMemberObjectiveResultDTO;

reverseMapping[EMATeamMemberObjectiveResultDTO.name] = 'fr.hiji.metier.ema.dto.front.EMATeamMemberObjectiveResultDTO';

fields['fr.hiji.metier.ema.dto.front.EMATeamMemberObjectiveResultDTO']  = {
    manager: 'java.util.List<java.lang.String>',
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    objective: 'java.lang.Double',
    result: 'java.lang.Double',
    rank: 'java.lang.Integer',
    creationDate: 'java.time.Instant'
};
