// ENTITY fr.hiji.metier.campaign.dto.front.ExchangeDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ExchangeObservationDTO } from './ExchangeObservationDTO';
import { SkillStatDTO } from './SkillStatDTO';
import { ObservableGestureDTO } from './ObservableGestureDTO';
import { Criteria } from './Criteria';

export class ExchangeDTO {

  idExchange: number | null = 0;
  conclusion: string | null = '';
  creationDate: Date | null = null;
  observations: ExchangeObservationDTO[] | null = [];
  observableGestures: ObservableGestureDTO[] | null = [];
  criterias: Criteria[] | null = [];
  teamStats: SkillStatDTO[] | null = [];
  observedAvatarExtension: string | null = '';
  managerAvatarExtension: string | null = '';
  idUserObserved: number | null = 0;
  userObserved: string | null = '';
  idUserManager: number | null = 0;
  userManager: string | null = '';
  firstExchange: boolean | null = false;

  constructor(init?:Partial<ExchangeDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.ExchangeDTO'] = ExchangeDTO;

reverseMapping[ExchangeDTO.name] = 'fr.hiji.metier.campaign.dto.front.ExchangeDTO';

fields['fr.hiji.metier.campaign.dto.front.ExchangeDTO']  = {
    idExchange: 'java.lang.Integer',
    conclusion: 'java.lang.String',
    creationDate: 'java.time.Instant',
    observations: 'java.util.List<fr.hiji.metier.campaign.dto.front.ExchangeObservationDTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservableGestureDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    teamStats: 'java.util.List<fr.hiji.metier.campaign.dto.front.SkillStatDTO>',
    observedAvatarExtension: 'java.lang.String',
    managerAvatarExtension: 'java.lang.String',
    idUserObserved: 'java.lang.Integer',
    userObserved: 'java.lang.String',
    idUserManager: 'java.lang.Integer',
    userManager: 'java.lang.String',
    firstExchange: 'java.lang.Boolean'
};
