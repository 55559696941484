// fr.hiji.metier.mif.services.back.formation.stats.FormationStatsFilterBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from '../entities/UserFuncAvatarDTO';
import { FormationSessionStatsLiteDTO } from '../entities/FormationSessionStatsLiteDTO';
import { FormationLiteDTO } from '../entities/FormationLiteDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationStatsFilterBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTrainers(): RpcRequestBuilder<UserFuncAvatarDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsFilterBOService.getTrainers';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getSessions(formations: FormationLiteDTO[], idUser: number): RpcRequestBuilder<FormationSessionStatsLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationStatsFilterBOService.getSessions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(formations, 'java.util.List<fr.hiji.metier.mif.dto.front.FormationLiteDTO>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
