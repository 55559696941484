// fr.hiji.metier.ema.services.front.EMARepositoryServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EMARepositoryInfoDTO } from '../entities/EMARepositoryInfoDTO';

@Injectable({
  providedIn: 'root',
})
export class EMARepositoryService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getRepositoryInfos(idUser: number, idRepository: number): RpcRequestBuilder<EMARepositoryInfoDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMARepositoryService.getRepositoryInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idRepository, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.front.repositoryinfo.EMARepositoryInfoDTO');
  }

}
