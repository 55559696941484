// ENTITY fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { CertificationCandidateResult } from './CertificationCandidateResult';
import { CertificationSession } from './CertificationSession';
import { CertificationSessionModalityBODTO } from './CertificationSessionModalityBODTO';
import { CertificationSessionCandidate } from './CertificationSessionCandidate';
import { CertificationSessionExamBODTO } from './CertificationSessionExamBODTO';
import { CertificationSessionAssessor } from './CertificationSessionAssessor';

export class CertificationSessionBODTO {

  certificationSession: CertificationSession | null = null;
  assessors: CertificationSessionAssessor[] | null = [];
  candidates: CertificationSessionCandidate[] | null = [];
  modalities: CertificationSessionModalityBODTO[] | null = [];
  users: UserFuncAvatarDTO[] | null = [];
  exams: CertificationSessionExamBODTO[] | null = [];
  results: CertificationCandidateResult[] | null = [];

  constructor(init?:Partial<CertificationSessionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionBODTO'] = CertificationSessionBODTO;

reverseMapping[CertificationSessionBODTO.name] = 'fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionBODTO';

fields['fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionBODTO']  = {
    certificationSession: 'fr.hiji.metier.certif.entities.session.CertificationSession',
    assessors: 'java.util.List<fr.hiji.metier.certif.entities.session.CertificationSessionAssessor>',
    candidates: 'java.util.List<fr.hiji.metier.certif.entities.session.CertificationSessionCandidate>',
    modalities: 'java.util.List<fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionModalityBODTO>',
    users: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>',
    exams: 'java.util.List<fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionExamBODTO>',
    results: 'java.util.List<fr.hiji.metier.certif.entities.CertificationCandidateResult>'
};
