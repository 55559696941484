// ENTITY fr.hiji.metier.mif.entities.session.FormationSessionParticipantExpectation generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationSessionParticipantExpectation {

  idFormationSessionParticipantExpectation: number | null = 0;
  idFormationSession: number | null = 0;
  idUser: number | null = 0;
  expectation: string | null = '';
  idUserCreator: number | null = 0;
  note: number | null = 0;
  observed: boolean | null = false;

  constructor(init?:Partial<FormationSessionParticipantExpectation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionParticipantExpectation'] = FormationSessionParticipantExpectation;

reverseMapping[FormationSessionParticipantExpectation.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionParticipantExpectation';

fields['fr.hiji.metier.mif.entities.session.FormationSessionParticipantExpectation']  = {
    idFormationSessionParticipantExpectation: 'java.lang.Integer',
    idFormationSession: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    expectation: 'java.lang.String',
    idUserCreator: 'java.lang.Integer',
    note: 'java.lang.Integer',
    observed: 'java.lang.Boolean'
};
