<div class="row mt-3">
  <div class="col">
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto d-flex flex-column align-items-center text-end">
          <hiji-user-avatar
            style="width: 50px; height: 50px"
            [idUser]="idUser()"
            [defaultBlueAvatar]="true"
            [avatarExtension]="avatarExtension()"
          ></hiji-user-avatar>
          <span class="my-fs-1 text-center text-muted d-inline-block text-wrap" style="width: 75px">
            <span class="fw-bold">{{ firstName() }} {{ lastName() }}</span>
            @if (creationDate() !== null) {
              <br />
              {{ creationDate() | localizedDate }}
            }
          </span>
        </div>
        <div class="col">
          @if (title() !== null) {
            <div class="row">
              <div class="col my-fs-2 text-muted">{{ title() }}</div>
            </div>
          }
          <div class="p-3 rounded-3 speech-bubble border">
            <div class="row">
              <div class="col" style="white-space: pre-line">
                {{ message() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
