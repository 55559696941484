<div class="row mb-2 align-items-center">
  @if (title) {
    <div class="col-auto">
      <h4 class="m-0">
        @if (icon) {
          <i class="me-2 {{ icon }}"></i>
        }
        {{ title }}
      </h4>
    </div>
  }
</div>
@if (!alreadyExist) {
  @if (image) {
    <hiji-translate-img
      [translation]="name"
      [sourceLang]="sourceLang"
      [destinationLang]="destinationLang"
      [textAreaRows]="textAreaRows"
      [title]="'common.translate.Image' | translate"
    ></hiji-translate-img>
  } @else {
    <hiji-translate-textarea
      [sourceLang]="sourceLang"
      [destinationLang]="destinationLang"
      [textAreaRows]="textAreaRows"
      [(translation)]="name"
      [title]="'common.translate.Nom' | translate"
    ></hiji-translate-textarea>

    @if (description != null && description.obj[sourceLang] !== undefined) {
      <hiji-translate-textarea
        [sourceLang]="sourceLang"
        [destinationLang]="destinationLang"
        [textAreaRows]="textAreaRows"
        [(translation)]="description"
        [title]="'common.translate.Description' | translate"
      ></hiji-translate-textarea>
    }
  }
} @else {
  <div class="row">
    <div class="col-auto">
      <div class="alert alert-info mt-2" role="alert">
        {{ alreadyExistTitle }}
      </div>
    </div>
  </div>
}
