// ENTITY fr.hiji.metier.reporting.dto.mif.PPTFilterConfigMIFBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class PPTFilterConfigMIFBODTO {

  idFormation: number | null = 0;
  idSessions: number[] | null = [];
  start: Date | null = null;
  end: Date | null = null;
  idUserTrainer: number | null = 0;
  locale: string | null = '';
  withTrainerSlides: boolean | null = false;
  withSessionSlides: boolean | null = false;

  constructor(init?:Partial<PPTFilterConfigMIFBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.mif.PPTFilterConfigMIFBODTO'] = PPTFilterConfigMIFBODTO;

reverseMapping[PPTFilterConfigMIFBODTO.name] = 'fr.hiji.metier.reporting.dto.mif.PPTFilterConfigMIFBODTO';

fields['fr.hiji.metier.reporting.dto.mif.PPTFilterConfigMIFBODTO']  = {
    idFormation: 'java.lang.Integer',
    idSessions: 'java.util.List<java.lang.Integer>',
    start: 'java.time.LocalDate',
    end: 'java.time.LocalDate',
    idUserTrainer: 'java.lang.Integer',
    locale: 'java.lang.String',
    withTrainerSlides: 'java.lang.Boolean',
    withSessionSlides: 'java.lang.Boolean'
};
