// ENTITY fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerActionsDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { FormationSessionParticipantAction } from './FormationSessionParticipantAction';
import { Criteria } from './Criteria';

export class FormationSessionTrainerActionsDTO {

  actions: FormationSessionParticipantAction[] | null = [];
  idFormationSession: number | null = 0;
  criteria: Criteria | null = null;
  users: UserFuncAvatarDTO[] | null = [];

  constructor(init?:Partial<FormationSessionTrainerActionsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerActionsDTO'] = FormationSessionTrainerActionsDTO;

reverseMapping[FormationSessionTrainerActionsDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerActionsDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerActionsDTO']  = {
    actions: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionParticipantAction>',
    idFormationSession: 'java.lang.Integer',
    criteria: 'fr.hiji.metier.referential.entities.Criteria',
    users: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>'
};
