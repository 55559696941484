// ENTITY fr.hiji.metier.user.dto.ImportManagerDiorResultDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ImportManagerDiorResultDTO {

  usersNotFound: string[] | null = null;
  managersNotFound: string[] | null = null;
  nbRelationCreated: number | null = 0;
  nbRelationAlreadyExisting: number | null = 0;

  constructor(init?:Partial<ImportManagerDiorResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.ImportManagerDiorResultDTO'] = ImportManagerDiorResultDTO;

reverseMapping[ImportManagerDiorResultDTO.name] = 'fr.hiji.metier.user.dto.ImportManagerDiorResultDTO';

fields['fr.hiji.metier.user.dto.ImportManagerDiorResultDTO']  = {
    usersNotFound: 'java.util.Set<java.lang.String>',
    managersNotFound: 'java.util.Set<java.lang.String>',
    nbRelationCreated: 'java.lang.Integer',
    nbRelationAlreadyExisting: 'java.lang.Integer'
};
