<div *ngIf="!hasAnyTagsToShow()">
  {{ 'common.radar.Pas de compétences associées' | translate }}
</div>
<div class="position-relative" *ngIf="hasAnyTagsToShow()">
  <button
    class="btn btn-sm btn-primary position-absolute"
    ngbTooltip="{{ 'common.radar.Réinitialiser' | translate }}"
    (click)="resetShowChildren()"
    *ngIf="this.showChildrenOf !== null"
  >
    <i class="fa-light fa-refresh"></i>
  </button>
  <h3 class="text-center pt-2" [ngStyle]="{ color: getCurrentTag().colorCode }" *ngIf="this.showChildrenOf !== null">{{ getCurrentTag().name | ms }}</h3>
  <hiji-download-graph [imgName]="'radar'">
    <hiji-radar-chart
      [data]="reworkedData"
      [criteriaType]="criteriaType"
      (titleClick)="radarTitleClick($event)"
      [canBeExpanded]="canBeExpanded.bind(this)"
    ></hiji-radar-chart>
  </hiji-download-graph>
</div>
