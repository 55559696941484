import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnvService, MultilingualString } from '@ic2/ic2-lib';
import { HijiBundle } from '../../../ic2/entities/HijiBundle';
import { TranslationBOService } from '../../../ic2/services/TranslationBOService';
import { AuthService } from '../../../services/auth.service';
import { Config } from '../../../tools/Config';

@Component({
    selector: 'hiji-translate-textarea',
    templateUrl: './translate-textarea.component.html',
    styleUrl: './translate-textarea.component.scss',
    standalone: false
})
export class TranslateTextareaComponent {
  @Input() sourceLang: string;
  @Input() destinationLang: string;
  @Input() textAreaRows: number = 5;
  @Input() title: string;

  loadingTrad: boolean = false;

  private _translation: MultilingualString;

  @Input()
  get translation(): MultilingualString {
    return this._translation;
  }
  set translation(value: MultilingualString) {
    this._translation = value;
    this.translationChange.emit(value);
  }

  @Output()
  translationChange = new EventEmitter<MultilingualString>();

  constructor(
    private config: EnvService<Config>,
    private authService: AuthService,
    private translationBOService: TranslationBOService
  ) {}

  translate() {
    if (this.translation.obj[this.sourceLang] == null) return;
    this.loadingTrad = true;
    this.checkLang();
    this.translationBOService
      .translateAPI(this.translation.obj[this.sourceLang], this.sourceLang, this.destinationLang)
      .onErrorUseDefault()
      .onErrorAlwaysDo(() => (this.loadingTrad = false))
      .execute((data) => {
        this.translation.obj[this.destinationLang] = data;
        this.loadingTrad = false;
      });
  }

  checkLang() {
    if (this.destinationLang == null) this.destinationLang = (this.authService.userBundle.data as HijiBundle).hijiUser.locale;

    if (this.sourceLang == null) this.sourceLang = this.config.configuration.defaultLanguage;
  }
}
