// ENTITY fr.hiji.metier.campaign.entities._360.Campaign360Relance generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Campaign360Relance {

  idCampaign: number | null = 0;
  relanceDate: Date | null = null;
  relanceDone: boolean | null = false;
  manual: boolean | null = false;

  constructor(init?:Partial<Campaign360Relance>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities._360.Campaign360Relance'] = Campaign360Relance;

reverseMapping[Campaign360Relance.name] = 'fr.hiji.metier.campaign.entities._360.Campaign360Relance';

fields['fr.hiji.metier.campaign.entities._360.Campaign360Relance']  = {
    idCampaign: 'java.lang.Integer',
    relanceDate: 'java.time.Instant',
    relanceDone: 'java.lang.Boolean',
    manual: 'java.lang.Boolean'
};
