// ENTITY fr.hiji.metier.mif.dto.front.manager.MenuManagerDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class MenuManagerDTO {

  acquisitionEnabled: boolean | null = false;
  activationEnabled: boolean | null = false;
  expectationsManagerEnabled: boolean | null = false;
  acquisitionBeforeAvailable: boolean | null = false;
  acquisitionAfterAvailable: boolean | null = false;
  managerExpectationsDefined: boolean | null = false;
  evaDefined: boolean | null = false;
  evaDone: boolean | null = false;

  constructor(init?:Partial<MenuManagerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.manager.MenuManagerDTO'] = MenuManagerDTO;

reverseMapping[MenuManagerDTO.name] = 'fr.hiji.metier.mif.dto.front.manager.MenuManagerDTO';

fields['fr.hiji.metier.mif.dto.front.manager.MenuManagerDTO']  = {
    acquisitionEnabled: 'java.lang.Boolean',
    activationEnabled: 'java.lang.Boolean',
    expectationsManagerEnabled: 'java.lang.Boolean',
    acquisitionBeforeAvailable: 'java.lang.Boolean',
    acquisitionAfterAvailable: 'java.lang.Boolean',
    managerExpectationsDefined: 'java.lang.Boolean',
    evaDefined: 'java.lang.Boolean',
    evaDone: 'java.lang.Boolean'
};
