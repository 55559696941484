// DB ENUM fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationType generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FormationEventAnimationType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly PLENIERE = new FormationEventAnimationType(1, "Plénière", "PLENIERE");
  static readonly WORKSHOP = new FormationEventAnimationType(2, "Atelier", "WORKSHOP");

  static readonly values = [
    FormationEventAnimationType.PLENIERE,
 
    FormationEventAnimationType.WORKSHOP
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FormationEventAnimationType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FormationEventAnimationType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationType'] = FormationEventAnimationType;
reverseMapping[FormationEventAnimationType.name] = 'fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationType';
