// ENTITY fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerSkillAvg generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityRoleplayAnswerSkillAvg {

  idModalityRoleplayAnswer: number | null = 0;
  idTag: number | null = 0;
  value: number | null = 0;

  constructor(init?:Partial<ModalityRoleplayAnswerSkillAvg>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerSkillAvg'] = ModalityRoleplayAnswerSkillAvg;

reverseMapping[ModalityRoleplayAnswerSkillAvg.name] = 'fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerSkillAvg';

fields['fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerSkillAvg']  = {
    idModalityRoleplayAnswer: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    value: 'java.lang.Double'
};
