// ENTITY fr.hiji.metier.conf.dto.front.submodules.ModuleGlobalDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleGlobalDTO {

  manualHierarchicalManagement: boolean | null = false;
  nbMiniUsersStats: number | null = 0;
  allowUserToChangeLanguage: boolean | null = false;
  forceGDPRAcceptation: boolean | null = false;
  opinionEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleGlobalDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.submodules.ModuleGlobalDTO'] = ModuleGlobalDTO;

reverseMapping[ModuleGlobalDTO.name] = 'fr.hiji.metier.conf.dto.front.submodules.ModuleGlobalDTO';

fields['fr.hiji.metier.conf.dto.front.submodules.ModuleGlobalDTO']  = {
    manualHierarchicalManagement: 'java.lang.Boolean',
    nbMiniUsersStats: 'java.lang.Integer',
    allowUserToChangeLanguage: 'java.lang.Boolean',
    forceGDPRAcceptation: 'java.lang.Boolean',
    opinionEnabled: 'java.lang.Boolean'
};
