import { Component, Input } from '@angular/core';

@Component({
    selector: 'hiji-accordion-animation',
    templateUrl: './accordion-animation.component.html',
    styleUrl: './accordion-animation.component.scss',
    standalone: false
})
export class AccordionAnimationComponent {
  @Input()
  icon: String = '';
  @Input()
  title: String = '';
  @Input()
  allowToggle: boolean = true;
  @Input()
  open: boolean = false;

  toggle() {
    if (this.allowToggle) {
      this.open = !this.open;
    }
  }
}
