// ENTITY fr.hiji.metier.ema.dto.front.observation.EMAObservationSaveResultDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OpinionDTO } from './OpinionDTO';

export class EMAObservationSaveResultDTO {

  idEMAObservation: number | null = 0;
  opinionDTO: OpinionDTO | null = null;

  constructor(init?:Partial<EMAObservationSaveResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.observation.EMAObservationSaveResultDTO'] = EMAObservationSaveResultDTO;

reverseMapping[EMAObservationSaveResultDTO.name] = 'fr.hiji.metier.ema.dto.front.observation.EMAObservationSaveResultDTO';

fields['fr.hiji.metier.ema.dto.front.observation.EMAObservationSaveResultDTO']  = {
    idEMAObservation: 'java.lang.Integer',
    opinionDTO: 'fr.hiji.metier.opinion.dto.front.OpinionDTO'
};
