// fr.hiji.metier.page.services.front.StaticPageServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { StaticPage } from '../entities/StaticPage';

@Injectable({
  providedIn: 'root',
})
export class StaticPageService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getPage(slug: string): RpcRequestBuilder<StaticPage> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'StaticPageService.getPage';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(slug, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.page.entities.StaticPage');
  }

  validateGDPR(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'StaticPageService.validateGDPR';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateMessageSeen(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'StaticPageService.updateMessageSeen';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
