// fr.hiji.metier.mif.services.front.FormationSessionTrainerServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationSessionWorkflowStep } from '../entities/FormationSessionWorkflowStep';
import { RepositoryResultDTO } from '../entities/RepositoryResultDTO';
import { FormationQuizLiteDTO } from '../entities/FormationQuizLiteDTO';
import { ParticipantResultsDTO } from '../entities/ParticipantResultsDTO';
import { LayoutTrainerDTO } from '../entities/LayoutTrainerDTO';
import { MenuTrainerDTO } from '../entities/MenuTrainerDTO';
import { AttendanceDTO } from '../entities/AttendanceDTO';
import { ParticipantAttendanceDTO } from '../entities/ParticipantAttendanceDTO';
import { FormationSessionTrainerDTO } from '../entities/FormationSessionTrainerDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationSessionTrainerService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFormationSessionsTrainer(idFormation: number): RpcRequestBuilder<FormationSessionTrainerDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.getFormationSessionsTrainer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.trainer.FormationSessionTrainerDTO');
  }

  getLayoutTrainer(idFormation: number, idFormationSession: number): RpcRequestBuilder<LayoutTrainerDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.getLayoutTrainer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.trainer.LayoutTrainerDTO');
  }

  getSessionMenuTrainer(idFormationSession: number): RpcRequestBuilder<MenuTrainerDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.getSessionMenuTrainer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.trainer.MenuTrainerDTO');
  }

  launchModalFormationSession(idFormationSession: number, workflowStep: FormationSessionWorkflowStep, sendMail: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.launchModalFormationSession';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(workflowStep, 'fr.hiji.metier.mif.entities.session.FormationSessionWorkflowStep', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sendMail, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getParticipantResults(idFormationSession: number): RpcRequestBuilder<ParticipantResultsDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.getParticipantResults';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getQuizes(idFormationSession: number): RpcRequestBuilder<FormationQuizLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.getQuizes';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  toggleQuizOptions(quizDto: FormationQuizLiteDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.toggleQuizOptions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(quizDto, 'fr.hiji.metier.mif.dto.front.trainer.FormationQuizLiteDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getAttendance(idFormationSession: number): RpcRequestBuilder<AttendanceDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.getAttendance';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.trainer.AttendanceDTO');
  }

  addParticipant(idFormationSession: number, idUser: number): RpcRequestBuilder<ParticipantAttendanceDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.addParticipant';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.trainer.ParticipantAttendanceDTO');
  }

  removeParticipant(idFormationSession: number, idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.removeParticipant';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getReport(idFormationSession: number): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.getReport';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

  updateReport(idFormationSession: number, report: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.updateReport';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(report, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getAcquisitionResults(idFormationSession: number): RpcRequestBuilder<RepositoryResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.getAcquisitionResults';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.trainer.RepositoryResultDTO');
  }

  getSatisfactionResults(idFormationSession: number): RpcRequestBuilder<RepositoryResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionTrainerService.getSatisfactionResults';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.trainer.RepositoryResultDTO');
  }

}
