// ENTITY fr.hiji.metier.campaign.dto.back.CampaignUserAccessBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CampaignUserAccess } from './CampaignUserAccess';

export class CampaignUserAccessBODTO {

  cua: CampaignUserAccess | null = null;
  firstName: string | null = '';
  lastName: string | null = '';
  avatarExtension: string | null = '';
  del: boolean | null = false;

  constructor(init?:Partial<CampaignUserAccessBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.CampaignUserAccessBODTO'] = CampaignUserAccessBODTO;

reverseMapping[CampaignUserAccessBODTO.name] = 'fr.hiji.metier.campaign.dto.back.CampaignUserAccessBODTO';

fields['fr.hiji.metier.campaign.dto.back.CampaignUserAccessBODTO']  = {
    cua: 'fr.hiji.metier.campaign.entities.CampaignUserAccess',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    del: 'java.lang.Boolean'
};
