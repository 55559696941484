// ENTITY fr.hiji.metier.ema.dto.front.EMALiteDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { RepositoryLiteDTO } from './RepositoryLiteDTO';
import { EMAType } from './EMAType';

export class EMALiteDTO {

  idEMA: number | null = 0;
  creationDate: Date | null = null;
  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  message: string | null = '';
  supportPlan: string | null = '';
  stepRepositorySelectionEnabled: boolean | null = false;
  repositories: RepositoryLiteDTO[] | null = [];
  type: EMAType | null = null;

  constructor(init?:Partial<EMALiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMALiteDTO'] = EMALiteDTO;

reverseMapping[EMALiteDTO.name] = 'fr.hiji.metier.ema.dto.front.EMALiteDTO';

fields['fr.hiji.metier.ema.dto.front.EMALiteDTO']  = {
    idEMA: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    message: 'java.lang.String',
    supportPlan: 'java.lang.String',
    stepRepositorySelectionEnabled: 'java.lang.Boolean',
    repositories: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryLiteDTO>',
    type: 'fr.hiji.metier.ema.entities.EMAType'
};
