// DB ENUM fr.hiji.metier.referential.entities.quiz.QuestionType generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class QuestionType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly QCM = new QuestionType(1, "QCM", "QCM");
  static readonly QUESTION_OUVERTE = new QuestionType(2, "Question ouverte", "QUESTION_OUVERTE");

  static readonly values = [
    QuestionType.QCM,
 
    QuestionType.QUESTION_OUVERTE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of QuestionType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of QuestionType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.referential.entities.quiz.QuestionType'] = QuestionType;
reverseMapping[QuestionType.name] = 'fr.hiji.metier.referential.entities.quiz.QuestionType';
