// fr.hiji.metier.certif.services.back.stats.CertificationOverviewStatsBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { RecapCertifDTO } from '../entities/RecapCertifDTO';
import { RecapCertifPerOrganisationDTO } from '../entities/RecapCertifPerOrganisationDTO';
import { CertificationStatsFiltersBODTO } from '../entities/CertificationStatsFiltersBODTO';

@Injectable({
  providedIn: 'root',
})
export class CertificationOverviewStatsBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  downloadCertificationOverviewCSV(filter: CertificationStatsFiltersBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationOverviewStatsBOService.downloadCertificationOverviewCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getCertificationOverview(filter: CertificationStatsFiltersBODTO, genericFilter: GenericFilter): RpcRequestBuilder<ListWithCount<RecapCertifDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationOverviewStatsBOService.getCertificationOverview';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getCertificationOverviewPerOrganisation(filter: CertificationStatsFiltersBODTO, genericFilter: GenericFilter): RpcRequestBuilder<ListWithCount<RecapCertifPerOrganisationDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationOverviewStatsBOService.getCertificationOverviewPerOrganisation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(genericFilter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  downloadCertificationOverviewPerOrganisationCSV(filter: CertificationStatsFiltersBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationOverviewStatsBOService.downloadCertificationOverviewPerOrganisationCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
