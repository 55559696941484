// ENTITY fr.hiji.metier.referential.dto.back.ImportExportRepositoryBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Repository } from './Repository';
import { ObservableGestureLiteDTO } from './ObservableGestureLiteDTO';

export class ImportExportRepositoryBODTO {

  repository: Repository | null = null;
  allOgsUnordered: boolean | null = false;
  nbOgs: number | null = 0;
  existingOgs: ObservableGestureLiteDTO[] | null = [];

  constructor(init?:Partial<ImportExportRepositoryBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.back.ImportExportRepositoryBODTO'] = ImportExportRepositoryBODTO;

reverseMapping[ImportExportRepositoryBODTO.name] = 'fr.hiji.metier.referential.dto.back.ImportExportRepositoryBODTO';

fields['fr.hiji.metier.referential.dto.back.ImportExportRepositoryBODTO']  = {
    repository: 'fr.hiji.metier.referential.entities.Repository',
    allOgsUnordered: 'java.lang.Boolean',
    nbOgs: 'java.lang.Integer',
    existingOgs: 'java.util.List<fr.hiji.metier.referential.dto.front.ObservableGestureLiteDTO>'
};
