// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModuleCertificationBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleCertificationBODTO {

  moduleCertificationEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleCertificationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModuleCertificationBODTO'] = ModuleCertificationBODTO;

reverseMapping[ModuleCertificationBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModuleCertificationBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModuleCertificationBODTO']  = {
    moduleCertificationEnabled: 'java.lang.Boolean'
};
