// fr.hiji.metier.ema.services.back.EMAObjectiveResultBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, GenericFilter } from '@ic2/ic2-lib';
import { EMAObjectiveResultBODTO } from '../entities/EMAObjectiveResultBODTO';

@Injectable({
  providedIn: 'root',
})
export class EMAObjectiveResultBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getEMAObjectiveResult(idUser: number, filter: GenericFilter): RpcRequestBuilder<EMAObjectiveResultBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAObjectiveResultBOService.getEMAObjectiveResult';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
