// ENTITY fr.hiji.metier.campaign.dto.back.stats.ExchangePerWeekDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ExchangePerWeekDTO {

  week: string | null = '';
  nbExchange: number | null = 0;

  constructor(init?:Partial<ExchangePerWeekDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.ExchangePerWeekDTO'] = ExchangePerWeekDTO;

reverseMapping[ExchangePerWeekDTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.ExchangePerWeekDTO';

fields['fr.hiji.metier.campaign.dto.back.stats.ExchangePerWeekDTO']  = {
    week: 'java.lang.String',
    nbExchange: 'java.lang.Integer'
};
