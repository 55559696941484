// fr.hiji.metier.user.services.front.UserSearchServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from '../entities/UserFuncAvatarDTO';

@Injectable({
  providedIn: 'root',
})
export class UserSearchService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  searchUserBackWithPerimeter(txt: string, withExternal: boolean): RpcRequestBuilder<UserFuncAvatarDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserSearchService.searchUserBackWithPerimeter';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(txt, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(withExternal, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  searchUserBack(txt: string, withExternal: boolean): RpcRequestBuilder<UserFuncAvatarDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserSearchService.searchUserBack';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(txt, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(withExternal, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  searchManagerBackWithPerimeter(txt: string, withExternal: boolean): RpcRequestBuilder<UserFuncAvatarDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserSearchService.searchManagerBackWithPerimeter';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(txt, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(withExternal, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  searchUser(txt: string, withExternal: boolean): RpcRequestBuilder<UserFuncAvatarDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'UserSearchService.searchUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(txt, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(withExternal, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
