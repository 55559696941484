// ENTITY fr.hiji.metier.file.entities.HijiFile generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class HijiFile {

  idHijiFile: number | null = 0;
  originalFileName: string | null = '';
  privateFile: boolean | null = false;

  constructor(init?:Partial<HijiFile>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.file.entities.HijiFile'] = HijiFile;

reverseMapping[HijiFile.name] = 'fr.hiji.metier.file.entities.HijiFile';

fields['fr.hiji.metier.file.entities.HijiFile']  = {
    idHijiFile: 'java.lang.Integer',
    originalFileName: 'java.lang.String',
    privateFile: 'java.lang.Boolean'
};
