// ENTITY fr.hiji.metier.mif.dto.back.stats.satisfaction.SatisfactionMIFAttendanceUncompleteInfosBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class SatisfactionMIFAttendanceUncompleteInfosBODTO {

  idFormation: number | null = 0;
  nameFormation: MultilingualString | null = new MultilingualString();
  formationColorCode: string | null = '';
  idFormationSession: number | null = 0;
  nameFormationSession: string | null = '';
  formationSessionColorCode: string | null = '';
  idUser: number | null = 0;
  userFullName: string | null = '';

  constructor(init?:Partial<SatisfactionMIFAttendanceUncompleteInfosBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.satisfaction.SatisfactionMIFAttendanceUncompleteInfosBODTO'] = SatisfactionMIFAttendanceUncompleteInfosBODTO;

reverseMapping[SatisfactionMIFAttendanceUncompleteInfosBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.satisfaction.SatisfactionMIFAttendanceUncompleteInfosBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.satisfaction.SatisfactionMIFAttendanceUncompleteInfosBODTO']  = {
    idFormation: 'java.lang.Integer',
    nameFormation: 'com.ic2.sc.MultilingualString',
    formationColorCode: 'java.lang.String',
    idFormationSession: 'java.lang.Integer',
    nameFormationSession: 'java.lang.String',
    formationSessionColorCode: 'java.lang.String',
    idUser: 'java.lang.Integer',
    userFullName: 'java.lang.String'
};
