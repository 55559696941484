<hiji-wavy-container>
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <div class="row justify-content-center">
      <div class="col-auto">
        <img style="width: 75px" src="assets/logo.png" />
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 text-center justify-content-center">
        <h4>
          {{ "common.reset-password-mail-sent.C'est tout bon !" | translate }}
          <br />
        </h4>
        <p class="mt-2">{{ 'common.reset-password-mail-sent.Surveillez votre boîte mail et revenez vite vous connecter!' | translate }}</p>
      </div>
    </div>
  </div>
</hiji-wavy-container>
