// ENTITY fr.hiji.metier.referential.entities.quiz.QuestionQCMAnswer generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class QuestionQCMAnswer {

  idQuestion: number | null = 0;
  numAnswer: number | null = 0;
  answer: MultilingualString | null = new MultilingualString();
  answerImage: MultilingualString | null = new MultilingualString();
  points: number | null = 0;
  order: number | null = 0;
  maxW: number | null = 0;
  maxH: number | null = 0;

  constructor(init?:Partial<QuestionQCMAnswer>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.quiz.QuestionQCMAnswer'] = QuestionQCMAnswer;

reverseMapping[QuestionQCMAnswer.name] = 'fr.hiji.metier.referential.entities.quiz.QuestionQCMAnswer';

fields['fr.hiji.metier.referential.entities.quiz.QuestionQCMAnswer']  = {
    idQuestion: 'java.lang.Integer',
    numAnswer: 'java.lang.Integer',
    answer: 'com.ic2.sc.MultilingualString',
    answerImage: 'com.ic2.sc.MultilingualString',
    points: 'java.lang.Double',
    order: 'java.lang.Integer',
    maxW: 'java.lang.Integer',
    maxH: 'java.lang.Integer'
};
