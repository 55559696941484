// ENTITY fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { QuestionFlatTag } from './QuestionFlatTag';

export class FormationQuizQuestionStatsBODTO {

  idQuestion: number | null = 0;
  question: MultilingualString | null = new MultilingualString();
  average: number | null = null;
  nbGoodReponse: number | null = 0;
  nbReponse: number | null = 0;
  tags: QuestionFlatTag[] | null = [];
  deletedFromQuiz: boolean | null = false;

  constructor(init?:Partial<FormationQuizQuestionStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionStatsBODTO'] = FormationQuizQuestionStatsBODTO;

reverseMapping[FormationQuizQuestionStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionStatsBODTO']  = {
    idQuestion: 'java.lang.Integer',
    question: 'com.ic2.sc.MultilingualString',
    average: 'java.lang.Double',
    nbGoodReponse: 'java.lang.Integer',
    nbReponse: 'java.lang.Integer',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.quiz.QuestionFlatTag>',
    deletedFromQuiz: 'java.lang.Boolean'
};
