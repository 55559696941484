<div class="align-items-center d-flex flex-column justify-content-center" style="min-height: 100vh !important">
  <h1>{{ 'common.tom-auth.Connexion en cours' | translate }}</h1>
  <i class="fa-light fa-spinner spinning fs-1 mt-3"></i>
  <h2 class="text-danger mt-3" *ngIf="error">
    {{ 'common.global.Une erreur est survenue' | translate }}
    <br />
    {{ 'common.tom-auth.Merci de re-tenter la connexion' | translate }}
  </h2>
  <h2 class="text-danger mt-3" *ngIf="errorNoRight">{{ "common.login.error.Ce compte n'a pas d'accès au backoffice" | translate }}</h2>
</div>
