<div class="d-flex align-items-center" style="min-height: 100vh !important">
  <div class="bg-white rounded shadow-2 p-3 mx-auto" style="z-index: 10">
    <!--<div style="width: 23rem"></div>-->

    <div class="container-fluid">
      <div class="row text-center justify-content-center">
        <div class="col-auto">
          <div>
            <img style="width: 50px" src="assets/logo.png" />

            <h3 class="mt-2">{{ 'common.logout.Vous voilà déconnecté(e)' | translate }}...</h3>
          </div>
          <div class="mt-2">{{ 'common.logout. mais vous avez le doit de changer d avis' | translate }}😊</div>
        </div>
      </div>
      <div class="row mt-3 text-center justify-content-center-5 mt-2">
        <div class="col">
          <a class="btn btn-primary text-uppercase" routerLink="/">{{ 'common.logout.ME CONNECTER' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
