// ENTITY fr.hiji.metier.ema.entities.EMANextRepository generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMANextRepository {

  idEMA: number | null = 0;
  idRepository: number | null = 0;

  constructor(init?:Partial<EMANextRepository>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.EMANextRepository'] = EMANextRepository;

reverseMapping[EMANextRepository.name] = 'fr.hiji.metier.ema.entities.EMANextRepository';

fields['fr.hiji.metier.ema.entities.EMANextRepository']  = {
    idEMA: 'java.lang.Integer',
    idRepository: 'java.lang.Integer'
};
