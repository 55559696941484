// ENTITY fr.hiji.metier.mif.dto.back.event.FormationEventParticipantBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { FormationEventParticipant } from './FormationEventParticipant';
import { Registration } from './Registration';

export class FormationEventParticipantBODTO {

  formationEventParticipant: FormationEventParticipant | null = null;
  registration: Registration | null = null;
  userFuncAvatarDTO: UserFuncAvatarDTO | null = null;
  acquisitionBeforeAnswered: boolean | null = false;
  acquisitionAfterAnswered: boolean | null = false;
  satisfactionAnswered: boolean | null = false;
  avgSatisfaction: number | null = null;
  avgAcquisitionBefore: number | null = null;
  avgAcquisitionAfter: number | null = null;
  added: boolean | null = false;
  deleted: boolean | null = false;
  updated: boolean | null = false;

  constructor(init?:Partial<FormationEventParticipantBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.FormationEventParticipantBODTO'] = FormationEventParticipantBODTO;

reverseMapping[FormationEventParticipantBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.FormationEventParticipantBODTO';

fields['fr.hiji.metier.mif.dto.back.event.FormationEventParticipantBODTO']  = {
    formationEventParticipant: 'fr.hiji.metier.mif.entities.event.FormationEventParticipant',
    registration: 'fr.hiji.metier.mif.entities.registration.Registration',
    userFuncAvatarDTO: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    acquisitionBeforeAnswered: 'java.lang.Boolean',
    acquisitionAfterAnswered: 'java.lang.Boolean',
    satisfactionAnswered: 'java.lang.Boolean',
    avgSatisfaction: 'java.lang.Double',
    avgAcquisitionBefore: 'java.lang.Double',
    avgAcquisitionAfter: 'java.lang.Double',
    added: 'java.lang.Boolean',
    deleted: 'java.lang.Boolean',
    updated: 'java.lang.Boolean'
};
