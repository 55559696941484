// ENTITY fr.hiji.metier.conf.dto.front.submodules.ModuleEMADTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class ModuleEMADTO {

  enabled: boolean | null = false;
  emaName: MultilingualString | null = new MultilingualString();
  supportPlanName: MultilingualString | null = new MultilingualString();
  hasTeam: boolean | null = false;
  allowN2PlusToAuditExchanges: boolean | null = false;
  coachEnabled: boolean | null = false;
  managerCanViewOtherManagersExchanges: boolean | null = false;
  managerCanViewCoachExchanges: boolean | null = false;
  coachCanViewOtherCoachsExchanges: boolean | null = false;
  teamKPIEnabled: boolean | null = false;
  teamExchangeRecapEnabled: boolean | null = false;
  showEMAManagerExchangeWithTeamRepositoryDetails: boolean | null = false;

  constructor(init?:Partial<ModuleEMADTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.submodules.ModuleEMADTO'] = ModuleEMADTO;

reverseMapping[ModuleEMADTO.name] = 'fr.hiji.metier.conf.dto.front.submodules.ModuleEMADTO';

fields['fr.hiji.metier.conf.dto.front.submodules.ModuleEMADTO']  = {
    enabled: 'java.lang.Boolean',
    emaName: 'com.ic2.sc.MultilingualString',
    supportPlanName: 'com.ic2.sc.MultilingualString',
    hasTeam: 'java.lang.Boolean',
    allowN2PlusToAuditExchanges: 'java.lang.Boolean',
    coachEnabled: 'java.lang.Boolean',
    managerCanViewOtherManagersExchanges: 'java.lang.Boolean',
    managerCanViewCoachExchanges: 'java.lang.Boolean',
    coachCanViewOtherCoachsExchanges: 'java.lang.Boolean',
    teamKPIEnabled: 'java.lang.Boolean',
    teamExchangeRecapEnabled: 'java.lang.Boolean',
    showEMAManagerExchangeWithTeamRepositoryDetails: 'java.lang.Boolean'
};
