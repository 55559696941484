// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModuleSurveyBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleSurveyBODTO {

  moduleEnquete: boolean | null = false;
  changeBONamesToSurveyMode: boolean | null = false;

  constructor(init?:Partial<ModuleSurveyBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModuleSurveyBODTO'] = ModuleSurveyBODTO;

reverseMapping[ModuleSurveyBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModuleSurveyBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModuleSurveyBODTO']  = {
    moduleEnquete: 'java.lang.Boolean',
    changeBONamesToSurveyMode: 'java.lang.Boolean'
};
