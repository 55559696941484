// ENTITY fr.hiji.metier.ema.dto.front.observation.EMAObservationDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAObservationObservableGestures } from './EMAObservationObservableGestures';
import { EMANextRepository } from './EMANextRepository';
import { EMARepositoryLiteDTO } from './EMARepositoryLiteDTO';
import { EMASkillMessageWithUserDTO } from './EMASkillMessageWithUserDTO';
import { EMAUserOGC } from './EMAUserOGC';
import { EMAUserOGDTO } from './EMAUserOGDTO';
import { Criteria } from './Criteria';
import { EMAStep } from './EMAStep';
import { EMAObservation } from './EMAObservation';

export class EMAObservationDTO {

  emaObservation: EMAObservation | null = null;
  observableGestures: EMAUserOGDTO[] | null = [];
  observableGesturesCustom: EMAUserOGC[] | null = [];
  oogs: EMAObservationObservableGestures[] | null = [];
  repositories: EMARepositoryLiteDTO[] | null = [];
  previousEMASelectedRepositories: EMANextRepository[] | null = [];
  preselectedIdRepositories: number[] | null = [];
  selectedIdRepositories: number[] | null = [];
  criterias: Criteria[] | null = [];
  enabledSteps: EMAStep[] | null = [];
  skillMessages: EMASkillMessageWithUserDTO[] | null = [];
  allowObsComment: boolean | null = false;
  allowExchangeSkillComment: boolean | null = false;
  allowRepositorySelectionForObservation: boolean | null = false;
  allowCommentOnOG: boolean | null = false;
  allowObsDraft: boolean | null = false;
  hasPreviousEMA: boolean | null = false;
  message: string | null = '';
  supportPlan: string | null = '';
  coach: boolean | null = false;
  draftCouldNotBeRestoredBecauseRepositoryChanged: boolean | null = false;

  constructor(init?:Partial<EMAObservationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.observation.EMAObservationDTO'] = EMAObservationDTO;

reverseMapping[EMAObservationDTO.name] = 'fr.hiji.metier.ema.dto.front.observation.EMAObservationDTO';

fields['fr.hiji.metier.ema.dto.front.observation.EMAObservationDTO']  = {
    emaObservation: 'fr.hiji.metier.ema.entities.observation.EMAObservation',
    observableGestures: 'java.util.List<fr.hiji.metier.ema.dto.front.EMAUserOGDTO>',
    observableGesturesCustom: 'java.util.List<fr.hiji.metier.ema.entities.EMAUserOGC>',
    oogs: 'java.util.List<fr.hiji.metier.ema.entities.observation.EMAObservationObservableGestures>',
    repositories: 'java.util.List<fr.hiji.metier.ema.dto.front.EMARepositoryLiteDTO>',
    previousEMASelectedRepositories: 'java.util.List<fr.hiji.metier.ema.entities.EMANextRepository>',
    preselectedIdRepositories: 'java.util.List<java.lang.Integer>',
    selectedIdRepositories: 'java.util.List<java.lang.Integer>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    enabledSteps: 'java.util.List<fr.hiji.metier.ema.entities.EMAStep>',
    skillMessages: 'java.util.List<fr.hiji.metier.ema.dto.front.EMASkillMessageWithUserDTO>',
    allowObsComment: 'java.lang.Boolean',
    allowExchangeSkillComment: 'java.lang.Boolean',
    allowRepositorySelectionForObservation: 'java.lang.Boolean',
    allowCommentOnOG: 'java.lang.Boolean',
    allowObsDraft: 'java.lang.Boolean',
    hasPreviousEMA: 'java.lang.Boolean',
    message: 'java.lang.String',
    supportPlan: 'java.lang.String',
    coach: 'java.lang.Boolean',
    draftCouldNotBeRestoredBecauseRepositoryChanged: 'java.lang.Boolean'
};
