import { Component } from '@angular/core';

@Component({
    selector: 'hiji-translate-texts',
    templateUrl: './translate-texts.component.html',
    styleUrls: ['./translate-texts.component.scss'],
    standalone: false
})
export class TranslateTextsComponent {

}
