// ENTITY fr.hiji.metier.mif.entities.formation.FormationFile generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationFile {

  idFormationFile: number | null = 0;
  idFormation: number | null = 0;
  idHijiFile: number | null = 0;

  constructor(init?:Partial<FormationFile>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.formation.FormationFile'] = FormationFile;

reverseMapping[FormationFile.name] = 'fr.hiji.metier.mif.entities.formation.FormationFile';

fields['fr.hiji.metier.mif.entities.formation.FormationFile']  = {
    idFormationFile: 'java.lang.Integer',
    idFormation: 'java.lang.Integer',
    idHijiFile: 'java.lang.Integer'
};
