// fr.hiji.metier.mif.services.back.registration.DiffusionListBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:55 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { DiffusionListBODTO } from '../entities/DiffusionListBODTO';

@Injectable({
  providedIn: 'root',
})
export class DiffusionListBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getDiffusionList(id: number): RpcRequestBuilder<DiffusionListBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DiffusionListBOService.getDiffusionList';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.registration.DiffusionListBODTO');
  }

  getDiffusionLists(filter: GenericFilter): RpcRequestBuilder<ListWithCount<DiffusionListBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DiffusionListBOService.getDiffusionLists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  saveDiffusionList(dto: DiffusionListBODTO): RpcRequestBuilder<DiffusionListBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DiffusionListBOService.saveDiffusionList';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.back.registration.DiffusionListBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.registration.DiffusionListBODTO');
  }

}
