// fr.hiji.metier.mif.services.back.formation.FormationSessionBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FormationSessionParticipantAttendanceSignLiteBODTO } from '../entities/FormationSessionParticipantAttendanceSignLiteBODTO';
import { FormationSessionBODTO } from '../entities/FormationSessionBODTO';
import { FormationSessionLiteBODTO } from '../entities/FormationSessionLiteBODTO';

@Injectable({
  providedIn: 'root',
})
export class FormationSessionBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFormationSessionParticipantAttendance(idFormationSession: number, idParticipant: number): RpcRequestBuilder<FormationSessionParticipantAttendanceSignLiteBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionBOService.getFormationSessionParticipantAttendance';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idParticipant, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getFormationSessions(idFormation: number, filter: GenericFilter): RpcRequestBuilder<ListWithCount<FormationSessionLiteBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionBOService.getFormationSessions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getFormationSession(id: number): RpcRequestBuilder<FormationSessionBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionBOService.getFormationSession';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.formationsession.FormationSessionBODTO');
  }

  saveFormationSession(dto: FormationSessionBODTO): RpcRequestBuilder<FormationSessionBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionBOService.saveFormationSession';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.back.formationsession.FormationSessionBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.back.formationsession.FormationSessionBODTO');
  }

}
