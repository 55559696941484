import { ClientEnv } from '../services/client.env.service';
import { Config } from './Config';

export const loadClientStyle = (config: Config, clientEnv: ClientEnv) => {
  {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    const url = new URL(window.location.href);
    const theme = url.searchParams.get('theme');
    if (theme) {
      style.href = theme + '.css';
      head.appendChild(style);
    } else if (clientEnv.is(clientEnv.SKILLSUP) || clientEnv.is(clientEnv.SKILLSUP_FASHION)) {
      style.href = 'chanel.css';
      head.appendChild(style);
    } else if (clientEnv.is(clientEnv.DIOR)) {
      style.href = 'dior.css';
      head.appendChild(style);
    } else if (clientEnv.is(clientEnv.ARMATIS)) {
      style.href = 'armatis.css';
      head.appendChild(style);
    } else if (config.css) {
      style.href = config.css;
      head.appendChild(style);
    }
  }
  {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id = 'custom-theme';
    style.rel = 'stylesheet';
    style.href = config.rpcHost + '/custom.css';

    head.appendChild(style);
  }
};
