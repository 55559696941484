// ENTITY fr.hiji.metier.conf.dto.back.submodules.ModuleTeamBoardBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleTeamBoardBODTO {

  moduleTeamBoardEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleTeamBoardBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.submodules.ModuleTeamBoardBODTO'] = ModuleTeamBoardBODTO;

reverseMapping[ModuleTeamBoardBODTO.name] = 'fr.hiji.metier.conf.dto.back.submodules.ModuleTeamBoardBODTO';

fields['fr.hiji.metier.conf.dto.back.submodules.ModuleTeamBoardBODTO']  = {
    moduleTeamBoardEnabled: 'java.lang.Boolean'
};
