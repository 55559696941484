// ENTITY fr.hiji.metier.mif.entities.session.FormationSession generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionDatePeriod } from './FormationSessionDatePeriod';

export class FormationSession {

  idFormationSession: number | null = 0;
  idFormation: number | null = 0;
  name: string | null = '';
  adresse: string | null = '';
  url: string | null = '';
  nbParticipantSlots: number | null = 0;
  nbQueueSlots: number | null = 0;
  colorCode: string | null = '';
  acquisitionBeforeAvailable: boolean | null = false;
  satisfactionAvailable: boolean | null = false;
  acquisitionAfterAvailable: boolean | null = false;
  actionAvailable: boolean | null = false;
  online: boolean | null = false;
  acquisitionEnabled: boolean | null = false;
  expectationsParticipantEnabled: boolean | null = false;
  expectationsParticipantEvaluationEnabled: boolean | null = false;
  expectationsManagerEnabled: boolean | null = false;
  activationEnabled: boolean | null = false;
  actionEnabled: boolean | null = false;
  actionEvaluationJ10Enabled: boolean | null = false;
  attendanceEnabled: boolean | null = false;
  quizQualiopiEnabled: boolean | null = false;
  allowManagerToSeeExpectations: boolean | null = false;
  minDate: Date | null = null;
  maxDate: Date | null = null;
  openedAttendanceDate: Date | null = null;
  openedAttendancePeriod: FormationSessionDatePeriod | null = null;
  report: string | null = '';
  idOrganisationStorage: number | null = null;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<FormationSession>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.session.FormationSession'] = FormationSession;

reverseMapping[FormationSession.name] = 'fr.hiji.metier.mif.entities.session.FormationSession';

fields['fr.hiji.metier.mif.entities.session.FormationSession']  = {
    idFormationSession: 'java.lang.Integer',
    idFormation: 'java.lang.Integer',
    name: 'java.lang.String',
    adresse: 'java.lang.String',
    url: 'java.lang.String',
    nbParticipantSlots: 'java.lang.Integer',
    nbQueueSlots: 'java.lang.Integer',
    colorCode: 'java.lang.String',
    acquisitionBeforeAvailable: 'java.lang.Boolean',
    satisfactionAvailable: 'java.lang.Boolean',
    acquisitionAfterAvailable: 'java.lang.Boolean',
    actionAvailable: 'java.lang.Boolean',
    online: 'java.lang.Boolean',
    acquisitionEnabled: 'java.lang.Boolean',
    expectationsParticipantEnabled: 'java.lang.Boolean',
    expectationsParticipantEvaluationEnabled: 'java.lang.Boolean',
    expectationsManagerEnabled: 'java.lang.Boolean',
    activationEnabled: 'java.lang.Boolean',
    actionEnabled: 'java.lang.Boolean',
    actionEvaluationJ10Enabled: 'java.lang.Boolean',
    attendanceEnabled: 'java.lang.Boolean',
    quizQualiopiEnabled: 'java.lang.Boolean',
    allowManagerToSeeExpectations: 'java.lang.Boolean',
    minDate: 'java.time.LocalDate',
    maxDate: 'java.time.LocalDate',
    openedAttendanceDate: 'java.time.LocalDate',
    openedAttendancePeriod: 'fr.hiji.metier.mif.entities.session.FormationSessionDatePeriod',
    report: 'java.lang.String',
    idOrganisationStorage: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
