// DB ENUM fr.hiji.metier.HijiRelationUser generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class HijiRelationUser {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly MANAGER_PAR_AUTO = new HijiRelationUser(1, "Managé par", "MANAGER_PAR_AUTO");
  static readonly MANAGER_PAR = new HijiRelationUser(2, "Managé par", "MANAGER_PAR");
  static readonly DANS_EQUIPE_DE = new HijiRelationUser(3, "Dans l'équipe de", "DANS_EQUIPE_DE");
  static readonly PARTAGE_EQUIPE_DE = new HijiRelationUser(4, "Partage l'équipe de", "PARTAGE_EQUIPE_DE");
  static readonly MANAGER_PAR_EN_ATTENTE_DE_VALIDATION = new HijiRelationUser(5, "En attente de validation", "MANAGER_PAR_EN_ATTENTE_DE_VALIDATION");

  static readonly values = [
    HijiRelationUser.MANAGER_PAR_AUTO,
 
    HijiRelationUser.MANAGER_PAR,
 
    HijiRelationUser.DANS_EQUIPE_DE,
 
    HijiRelationUser.PARTAGE_EQUIPE_DE,
 
    HijiRelationUser.MANAGER_PAR_EN_ATTENTE_DE_VALIDATION
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of HijiRelationUser.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of HijiRelationUser.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.HijiRelationUser'] = HijiRelationUser;
reverseMapping[HijiRelationUser.name] = 'fr.hiji.metier.HijiRelationUser';
