import { Component, Input, OnInit } from '@angular/core';
import { AvatarService } from '../../services/avatar.service';

@Component({
    selector: 'hiji-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss'],
    standalone: false
})
export class UserAvatarComponent implements OnInit {
  @Input()
  idUser: number;
  @Input()
  avatarExtension: string;
  @Input()
  defaultBlueAvatar: boolean = false;
  @Input()
  rounded: boolean = true;
  constructor(public avatarService: AvatarService) {}

  ngOnInit(): void {}
}
