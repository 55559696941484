<div class="container-fluid bg-white" [ngClass]="allowToggle ? 'accordion-title' : 'border-custom'">
  <div class="row bg-white rounded" [class.border-bottom]="open" [class.pointer]="allowToggle" (click)="toggle()">
    <div class="col p-4">
      <div class="row">
        @if (icon.length > 0) {
          <div class="col-auto d-flex align-items-center">
            <i class="{{ icon }}"></i>
          </div>
        }
        <div class="col d-flex align-items-center">
          {{ title }}
        </div>
      </div>
    </div>
    @if (allowToggle) {
      <div class="col-auto align-content-center">
        <i class="fa-light fa-2x icon-rotate fa-angle-down" [class.open]="open"></i>
      </div>
    }
  </div>
  @if (open) {
    <div class="row py-2">
      <div class="col-12">
        <ng-content></ng-content>
      </div>
    </div>
  }
</div>
