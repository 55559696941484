// ENTITY fr.hiji.metier.teamboard.dto.EMATeamResultDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMATeamResultDTO {

  creationDate: Date | null = null;
  average: number | null = null;

  constructor(init?:Partial<EMATeamResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.teamboard.dto.EMATeamResultDTO'] = EMATeamResultDTO;

reverseMapping[EMATeamResultDTO.name] = 'fr.hiji.metier.teamboard.dto.EMATeamResultDTO';

fields['fr.hiji.metier.teamboard.dto.EMATeamResultDTO']  = {
    creationDate: 'java.time.Instant',
    average: 'java.lang.Double'
};
