// ENTITY com.ic2.services.dto.LoginResponse generated by ic2 3.0.0-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserBundle } from './UserBundle';

export class LoginResponse {

  token: string | null = '';
  ub: UserBundle | null = null;

  constructor(init?:Partial<LoginResponse>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.services.dto.LoginResponse'] = LoginResponse;

reverseMapping[LoginResponse.name] = 'com.ic2.services.dto.LoginResponse';

fields['com.ic2.services.dto.LoginResponse']  = {
    token: 'java.lang.String',
    ub: 'com.ic2.entity.UserBundle'
};
