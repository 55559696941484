// ENTITY fr.hiji.metier.certif.dto.back.stats.candidates.CertificationCandidateStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CertificationCandidateExamResultBODTO } from './CertificationCandidateExamResultBODTO';

export class CertificationCandidateStatsBODTO {

  idCertification: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  idCertificationSession: number | null = 0;
  sessionName: string | null = '';
  startDate: Date | null = null;
  endDate: Date | null = null;
  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  certified: boolean | null = false;
  examResults: CertificationCandidateExamResultBODTO[] | null = [];
  enseignes: string[] | null = [];
  societes: string[] | null = [];
  pays: string[] | null = [];
  regions: string[] | null = [];
  etablissements: string[] | null = [];
  directions: string[] | null = [];
  services: string[] | null = [];
  autres: string[] | null = [];

  constructor(init?:Partial<CertificationCandidateStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.candidates.CertificationCandidateStatsBODTO'] = CertificationCandidateStatsBODTO;

reverseMapping[CertificationCandidateStatsBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.candidates.CertificationCandidateStatsBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.candidates.CertificationCandidateStatsBODTO']  = {
    idCertification: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    idCertificationSession: 'java.lang.Integer',
    sessionName: 'java.lang.String',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    certified: 'java.lang.Boolean',
    examResults: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.candidates.CertificationCandidateExamResultBODTO>',
    enseignes: 'java.util.List<java.lang.String>',
    societes: 'java.util.List<java.lang.String>',
    pays: 'java.util.List<java.lang.String>',
    regions: 'java.util.List<java.lang.String>',
    etablissements: 'java.util.List<java.lang.String>',
    directions: 'java.util.List<java.lang.String>',
    services: 'java.util.List<java.lang.String>',
    autres: 'java.util.List<java.lang.String>'
};
