// ENTITY fr.hiji.metier.certif.entities.Certification generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class Certification {

  idCertification: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  imageExtension: string | null = '';
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<Certification>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.Certification'] = Certification;

reverseMapping[Certification.name] = 'fr.hiji.metier.certif.entities.Certification';

fields['fr.hiji.metier.certif.entities.Certification']  = {
    idCertification: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    imageExtension: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
