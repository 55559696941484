// ENTITY fr.hiji.metier.conf.dto.back.ModulesBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModulePDIBODTO } from './ModulePDIBODTO';
import { BackConfigurationBODTO } from './BackConfigurationBODTO';
import { ModuleEventBODTO } from './ModuleEventBODTO';
import { Module180BODTO } from './Module180BODTO';
import { ModuleFBSBODTO } from './ModuleFBSBODTO';
import { ModuleBilanBODTO } from './ModuleBilanBODTO';
import { ModuleCertificationBODTO } from './ModuleCertificationBODTO';
import { ModuleEMABODTO } from './ModuleEMABODTO';
import { FrontConfigurationBODTO } from './FrontConfigurationBODTO';
import { ModuleMIFBODTO } from './ModuleMIFBODTO';
import { ModuleTMSBODTO } from './ModuleTMSBODTO';
import { ModuleSurveyBODTO } from './ModuleSurveyBODTO';
import { Module360BODTO } from './Module360BODTO';
import { ModuleTeamBoardBODTO } from './ModuleTeamBoardBODTO';
import { ModuleGlobalBODTO } from './ModuleGlobalBODTO';

export class ModulesBODTO {

  moduleGlobal: ModuleGlobalBODTO | null = null;
  module180: Module180BODTO | null = null;
  module360: Module360BODTO | null = null;
  moduleFBS: ModuleFBSBODTO | null = null;
  moduleSurvey: ModuleSurveyBODTO | null = null;
  moduleBilan: ModuleBilanBODTO | null = null;
  modulePDI: ModulePDIBODTO | null = null;
  moduleCertification: ModuleCertificationBODTO | null = null;
  moduleEvent: ModuleEventBODTO | null = null;
  moduleMIF: ModuleMIFBODTO | null = null;
  moduleEMA: ModuleEMABODTO | null = null;
  moduleTeamBoard: ModuleTeamBoardBODTO | null = null;
  moduleTMS: ModuleTMSBODTO | null = null;
  backConfiguration: BackConfigurationBODTO | null = null;
  frontConfiguration: FrontConfigurationBODTO | null = null;

  constructor(init?:Partial<ModulesBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.back.ModulesBODTO'] = ModulesBODTO;

reverseMapping[ModulesBODTO.name] = 'fr.hiji.metier.conf.dto.back.ModulesBODTO';

fields['fr.hiji.metier.conf.dto.back.ModulesBODTO']  = {
    moduleGlobal: 'fr.hiji.metier.conf.dto.back.submodules.ModuleGlobalBODTO',
    module180: 'fr.hiji.metier.conf.dto.back.submodules.Module180BODTO',
    module360: 'fr.hiji.metier.conf.dto.back.submodules.Module360BODTO',
    moduleFBS: 'fr.hiji.metier.conf.dto.back.submodules.ModuleFBSBODTO',
    moduleSurvey: 'fr.hiji.metier.conf.dto.back.submodules.ModuleSurveyBODTO',
    moduleBilan: 'fr.hiji.metier.conf.dto.back.submodules.ModuleBilanBODTO',
    modulePDI: 'fr.hiji.metier.conf.dto.back.submodules.ModulePDIBODTO',
    moduleCertification: 'fr.hiji.metier.conf.dto.back.submodules.ModuleCertificationBODTO',
    moduleEvent: 'fr.hiji.metier.conf.dto.back.submodules.ModuleEventBODTO',
    moduleMIF: 'fr.hiji.metier.conf.dto.back.submodules.ModuleMIFBODTO',
    moduleEMA: 'fr.hiji.metier.conf.dto.back.submodules.ModuleEMABODTO',
    moduleTeamBoard: 'fr.hiji.metier.conf.dto.back.submodules.ModuleTeamBoardBODTO',
    moduleTMS: 'fr.hiji.metier.conf.dto.back.submodules.ModuleTMSBODTO',
    backConfiguration: 'fr.hiji.metier.conf.dto.back.submodules.BackConfigurationBODTO',
    frontConfiguration: 'fr.hiji.metier.conf.dto.back.submodules.FrontConfigurationBODTO'
};
