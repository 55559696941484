import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Filter, GenericFilter, MsPipe, TextassistComponent, string_to_slug } from '@ic2/ic2-lib';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RepositoryItemBODTO } from '../../ic2/entities/RepositoryItemBODTO';
import { RepositoryBOService } from '../../ic2/services/RepositoryBOService';
import { RepositoryManagerService } from '../../services/repository-manager.service';

@Component({
    selector: 'hiji-repository-select',
    templateUrl: './repository-select.component.html',
    styleUrls: ['./repository-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RepositorySelectComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class RepositorySelectComponent implements ControlValueAccessor, OnInit {
  repositories: RepositoryItemBODTO[] = [];

  @Input()
  initialText: string = null;
  @Input()
  placeholder: string = '';
  @Input()
  emptyMeansNull: boolean = false;
  @Input()
  idStartRepository: number = 0;
  @Input()
  usableInMIFSatisfaction: boolean = false;
  @Input()
  usableIn360: boolean = false;
  @Input()
  withoutMifSatisfaction: boolean = false;
  @Input()
  clearAfterAdd: boolean = false;
  @Input()
  disabled: boolean = false;
  @Input()
  noDataTooltipTemplate: TemplateRef<any> = null;

  loading: boolean = false;
  loadingRepositoryName: boolean = true;
  clearing: boolean = false;
  idRepository: number = 0;

  @Output()
  repositoryChanged = new EventEmitter<RepositoryItemBODTO>();

  string_to_slug = string_to_slug;

  keyExtractorRepository = (ref: RepositoryItemBODTO) => (ref === null ? (this.emptyMeansNull ? null : 0) : ref.repository.idRepository);
  presenterRepository = (ref) => this.msPipe.transform(ref.repository.name);
  @ViewChild('ta') ta: TextassistComponent<RepositoryItemBODTO>;
  @ViewChild('noDataTooltip') noDataTooltip: NgbTooltip;

  constructor(
    private repositoryBOService: RepositoryBOService,
    private msPipe: MsPipe,
    private repositoryManagerService: RepositoryManagerService
  ) {}
  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    let filter = new GenericFilter();
    filter.filters = [];
    if (this.usableInMIFSatisfaction) filter.filters.push(new Filter('usableInMIFSatisfaction'));
    if (this.usableIn360) filter.filters.push(new Filter('usableIn360'));
    if (this.withoutMifSatisfaction) filter.filters.push(new Filter('withoutMifSatisfaction'));

    if (this.usableInMIFSatisfaction || this.usableIn360 || this.withoutMifSatisfaction) {
      this.repositoryBOService
        .getRepositories(filter)
        .onErrorUseDefault()
        .execute((data) => {
          this.repositories = data.data;
          if ((this.idRepository == 0 || this.idRepository == null) && this.repositories.length === 1) {
            this.setValue(this.repositories[0].repository.idRepository);
          }
          this.loading = false;
        });
    } else {
      this.repositoryManagerService.load().subscribe((data) => {
        this.repositories = data;
        this.loading = false;
      });
    }
    if (this.initialText === null && this.idStartRepository != 0 && this.idStartRepository != null) {
      this.repositoryBOService
        .getRepositoryName(this.idStartRepository)
        .defaultOnError()
        .execute((data) => {
          this.initialText = this.msPipe.transform(data);
          this.loadingRepositoryName = false;
        });
    } else {
      this.loadingRepositoryName = false;
    }
  }

  repositoryTextChanged(text: string): void {
    if (text.length === 0) return;

    const filter = new GenericFilter();
    filter.filters = [new Filter('recherche').withStringValues(text)];
    if (this.usableInMIFSatisfaction) filter.filters.push(new Filter('usableInMIFSatisfaction'));
    if (this.usableIn360) filter.filters.push(new Filter('usableIn360'));
    if (this.withoutMifSatisfaction) filter.filters.push(new Filter('withoutMifSatisfaction'));
    filter.page = 1;
    filter.nbPerPage = 50;
    this.repositoryBOService
      .getRepositories(filter)
      .onErrorUseDefault()
      .execute((data) => {
        this.repositories = data.data;
        if (this.repositories.length === 0 && this.noDataTooltipTemplate !== null) {
          this.noDataTooltip.open();
          setTimeout(() => {
            this.noDataTooltip.close();
          }, 3000);
        }
      });
  }

  registerOnChange(fn: number) {
    this.onChange = fn;
  }
  registerOnTouched(fn: number) {
    this.onTouch = fn;
  }

  writeValue(value: number) {
    this.idRepository = this.emptyMeansNull && value === 0 ? null : value;
  }

  setValue(idRepository: number) {
    if (this.clearing) return;
    this.idRepository = idRepository;
    this.repositoryChanged.emit(this.getRepository(idRepository));
    this.change();
    if (this.clearAfterAdd) {
      this.clearing = true;
      this.ta.clear();
      this.repositories = [];
      this.clearing = false;
    }
  }

  change() {
    if ((this.idRepository === null || this.idRepository === 0) && this.clearAfterAdd) return;
    this.onChange(this.idRepository);
    this.onTouch(this.idRepository);
  }

  getRepository(idRepository: number): RepositoryItemBODTO {
    return this.repositories.find((r) => r.repository.idRepository === idRepository);
  }
}
