// fr.hiji.metier.mif.services.front.FormationServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FormationSessionActionDTO } from '../entities/FormationSessionActionDTO';
import { FormationLiteDTO } from '../entities/FormationLiteDTO';
import { FormationSessionLiteDTO } from '../entities/FormationSessionLiteDTO';
import { FormationSessionExpectationsManagerDTO } from '../entities/FormationSessionExpectationsManagerDTO';
import { FormationEVADTO } from '../entities/FormationEVADTO';

@Injectable({
  providedIn: 'root',
})
export class FormationService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getActions(): RpcRequestBuilder<FormationSessionActionDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationService.getActions';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getFormations(): RpcRequestBuilder<FormationLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationService.getFormations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getFormationTrainerSessions(filter: GenericFilter): RpcRequestBuilder<ListWithCount<FormationSessionLiteDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationService.getFormationTrainerSessions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getFormationPastSessions(filter: GenericFilter): RpcRequestBuilder<ListWithCount<FormationSessionLiteDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationService.getFormationPastSessions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getFormationParticipantSessions(filter: GenericFilter): RpcRequestBuilder<ListWithCount<FormationSessionLiteDTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationService.getFormationParticipantSessions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getEVA(): RpcRequestBuilder<FormationEVADTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationService.getEVA';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getExpectations(): RpcRequestBuilder<FormationSessionExpectationsManagerDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationService.getExpectations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
