// ENTITY fr.hiji.metier.mif.dto.back.formationsession.FormationSessionParticipantAttendanceSignLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionParticipantAttendance } from './FormationSessionParticipantAttendance';

export class FormationSessionParticipantAttendanceSignLiteBODTO {

  formationSessionParticipantAttendance: FormationSessionParticipantAttendance | null = null;
  userSignFullName: string | null = '';

  constructor(init?:Partial<FormationSessionParticipantAttendanceSignLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionParticipantAttendanceSignLiteBODTO'] = FormationSessionParticipantAttendanceSignLiteBODTO;

reverseMapping[FormationSessionParticipantAttendanceSignLiteBODTO.name] = 'fr.hiji.metier.mif.dto.back.formationsession.FormationSessionParticipantAttendanceSignLiteBODTO';

fields['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionParticipantAttendanceSignLiteBODTO']  = {
    formationSessionParticipantAttendance: 'fr.hiji.metier.mif.entities.session.FormationSessionParticipantAttendance',
    userSignFullName: 'java.lang.String'
};
