import { Component, Input } from '@angular/core';
import { MsPipe, MultilingualString } from '@ic2/ic2-lib';

@Component({
    selector: 'hiji-translate-entity',
    templateUrl: './translate-entity.component.html',
    styleUrl: './translate-entity.component.scss',
    standalone: false
})
export class TranslateEntityComponent {
  @Input()
  name: MultilingualString;
  @Input()
  description: MultilingualString = null;
  @Input()
  sourceLang: string;
  @Input()
  destinationLang: string;
  @Input()
  textAreaRows: number = 5;
  @Input()
  title: string = null;
  @Input()
  alreadyExist: boolean = false;
  @Input()
  alreadyExistTitle: string;
  @Input()
  icon: string;
  @Input()
  image: boolean = false;

  constructor(public msPipe: MsPipe) {}
}
