// ENTITY fr.hiji.metier.reporting.dto.survey.SurveyReportDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { SurveyReportObservableGestureDTO } from './SurveyReportObservableGestureDTO';
import { SurveyReportGlobalDataDTO } from './SurveyReportGlobalDataDTO';
import { SurveyReportManager } from './SurveyReportManager';
import { SurveyReportOrganisation } from './SurveyReportOrganisation';
import { SurveyReport } from './SurveyReport';
import { SurveyReportIndiceEngagement } from './SurveyReportIndiceEngagement';
import { Criteria } from './Criteria';
import { SurveyReportSkillDataDTO } from './SurveyReportSkillDataDTO';

export class SurveyReportDTO {

  report: SurveyReport | null = null;
  fixed: boolean | null = false;
  ogs: SurveyReportObservableGestureDTO[] | null = [];
  surveyReportIndiceEngagement: SurveyReportIndiceEngagement | null = null;
  globalData: SurveyReportGlobalDataDTO | null = null;
  skills: SurveyReportSkillDataDTO[] | null = [];
  criterias: Criteria[] | null = [];
  childOrganisations: SurveyReportOrganisation[] | null = [];
  managers: SurveyReportManager[] | null = [];

  constructor(init?:Partial<SurveyReportDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.survey.SurveyReportDTO'] = SurveyReportDTO;

reverseMapping[SurveyReportDTO.name] = 'fr.hiji.metier.reporting.dto.survey.SurveyReportDTO';

fields['fr.hiji.metier.reporting.dto.survey.SurveyReportDTO']  = {
    report: 'fr.hiji.metier.reporting.entities.survey.SurveyReport',
    fixed: 'java.lang.Boolean',
    ogs: 'java.util.List<fr.hiji.metier.reporting.dto.survey.SurveyReportObservableGestureDTO>',
    surveyReportIndiceEngagement: 'fr.hiji.metier.reporting.entities.survey.SurveyReportIndiceEngagement',
    globalData: 'fr.hiji.metier.reporting.dto.survey.SurveyReportGlobalDataDTO',
    skills: 'java.util.List<fr.hiji.metier.reporting.dto.survey.SurveyReportSkillDataDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    childOrganisations: 'java.util.List<fr.hiji.metier.reporting.entities.survey.SurveyReportOrganisation>',
    managers: 'java.util.List<fr.hiji.metier.reporting.entities.survey.SurveyReportManager>'
};
