// ENTITY fr.hiji.metier.conf.dto.front.submodules.ModuleFBSDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleFBSDTO {

  enabled: boolean | null = false;
  fbsReceivedNotifMailEnabled: boolean | null = false;
  fbsSolicitationMailEnabled: boolean | null = false;
  displayFBSHomeButton: boolean | null = false;

  constructor(init?:Partial<ModuleFBSDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.submodules.ModuleFBSDTO'] = ModuleFBSDTO;

reverseMapping[ModuleFBSDTO.name] = 'fr.hiji.metier.conf.dto.front.submodules.ModuleFBSDTO';

fields['fr.hiji.metier.conf.dto.front.submodules.ModuleFBSDTO']  = {
    enabled: 'java.lang.Boolean',
    fbsReceivedNotifMailEnabled: 'java.lang.Boolean',
    fbsSolicitationMailEnabled: 'java.lang.Boolean',
    displayFBSHomeButton: 'java.lang.Boolean'
};
