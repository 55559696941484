// ENTITY fr.hiji.metier.mif.dto.back.stats.satisfaction.SatisfactionMIFStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationVerbatimStatsBODTO } from './FormationVerbatimStatsBODTO';
import { RepositoryLiteDTO } from './RepositoryLiteDTO';
import { FormationSkillStatsBODTO } from './FormationSkillStatsBODTO';
import { Criteria } from './Criteria';
import { FormationObservableGestureStatsBODTO } from './FormationObservableGestureStatsBODTO';

export class SatisfactionMIFStatsBODTO {

  totalParticipants: number | null = 0;
  totalAnswerParticipant: number | null = 0;
  totalAnswerAnonyme: number | null = 0;
  averageSatisfaction: number | null = null;
  tags: FormationSkillStatsBODTO[] | null = [];
  repositories: RepositoryLiteDTO[] | null = [];
  observableGestures: FormationObservableGestureStatsBODTO[] | null = [];
  verbatims: FormationVerbatimStatsBODTO[] | null = [];
  criterias: Criteria[] | null = [];
  nbSatisfactionAnswerWhenNotFullAttendanceOnAttendanceEnabledSession: number | null = 0;

  constructor(init?:Partial<SatisfactionMIFStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.satisfaction.SatisfactionMIFStatsBODTO'] = SatisfactionMIFStatsBODTO;

reverseMapping[SatisfactionMIFStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.satisfaction.SatisfactionMIFStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.satisfaction.SatisfactionMIFStatsBODTO']  = {
    totalParticipants: 'java.lang.Integer',
    totalAnswerParticipant: 'java.lang.Integer',
    totalAnswerAnonyme: 'java.lang.Integer',
    averageSatisfaction: 'java.lang.Double',
    tags: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.FormationSkillStatsBODTO>',
    repositories: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryLiteDTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.FormationObservableGestureStatsBODTO>',
    verbatims: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.FormationVerbatimStatsBODTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    nbSatisfactionAnswerWhenNotFullAttendanceOnAttendanceEnabledSession: 'java.lang.Integer'
};
