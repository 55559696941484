// ENTITY fr.hiji.metier.mif.entities.formation.FormationTag generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationTag {

  idFormation: number | null = 0;
  tag: string | null = '';

  constructor(init?:Partial<FormationTag>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.formation.FormationTag'] = FormationTag;

reverseMapping[FormationTag.name] = 'fr.hiji.metier.mif.entities.formation.FormationTag';

fields['fr.hiji.metier.mif.entities.formation.FormationTag']  = {
    idFormation: 'java.lang.Integer',
    tag: 'java.lang.String'
};
