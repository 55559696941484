// ENTITY fr.hiji.metier.reporting.entities.survey.SurveyReportManager generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SurveyReportManager {

  idSurveyReport: number | null = 0;
  idUser: number | null = 0;

  constructor(init?:Partial<SurveyReportManager>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities.survey.SurveyReportManager'] = SurveyReportManager;

reverseMapping[SurveyReportManager.name] = 'fr.hiji.metier.reporting.entities.survey.SurveyReportManager';

fields['fr.hiji.metier.reporting.entities.survey.SurveyReportManager']  = {
    idSurveyReport: 'java.lang.Integer',
    idUser: 'java.lang.Integer'
};
