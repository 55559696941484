<div class="row mb-2">
  <div class="col">
    <h4 class="m-0">
      <i class="me-2 fa-light fa-palette"></i>
      {{ criteria.name.obj[sourceLang] }}
    </h4>
  </div>
</div>
@if (!alreadyExist) {
  <hiji-translate-textarea
    [sourceLang]="sourceLang"
    [destinationLang]="destinationLang"
    [textAreaRows]="textAreaRows"
    [(translation)]="criteria.name"
    [title]="'common.translate.Nom' | translate"
  ></hiji-translate-textarea>

  <hiji-translate-textarea
    [sourceLang]="sourceLang"
    [destinationLang]="destinationLang"
    [textAreaRows]="textAreaRows"
    [(translation)]="criteria.description"
    [title]="'common.translate.Description' | translate"
  ></hiji-translate-textarea>

  @if (criteria.type !== CriteriaType.QUESTION_OUVERTE) {
    <hiji-translate-textarea
      [sourceLang]="sourceLang"
      [destinationLang]="destinationLang"
      [textAreaRows]="textAreaRows"
      [(translation)]="criteria.label1"
      [title]="'common.translate.criteria.label1' | translate"
    ></hiji-translate-textarea>

    @if (
      criteria.type === CriteriaType.ECHELLE_4 ||
      criteria.type === CriteriaType.ECHELLE_5 ||
      criteria.type === CriteriaType.NPS ||
      criteria.type === CriteriaType.ECHELLE_10
    ) {
      <hiji-translate-textarea
        [sourceLang]="sourceLang"
        [destinationLang]="destinationLang"
        [textAreaRows]="textAreaRows"
        [(translation)]="criteria.label2"
        [title]="'common.translate.criteria.label2' | translate"
      ></hiji-translate-textarea>
    }
    @if (criteria.type === CriteriaType.ECHELLE_4 || criteria.type === CriteriaType.ECHELLE_5) {
      <hiji-translate-textarea
        [sourceLang]="sourceLang"
        [destinationLang]="destinationLang"
        [textAreaRows]="textAreaRows"
        [(translation)]="criteria.label3"
        [title]="'common.translate.criteria.label3' | translate"
      ></hiji-translate-textarea>

      <hiji-translate-textarea
        [sourceLang]="sourceLang"
        [destinationLang]="destinationLang"
        [textAreaRows]="textAreaRows"
        [(translation)]="criteria.label4"
        [title]="'common.translate.criteria.label4' | translate"
      ></hiji-translate-textarea>
    }
    @if (criteria.type === CriteriaType.ECHELLE_5 || criteria.type === CriteriaType.BINAIRE) {
      <hiji-translate-textarea
        [sourceLang]="sourceLang"
        [destinationLang]="destinationLang"
        [textAreaRows]="textAreaRows"
        [(translation)]="criteria.label5"
        [title]="'common.translate.criteria.label5' | translate"
      ></hiji-translate-textarea>
    }
  }
} @else {
  <div class="row">
    <div class="col-auto">
      <div class="alert alert-info mt-2" role="alert">
        {{ "common.translate.Ce critère est déjà présent et n'a pas besoin d'être traduit plusieurs fois" | translate }}
      </div>
    </div>
  </div>
}
