// ENTITY fr.hiji.metier.user.dto.back.UserEMALiteBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserEMALiteBODTO {

  idEMA: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  creationDate: Date | null = null;
  idUserObserver: number | null = 0;
  idUserObserved: number | null = 0;
  doneWithACoach: boolean | null = false;

  constructor(init?:Partial<UserEMALiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.UserEMALiteBODTO'] = UserEMALiteBODTO;

reverseMapping[UserEMALiteBODTO.name] = 'fr.hiji.metier.user.dto.back.UserEMALiteBODTO';

fields['fr.hiji.metier.user.dto.back.UserEMALiteBODTO']  = {
    idEMA: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserObserver: 'java.lang.Integer',
    idUserObserved: 'java.lang.Integer',
    doneWithACoach: 'java.lang.Boolean'
};
