// ENTITY fr.hiji.metier.reporting.entities._180.Report180ConfigDepartment generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class Report180ConfigDepartment {

  idReport180Config: number | null = 0;
  idPopulation: number | null = 0;
  name: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<Report180ConfigDepartment>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities._180.Report180ConfigDepartment'] = Report180ConfigDepartment;

reverseMapping[Report180ConfigDepartment.name] = 'fr.hiji.metier.reporting.entities._180.Report180ConfigDepartment';

fields['fr.hiji.metier.reporting.entities._180.Report180ConfigDepartment']  = {
    idReport180Config: 'java.lang.Integer',
    idPopulation: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString'
};
