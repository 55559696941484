// ENTITY fr.hiji.metier.certif.dto.back.stats.overview.RecapCertifDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class RecapCertifDTO {

  idCertification: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  imageExtension: string | null = '';
  nbSessions: number | null = 0;
  nbCandidats: number | null = 0;
  nbCertified: number | null = 0;
  nbAssessors: number | null = 0;
  nbModalities: number | null = 0;

  constructor(init?:Partial<RecapCertifDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.overview.RecapCertifDTO'] = RecapCertifDTO;

reverseMapping[RecapCertifDTO.name] = 'fr.hiji.metier.certif.dto.back.stats.overview.RecapCertifDTO';

fields['fr.hiji.metier.certif.dto.back.stats.overview.RecapCertifDTO']  = {
    idCertification: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    imageExtension: 'java.lang.String',
    nbSessions: 'java.lang.Integer',
    nbCandidats: 'java.lang.Integer',
    nbCertified: 'java.lang.Integer',
    nbAssessors: 'java.lang.Integer',
    nbModalities: 'java.lang.Integer'
};
