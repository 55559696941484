// ENTITY fr.hiji.metier.referential.entities.quiz.QuizQuestion generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class QuizQuestion {

  idQuiz: number | null = 0;
  idQuestion: number | null = 0;
  order: number | null = 0;

  constructor(init?:Partial<QuizQuestion>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.quiz.QuizQuestion'] = QuizQuestion;

reverseMapping[QuizQuestion.name] = 'fr.hiji.metier.referential.entities.quiz.QuizQuestion';

fields['fr.hiji.metier.referential.entities.quiz.QuizQuestion']  = {
    idQuiz: 'java.lang.Integer',
    idQuestion: 'java.lang.Integer',
    order: 'java.lang.Integer'
};
