// ENTITY fr.hiji.metier.campaign.dto.back.CampaignItemBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CampaignType } from './CampaignType';

export class CampaignItemBODTO {

  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  startDate: Date | null = null;
  endDate: Date | null = null;
  nbQuestions: number | null = 0;
  nbUsersTotal: number | null = 0;
  nbUsersDeployed: number | null = 0;
  del: boolean | null = false;
  campaignType: CampaignType | null = null;

  constructor(init?:Partial<CampaignItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.CampaignItemBODTO'] = CampaignItemBODTO;

reverseMapping[CampaignItemBODTO.name] = 'fr.hiji.metier.campaign.dto.back.CampaignItemBODTO';

fields['fr.hiji.metier.campaign.dto.back.CampaignItemBODTO']  = {
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    nbQuestions: 'java.lang.Integer',
    nbUsersTotal: 'java.lang.Integer',
    nbUsersDeployed: 'java.lang.Integer',
    del: 'java.lang.Boolean',
    campaignType: 'fr.hiji.metier.campaign.entities.CampaignType'
};
