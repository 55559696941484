import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { L } from '@ic2/ic2-lib';

@Component({
    selector: 'hiji-download-graph',
    templateUrl: './download-graph.component.html',
    styleUrls: ['./download-graph.component.scss'],
    standalone: false
})
export class DownloadGraphComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  @Input() imgName: string = 'graph';
  constructor() {}

  ngOnInit(): void {}

  downloadCanvas(event) {
    let link = event.target;
    event.stopPropagation();
    if (event === null) return;
    const canvas = this.content.nativeElement.querySelector('canvas');
    var svg = this.content.nativeElement.querySelector('svg');
    //canvas
    if (canvas !== null) {
      while (link.tagName !== 'A') {
        link = link.parentNode;
        if (link.tagName === 'BODY') return;
      }
      link.href = canvas.toDataURL();
      link.download = this.imgName + '.png';
    }

    //svg
    if (svg !== null) {
      const data = new XMLSerializer().serializeToString(svg);
      const svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });

      // Convert the blob object to a dedicated URL
      const url = URL.createObjectURL(svgBlob);

      // Create an image object and load the SVG blob into it
      const img = new Image();
      img.onload = () => {
        // Create a canvas and draw the image on it
        const bbox = svg.getBBox();
        const canvas = document.createElement('canvas');
        canvas.width = bbox.width;
        canvas.height = bbox.height;
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, bbox.width, bbox.height);

        // Convert canvas to PNG and trigger download
        const pngDataURL = canvas.toDataURL('image/png/');
        URL.revokeObjectURL(url); // Clean up the object URL
        const tempLink = document.createElement('a');
        tempLink.href = pngDataURL;
        tempLink.download = this.imgName + '.png';
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink); // Clean up
      };
      img.src = url; // Start loading the SVG
    }

    if (svg === null && canvas === null) {
      L.e('No canvas or svg found on download-graph component');
    }
  }
}
