// ENTITY fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { PopulationItemBODTO } from './PopulationItemBODTO';
import { Organisation } from './Organisation';
import { FonctionItemBODTO } from './FonctionItemBODTO';
import { RepositoryLiteDTO } from './RepositoryLiteDTO';

export class EMAStatsFilterBODTO {

  start: Date | null = null;
  end: Date | null = null;
  langue: string | null = '';
  repositories: RepositoryLiteDTO[] | null = [];
  organisations: Organisation[] | null = [];
  functions: FonctionItemBODTO[] | null = [];
  populations: PopulationItemBODTO[] | null = [];

  constructor(init?:Partial<EMAStatsFilterBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO'] = EMAStatsFilterBODTO;

reverseMapping[EMAStatsFilterBODTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO']  = {
    start: 'java.time.Instant',
    end: 'java.time.Instant',
    langue: 'java.lang.String',
    repositories: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryLiteDTO>',
    organisations: 'java.util.List<fr.hiji.metier.reseau.entities.Organisation>',
    functions: 'java.util.List<fr.hiji.metier.reseau.dto.FonctionItemBODTO>',
    populations: 'java.util.List<fr.hiji.metier.reseau.dto.PopulationItemBODTO>'
};
