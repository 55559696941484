import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { L } from '@ic2/ic2-lib';
import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { VersionService } from '../ic2/services/VersionService';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private router: Router,
    private versionService: VersionService,
    private ngZone: NgZone
  ) {
    updates.versionUpdates.pipe(filter((e) => e.type === 'VERSION_READY')).subscribe((event: VersionReadyEvent) => {
      //router.navigate(['/update']);
      console.log('current version is', event.currentVersion);
      console.log('available version is', event.latestVersion);
    });
    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
    updates.unrecoverable.subscribe((event) => {
      console.log('Unrecoverable error with the service worker', event.reason);
    });
    /*
    updates.activatedUpdate.subscribe((event) => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });*/
  }

  check(prod: boolean, hash: string) {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => {
      console.log('checking for updates');
      this.versionService.getVersion().subscribe(
        (data) => {
          if (hash !== data) {
            console.log('version hash differs', hash, data);
            if (prod) {
              //this.ngZone.run(() => {
              console.log('Navigating to /update/update-service-check');
              this.router.navigate(['/update/update-service-check']);
              //});
            } else console.log('pas prod ?');
          } else {
            console.log('version hash is the same', hash, data);
          }
        },
        (err) => {
          L.e(err);
        }
      );

      console.log('checkForUpdate');
      this.updates
        .checkForUpdate()
        .then((newVersion) => {
          console.log('new version found ?', newVersion);
        })
        .catch((err) => {
          if (prod) {
            console.error(err);
          }
        });
    });
  }
}
