// ENTITY fr.hiji.metier.mif.dto.back.stats.acquisition.AcquisitionMIFStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationVerbatimStatsBODTO } from './FormationVerbatimStatsBODTO';
import { RepositoryLiteDTO } from './RepositoryLiteDTO';
import { FormationSkillStatsBODTO } from './FormationSkillStatsBODTO';
import { Criteria } from './Criteria';
import { FormationObservableGestureStatsBODTO } from './FormationObservableGestureStatsBODTO';

export class AcquisitionMIFStatsBODTO {

  nbParticipantBefore: number | null = 0;
  nbParticipantAnsweredBefore: number | null = 0;
  nbParticipantAfter: number | null = 0;
  nbParticipantAnsweredAfter: number | null = 0;
  nbParticipant: number | null = 0;
  nbParticipantAnsweredBeforeAfter: number | null = 0;
  averageAcquisitionBefore: number | null = null;
  averageAcquisitionAfter: number | null = null;
  tags: FormationSkillStatsBODTO[] | null = [];
  repositories: RepositoryLiteDTO[] | null = [];
  observableGesturesAverage: FormationObservableGestureStatsBODTO[] | null = [];
  verbatims: FormationVerbatimStatsBODTO[] | null = [];
  criterias: Criteria[] | null = [];

  constructor(init?:Partial<AcquisitionMIFStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.acquisition.AcquisitionMIFStatsBODTO'] = AcquisitionMIFStatsBODTO;

reverseMapping[AcquisitionMIFStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.acquisition.AcquisitionMIFStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.acquisition.AcquisitionMIFStatsBODTO']  = {
    nbParticipantBefore: 'java.lang.Integer',
    nbParticipantAnsweredBefore: 'java.lang.Integer',
    nbParticipantAfter: 'java.lang.Integer',
    nbParticipantAnsweredAfter: 'java.lang.Integer',
    nbParticipant: 'java.lang.Integer',
    nbParticipantAnsweredBeforeAfter: 'java.lang.Integer',
    averageAcquisitionBefore: 'java.lang.Double',
    averageAcquisitionAfter: 'java.lang.Double',
    tags: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.FormationSkillStatsBODTO>',
    repositories: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryLiteDTO>',
    observableGesturesAverage: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.FormationObservableGestureStatsBODTO>',
    verbatims: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.FormationVerbatimStatsBODTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>'
};
