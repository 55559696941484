// fr.hiji.metier.translate.services.ema.ImportExportTranslationEMABOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { LangueUpdatedChangedDTO } from '../entities/LangueUpdatedChangedDTO';

@Injectable({
  providedIn: 'root',
})
export class ImportExportTranslationEMABOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  exportEMATemplateTranslations(idTemplate: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationEMABOService.exportEMATemplateTranslations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idTemplate, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  importEMATemplateTranslations(): RpcRequestBuilder<LangueUpdatedChangedDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationEMABOService.importEMATemplateTranslations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
