// ENTITY fr.hiji.metier.user.dto.front.ema.TeamMemberEMARecapDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { NbExchangePerRepositoryDTO } from './NbExchangePerRepositoryDTO';

export class TeamMemberEMARecapDTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  avatarExtension: string | null = '';
  isTargetedByEMA: boolean | null = false;
  rank: number | null = 0;
  nbEMAInCurrentMonth: number | null = 0;
  list: NbExchangePerRepositoryDTO[] | null = [];

  constructor(init?:Partial<TeamMemberEMARecapDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.ema.TeamMemberEMARecapDTO'] = TeamMemberEMARecapDTO;

reverseMapping[TeamMemberEMARecapDTO.name] = 'fr.hiji.metier.user.dto.front.ema.TeamMemberEMARecapDTO';

fields['fr.hiji.metier.user.dto.front.ema.TeamMemberEMARecapDTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    isTargetedByEMA: 'java.lang.Boolean',
    rank: 'java.lang.Double',
    nbEMAInCurrentMonth: 'java.lang.Long',
    list: 'java.util.List<fr.hiji.metier.user.dto.front.ema.NbExchangePerRepositoryDTO>'
};
