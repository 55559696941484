// ENTITY fr.hiji.metier.reporting.dto.back.ReportEMAConfigLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ReportEMAConfig } from './ReportEMAConfig';

export class ReportEMAConfigLiteBODTO {

  config: ReportEMAConfig | null = null;
  emaPopulationName: MultilingualString[] | null = [];

  constructor(init?:Partial<ReportEMAConfigLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.ReportEMAConfigLiteBODTO'] = ReportEMAConfigLiteBODTO;

reverseMapping[ReportEMAConfigLiteBODTO.name] = 'fr.hiji.metier.reporting.dto.back.ReportEMAConfigLiteBODTO';

fields['fr.hiji.metier.reporting.dto.back.ReportEMAConfigLiteBODTO']  = {
    config: 'fr.hiji.metier.reporting.entities.ema.ReportEMAConfig',
    emaPopulationName: 'java.util.List<com.ic2.sc.MultilingualString>'
};
