import { Injectable } from '@angular/core';
import { RpcRequestBuilder } from '@ic2/ic2-lib';
@Injectable({
  providedIn: 'root',
})
export class AutoSaveHelperService<T> {
  private autoSaveMap = new Map<number, boolean>();
  private previouslyAnsweredQuestionId: number = -1;
  private needToAutoSave: boolean = false;
  private needToSave: boolean = false;
  private saveMethod: (dto: T) => RpcRequestBuilder<T> = null;
  private draftValueChanger: (dto: T, draft: boolean) => void;
  callbackOnAutoSaveDone: () => void = null;

  public loadingAutoSave: boolean = false;

  setSaveMethod(saveMethod: (dto: T) => RpcRequestBuilder<T>) {
    this.saveMethod = saveMethod;
    this.autoSaveMap.clear();
    this.previouslyAnsweredQuestionId = -1;
    this.needToAutoSave = false;
    this.needToSave = false;
    this.callbackOnAutoSaveDone = null;
  }

  setDraftValueChanger(draftValueChanger: (dto: T, draft: boolean) => void) {
    this.draftValueChanger = draftValueChanger;
  }

  setAutoSaveStatus(questionId: number, status: boolean) {
    this.autoSaveMap.set(questionId, status);
  }

  getAutoSaveStatus(questionId: number): boolean {
    return this.autoSaveMap.get(questionId) || false;
  }

  autoSaveObservation(data: T, questionId: number) {
    if (this.loadingAutoSave) {
      this.needToAutoSave = true;
      return;
    }

    this.setAutoSaveStatus(questionId, false);

    if (this.needsToAutoSave(questionId)) {
      if (this.saveMethod === null) throw new Error('not implemented');

      this.loadingAutoSave = true;
      if (!this.draftValueChanger) throw new Error('draftValueChanger not defined');
      this.draftValueChanger(data, true);

      this.saveMethod(data)
        .onErrorUseDefault()
        .onErrorAlwaysDo((err) => {
          this.setAutoSaveStatus(this.previouslyAnsweredQuestionId, false);
          this.loadingAutoSave = false;
          if (this.callbackOnAutoSaveDone) {
            this.callbackOnAutoSaveDone();
            this.callbackOnAutoSaveDone = null;
          }
        })
        .execute(() => {
          this.setAutoSaveStatus(this.previouslyAnsweredQuestionId, true);
          this.previouslyAnsweredQuestionId = questionId;
          this.loadingAutoSave = false;
          if (this.needToAutoSave) {
            this.needToAutoSave = false;
            this.autoSaveObservation(data, questionId);
          } else if (this.callbackOnAutoSaveDone) {
            this.callbackOnAutoSaveDone();
            this.callbackOnAutoSaveDone = null;
          }
        });
    } else {
      this.previouslyAnsweredQuestionId = questionId;
    }
  }

  saveObservation(data: T, draft: boolean): Promise<T> {
    return new Promise((resolve, reject) => {
      if (this.saveMethod === null) throw new Error('not implemented');

      this.needToSave = true;

      const save = () => {
        if (!this.draftValueChanger) throw new Error('draftValueChanger not defined');
        this.draftValueChanger(data, draft);

        return this.saveMethod(data)
          .onErrorUseDefault()
          .onErrorAlwaysDo((err) => {
            this.needToSave = false;
            return reject(err);
          })
          .execute((answer) => {
            this.needToSave = false;
            return resolve(answer);
          });
      };

      if (this.loadingAutoSave) {
        this.callbackOnAutoSaveDone = save;
      } else {
        save();
      }

      //if (this.loadingAutoSave) return;
    });
  }

  private needsToAutoSave(questionId: number): boolean {
    return this.previouslyAnsweredQuestionId !== -1 && this.previouslyAnsweredQuestionId !== questionId;
  }
}
