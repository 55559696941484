// ENTITY fr.hiji.metier.reporting.dto.pdf180.CritereTauxEchange generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Report180ExchangeRate } from './Report180ExchangeRate';

export class CritereTauxEchange {

  id: number | null = 0;
  name: string | null = '';
  echangesParMois: Report180ExchangeRate[] | null = [];
  tauxMoyenMoisPrecedents: number | null = 0;

  constructor(init?:Partial<CritereTauxEchange>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.pdf180.CritereTauxEchange'] = CritereTauxEchange;

reverseMapping[CritereTauxEchange.name] = 'fr.hiji.metier.reporting.dto.pdf180.CritereTauxEchange';

fields['fr.hiji.metier.reporting.dto.pdf180.CritereTauxEchange']  = {
    id: 'java.lang.Integer',
    name: 'java.lang.String',
    echangesParMois: 'java.util.List<fr.hiji.metier.reporting.entities._180.Report180ExchangeRate>',
    tauxMoyenMoisPrecedents: 'java.lang.Integer'
};
