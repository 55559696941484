// ENTITY fr.hiji.metier.ema.entities.observation.EMAObservation generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAObservation {

  idEMAObservation: number | null = 0;
  idUserObserved: number | null = 0;
  idUserObserver: number | null = 0;
  creationDate: Date | null = null;
  startAnsweringDate: Date | null = null;
  idEMAConsumer: number | null = null;
  doneByACoach: boolean | null = false;
  message: string | null = '';
  draft: boolean | null = false;

  constructor(init?:Partial<EMAObservation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.observation.EMAObservation'] = EMAObservation;

reverseMapping[EMAObservation.name] = 'fr.hiji.metier.ema.entities.observation.EMAObservation';

fields['fr.hiji.metier.ema.entities.observation.EMAObservation']  = {
    idEMAObservation: 'java.lang.Integer',
    idUserObserved: 'java.lang.Integer',
    idUserObserver: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    startAnsweringDate: 'java.time.Instant',
    idEMAConsumer: 'java.lang.Integer',
    doneByACoach: 'java.lang.Boolean',
    message: 'java.lang.String',
    draft: 'java.lang.Boolean'
};
