// ENTITY fr.hiji.metier.ema.dto.front.EMAObjectiveDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAObjectiveDefinition } from './EMAObjectiveDefinition';
import { EMAObjective } from './EMAObjective';
import { EMAObjectiveResult } from './EMAObjectiveResult';

export class EMAObjectiveDTO {

  objective: EMAObjective | null = null;
  objectiveDefinition: EMAObjectiveDefinition | null = null;
  lastResult: EMAObjectiveResult | null = null;
  newResult: EMAObjectiveResult | null = null;

  constructor(init?:Partial<EMAObjectiveDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMAObjectiveDTO'] = EMAObjectiveDTO;

reverseMapping[EMAObjectiveDTO.name] = 'fr.hiji.metier.ema.dto.front.EMAObjectiveDTO';

fields['fr.hiji.metier.ema.dto.front.EMAObjectiveDTO']  = {
    objective: 'fr.hiji.metier.ema.entities.objectif.EMAObjective',
    objectiveDefinition: 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveDefinition',
    lastResult: 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveResult',
    newResult: 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveResult'
};
