// DB ENUM fr.hiji.metier.certif.entities.modality.ModalityType generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class ModalityType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly QUIZ = new ModalityType(1, "Quiz", "QUIZ");
  static readonly INTERVIEW = new ModalityType(2, "Interview", "INTERVIEW");
  static readonly ROLEPLAY = new ModalityType(3, "Roleplay", "ROLEPLAY");

  static readonly values = [
    ModalityType.QUIZ,
 
    ModalityType.INTERVIEW,
 
    ModalityType.ROLEPLAY
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of ModalityType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of ModalityType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.certif.entities.modality.ModalityType'] = ModalityType;
reverseMapping[ModalityType.name] = 'fr.hiji.metier.certif.entities.modality.ModalityType';
