// ENTITY fr.hiji.metier.translate.entities.AttributBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class AttributBODTO {

  name: string | null = '';
  data: string | null = '';
  dataTranslate: string | null = '';

  constructor(init?:Partial<AttributBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.translate.entities.AttributBODTO'] = AttributBODTO;

reverseMapping[AttributBODTO.name] = 'fr.hiji.metier.translate.entities.AttributBODTO';

fields['fr.hiji.metier.translate.entities.AttributBODTO']  = {
    name: 'java.lang.String',
    data: 'java.lang.String',
    dataTranslate: 'java.lang.String'
};
