// ENTITY fr.hiji.metier.referential.dto.RepositoryItemBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Repository } from './Repository';

export class RepositoryItemBODTO {

  repository: Repository | null = null;
  nbObservableGesture: number | null = 0;

  constructor(init?:Partial<RepositoryItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.RepositoryItemBODTO'] = RepositoryItemBODTO;

reverseMapping[RepositoryItemBODTO.name] = 'fr.hiji.metier.referential.dto.RepositoryItemBODTO';

fields['fr.hiji.metier.referential.dto.RepositoryItemBODTO']  = {
    repository: 'fr.hiji.metier.referential.entities.Repository',
    nbObservableGesture: 'java.lang.Integer'
};
