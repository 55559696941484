// ENTITY fr.hiji.metier.mif.dto.front.trainer.FormationTrainingPathDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { TrainingPathFormationDTO } from './TrainingPathFormationDTO';
import { TrainingPath } from './TrainingPath';
import { TrainingPathCertificationDTO } from './TrainingPathCertificationDTO';

export class FormationTrainingPathDTO {

  trainingPath: TrainingPath | null = null;
  formations: TrainingPathFormationDTO[] | null = [];
  certifications: TrainingPathCertificationDTO[] | null = [];

  constructor(init?:Partial<FormationTrainingPathDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.FormationTrainingPathDTO'] = FormationTrainingPathDTO;

reverseMapping[FormationTrainingPathDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.FormationTrainingPathDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.FormationTrainingPathDTO']  = {
    trainingPath: 'fr.hiji.metier.mif.entities.trainingpath.TrainingPath',
    formations: 'java.util.List<fr.hiji.metier.mif.dto.front.trainer.TrainingPathFormationDTO>',
    certifications: 'java.util.List<fr.hiji.metier.mif.dto.front.trainer.TrainingPathCertificationDTO>'
};
