// ENTITY fr.hiji.metier.mif.dto.front.event.FormationEventDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationEventAnimationDTO } from './FormationEventAnimationDTO';

export class FormationEventDTO {

  idFormationEvent: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  startDate: Date | null = null;
  endDate: Date | null = null;
  acquisitionBeforeAvailable: boolean | null = false;
  acquisitionAfterAvailable: boolean | null = false;
  satisfactionAvailable: boolean | null = false;
  acquisitionBeforeAnswered: boolean | null = false;
  acquisitionAfterAnswered: boolean | null = false;
  satisfactionAnswered: boolean | null = false;
  animations: FormationEventAnimationDTO[] | null = [];

  constructor(init?:Partial<FormationEventDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.event.FormationEventDTO'] = FormationEventDTO;

reverseMapping[FormationEventDTO.name] = 'fr.hiji.metier.mif.dto.front.event.FormationEventDTO';

fields['fr.hiji.metier.mif.dto.front.event.FormationEventDTO']  = {
    idFormationEvent: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    acquisitionBeforeAvailable: 'java.lang.Boolean',
    acquisitionAfterAvailable: 'java.lang.Boolean',
    satisfactionAvailable: 'java.lang.Boolean',
    acquisitionBeforeAnswered: 'java.lang.Boolean',
    acquisitionAfterAnswered: 'java.lang.Boolean',
    satisfactionAnswered: 'java.lang.Boolean',
    animations: 'java.util.List<fr.hiji.metier.mif.dto.front.event.FormationEventAnimationDTO>'
};
