// ENTITY fr.hiji.metier.ema.dto.front.EMALastEncouragingWordDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMALastEncouragingWordDTO {

  message: string | null = '';
  supportPlan: string | null = '';
  creationDate: Date | null = null;
  idUserObserver: number | null = 0;

  constructor(init?:Partial<EMALastEncouragingWordDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMALastEncouragingWordDTO'] = EMALastEncouragingWordDTO;

reverseMapping[EMALastEncouragingWordDTO.name] = 'fr.hiji.metier.ema.dto.front.EMALastEncouragingWordDTO';

fields['fr.hiji.metier.ema.dto.front.EMALastEncouragingWordDTO']  = {
    message: 'java.lang.String',
    supportPlan: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserObserver: 'java.lang.Integer'
};
