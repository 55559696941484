import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'hiji-stat-card',
    templateUrl: './stat-card.component.html',
    styleUrl: './stat-card.component.scss',
    standalone: false
})
export class StatCardComponent {
  @Input({ required: true }) title: string;
  @Input() value: string;
  @Input({ required: true }) icon: string;
  @Input() detail: string;
  @Input() footer: string;
  @Input() footerTemplate: TemplateRef<any> | null = null;
}
