// ENTITY fr.hiji.metier.campaign.entities._360.Campaign360Infos generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Campaign360Infos {

  idCampaign: number | null = 0;
  hideSkill: boolean | null = false;
  randomizeOrder: boolean | null = false;
  idRegion: number | null = 0;
  inquiryAnonymousCollaborator: boolean | null = false;
  inquiryAnonymousPair: boolean | null = false;
  inquiryAnonymousManager: boolean | null = false;
  inquiryAnonymousExternal: boolean | null = false;
  managerAssesor: boolean | null = false;
  maxCollaborator: number | null = 0;
  maxPair: number | null = 0;
  maxManager: number | null = 0;
  maxExternal: number | null = 0;
  minCollaborator: number | null = 0;
  minPair: number | null = 0;
  minManager: number | null = 0;
  minExternal: number | null = 0;
  deploymentDate: Date | null = null;
  deployed: boolean | null = false;
  actionPlanEnabled: boolean | null = false;
  noAccountMode: boolean | null = false;
  hideResults: boolean | null = false;
  disableMailInquiryCreated_1: boolean | null = false;
  disableMailInquiryDeployed_2: boolean | null = false;
  disableMailInquiryTargetDeployed_11: boolean | null = false;
  disableMailInquiryTargetAnswer_6: boolean | null = false;
  disableMailInquiryEndAlert_7_8: boolean | null = false;
  disableMailInquiryEnd_9: boolean | null = false;
  skillTargetEnabled: boolean | null = false;
  onlyAssessorCanSeeSkillTarget: boolean | null = false;
  showSkillStatBeforeCampaignEnd: boolean | null = false;
  showSkillStatOnlyToAssessors: boolean | null = false;
  disablePDFGeneration: boolean | null = false;
  idPopulation: number | null = 0;
  idResponsibleUser: number | null = null;

  constructor(init?:Partial<Campaign360Infos>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities._360.Campaign360Infos'] = Campaign360Infos;

reverseMapping[Campaign360Infos.name] = 'fr.hiji.metier.campaign.entities._360.Campaign360Infos';

fields['fr.hiji.metier.campaign.entities._360.Campaign360Infos']  = {
    idCampaign: 'java.lang.Integer',
    hideSkill: 'java.lang.Boolean',
    randomizeOrder: 'java.lang.Boolean',
    idRegion: 'java.lang.Integer',
    inquiryAnonymousCollaborator: 'java.lang.Boolean',
    inquiryAnonymousPair: 'java.lang.Boolean',
    inquiryAnonymousManager: 'java.lang.Boolean',
    inquiryAnonymousExternal: 'java.lang.Boolean',
    managerAssesor: 'java.lang.Boolean',
    maxCollaborator: 'java.lang.Integer',
    maxPair: 'java.lang.Integer',
    maxManager: 'java.lang.Integer',
    maxExternal: 'java.lang.Integer',
    minCollaborator: 'java.lang.Integer',
    minPair: 'java.lang.Integer',
    minManager: 'java.lang.Integer',
    minExternal: 'java.lang.Integer',
    deploymentDate: 'java.time.Instant',
    deployed: 'java.lang.Boolean',
    actionPlanEnabled: 'java.lang.Boolean',
    noAccountMode: 'java.lang.Boolean',
    hideResults: 'java.lang.Boolean',
    disableMailInquiryCreated_1: 'java.lang.Boolean',
    disableMailInquiryDeployed_2: 'java.lang.Boolean',
    disableMailInquiryTargetDeployed_11: 'java.lang.Boolean',
    disableMailInquiryTargetAnswer_6: 'java.lang.Boolean',
    disableMailInquiryEndAlert_7_8: 'java.lang.Boolean',
    disableMailInquiryEnd_9: 'java.lang.Boolean',
    skillTargetEnabled: 'java.lang.Boolean',
    onlyAssessorCanSeeSkillTarget: 'java.lang.Boolean',
    showSkillStatBeforeCampaignEnd: 'java.lang.Boolean',
    showSkillStatOnlyToAssessors: 'java.lang.Boolean',
    disablePDFGeneration: 'java.lang.Boolean',
    idPopulation: 'java.lang.Integer',
    idResponsibleUser: 'java.lang.Integer'
};
