// fr.hiji.metier.verbatim.services.VerbatimCategoryBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { VerbatimCategory } from '../entities/VerbatimCategory';

@Injectable({
  providedIn: 'root',
})
export class VerbatimCategoryBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getVerbatimCategories(filter: GenericFilter): RpcRequestBuilder<ListWithCount<VerbatimCategory>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VerbatimCategoryBOService.getVerbatimCategories';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getVerbatimCategory(id: number): RpcRequestBuilder<VerbatimCategory> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VerbatimCategoryBOService.getVerbatimCategory';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.verbatim.entities.VerbatimCategory');
  }

  saveVerbatimCategory(category: VerbatimCategory): RpcRequestBuilder<VerbatimCategory> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'VerbatimCategoryBOService.saveVerbatimCategory';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(category, 'fr.hiji.metier.verbatim.entities.VerbatimCategory', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.verbatim.entities.VerbatimCategory');
  }

}
