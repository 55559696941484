import { Component, Input } from '@angular/core';
import { Criteria } from '../../../ic2/entities/Criteria';
import { CriteriaType } from '../../../ic2/entities/CriteriaType';

@Component({
    selector: 'hiji-translate-criteria',
    templateUrl: './translate-criteria.component.html',
    styleUrl: './translate-criteria.component.scss',
    standalone: false
})
export class TranslateCriteriaComponent {
  @Input()
  criteria: Criteria;
  @Input()
  sourceLang: string;
  @Input()
  destinationLang: string;
  @Input()
  textAreaRows: number = 5;
  @Input()
  alreadyExist: boolean;

  CriteriaType: typeof CriteriaType = CriteriaType;
}
