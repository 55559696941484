export class DateUtils {
  static isSameDay(date1: Date, date2: Date): boolean {
    if (!date1 || !date2) return false;
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  }

  static addDays(date: Date, nbDaysToAdd: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + nbDaysToAdd);
    return result;
  }

  //Passed
  static isPast(date: Date): boolean {
    if (date === null) return false;
    return date < new Date();
  }
  static isPastOrNow(date: Date): boolean {
    if (date === null) return false;
    return date <= new Date();
  }
  static isFuture(date: Date): boolean {
    if (date === null) return false;
    return date > new Date();
  }
  static isNowOrFuture(date: Date): boolean {
    if (date === null) return false;
    return date >= new Date();
  }

  static isToday(date: Date): boolean {
    if (date === null) return false;
    return DateUtils.isSameDay(date, new Date());
  }

  //isOutdated
  static isPastDay(date: Date) {
    if (date === null) return false;
    const copiedDate = new Date(date.getTime());
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    copiedDate.setHours(0, 0, 0, 0);
    return copiedDate < now;
  }
  //isFuture
  static isFutureDay(date: Date) {
    if (date === null) return false;
    const copiedDate = new Date(date.getTime());
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    copiedDate.setHours(0, 0, 0, 0);
    return copiedDate > now;
  }

  static isCurrentMonth(date: Date) {
    if (date === null) return true;
    if (date.getMonth() === new Date().getMonth() && date.getUTCFullYear() === new Date().getUTCFullYear()) return false;
    return true;
  }
}
