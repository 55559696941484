export class Config {
  production: boolean;
  rpcHost: string;
  foUrl: string;
  boUrl: string;
  publicFileUrl: string;
  themeColor: string;
  appTitle: string;
  logRpcRequests: boolean;
  defaultLanguage: string;
  availableLanguages: string[];
  vapidPublicKey: string;
  mailAuthentication: boolean = false;
  resetPasswordViaPinCode: boolean = false;
  css: string = null;
  localAccountLoginEnabled: boolean = true;
  signupEnabled: boolean = true;
  hasSSOOtherThanHiji: boolean = true;
  client: string = null;
  environment: string = null;

  //WARNING READ

  //IF YOU NEED TO ADD SOMETHING HERE IT IS BECAUSE IT IS NEEDED BEFORE THE USER CONNECTS
  //IF SO PLEASE ADD IT TO FrontConfigHandler IN THE SERVER PROJECT
}
