// fr.hiji.metier.referential.services.back.ImportExportRepositoryBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ImportExportRepositoryBODTO } from '../entities/ImportExportRepositoryBODTO';

@Injectable({
  providedIn: 'root',
})
export class ImportExportRepositoryBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  downloadModele(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportRepositoryBOService.downloadModele';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  importRepository(exec: boolean): RpcRequestBuilder<ImportExportRepositoryBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportRepositoryBOService.importRepository';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(exec, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.back.ImportExportRepositoryBODTO');
  }

  downloadRepository(idRepository: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportRepositoryBOService.downloadRepository';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idRepository, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
