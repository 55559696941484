import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultilingualString } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { ModalityBOService } from '../../ic2/services/ModalityBOService';
import { Ic2ToastrService } from '../../services/ic2-toastr.service';

@Component({
    selector: 'hiji-translate-img',
    templateUrl: './translate-img.component.html',
    styleUrl: './translate-img.component.scss',
    standalone: false
})
export class TranslateImgComponent {
  @Input() sourceLang: string;
  @Input() destinationLang: string;
  @Input() textAreaRows: number = 5;
  @Input() title: string;

  loadingUpload: boolean = false;

  private _translation: MultilingualString;

  @Input()
  get translation(): MultilingualString {
    return this._translation;
  }
  set translation(value: MultilingualString) {
    this._translation = value;
    this.translationChange.emit(value);
  }

  @Output()
  translationChange = new EventEmitter<MultilingualString>();

  constructor(
    private modalityBOService: ModalityBOService,
    private ic2ToastrService: Ic2ToastrService,
    public translate: TranslateService
  ) {}

  handleFileInput(event) {
    const files: FileList = event.target.files;
    const file = files[0];

    this.loadingUpload = true;
    this.modalityBOService
      .uploadImage()
      .withFile(file)
      .onErrorUseDefault()
      .onErrorAlwaysDo(() => (this.loadingUpload = false))
      .execute((publicUrlImage) => {
        this.loadingUpload = false;
        this.ic2ToastrService.showSuccess(this.translate.instant("common.translate-img.Upload de l'image réussi"));
        this.translation.obj[this.destinationLang] = publicUrlImage;
      });
  }
}
