// ENTITY fr.hiji.metier.certif.entities.session.CertificationSession generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationSession {

  idCertificationSession: number | null = 0;
  idCertification: number | null = 0;
  name: string | null = '';
  startDate: Date | null = null;
  endDate: Date | null = null;
  code: string | null = '';
  idOrganisationStorage: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<CertificationSession>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.session.CertificationSession'] = CertificationSession;

reverseMapping[CertificationSession.name] = 'fr.hiji.metier.certif.entities.session.CertificationSession';

fields['fr.hiji.metier.certif.entities.session.CertificationSession']  = {
    idCertificationSession: 'java.lang.Integer',
    idCertification: 'java.lang.Integer',
    name: 'java.lang.String',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    code: 'java.lang.String',
    idOrganisationStorage: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
