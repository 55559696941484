{{ 'common.languages.en' | translate }}
{{ 'common.languages.en-AUS_NZ' | translate }}
{{ 'common.languages.en-CA' | translate }}
{{ 'common.languages.en-SG' | translate }}
{{ 'common.languages.en-US' | translate }}
{{ 'common.languages.es-AR' | translate }}
{{ 'common.languages.es-ES' | translate }}
{{ 'common.languages.es-MX' | translate }}
{{ 'common.languages.da' | translate }}
{{ 'common.languages.de' | translate }}
{{ 'common.languages.de-CH' | translate }}
{{ 'common.languages.el' | translate }}
{{ 'common.languages.fi' | translate }}
{{ 'common.languages.fr' | translate }}
{{ 'common.languages.he' | translate }}
{{ 'common.languages.hr' | translate }}
{{ 'common.languages.hu' | translate }}
{{ 'common.languages.it' | translate }}
{{ 'common.languages.ja' | translate }}
{{ 'common.languages.ko' | translate }}
{{ 'common.languages.nb' | translate }}
{{ 'common.languages.nl' | translate }}
{{ 'common.languages.pl' | translate }}
{{ 'common.languages.pt-BR' | translate }}
{{ 'common.languages.pt-PT' | translate }}
{{ 'common.languages.ro' | translate }}
{{ 'common.languages.ru' | translate }}
{{ 'common.languages.sk' | translate }}
{{ 'common.languages.sl' | translate }}
{{ 'common.languages.sr' | translate }}
{{ 'common.languages.sv' | translate }}
{{ 'common.languages.th' | translate }}
{{ 'common.languages.tr' | translate }}
{{ 'common.languages.id' | translate }}
{{ 'common.languages.tk' | translate }}
{{ 'common.languages.uk' | translate }}
{{ 'common.languages.ar' | translate }}
{{ 'common.languages.bg' | translate }}
{{ 'common.languages.cs' | translate }}
{{ 'common.languages.zh-CN' | translate }}
{{ 'common.languages.zh-HK' | translate }}
{{ 'common.languages.zh-SG' | translate }}
{{ 'common.languages.zh-TW' | translate }}
{{ 'common.languages.vi' | translate }}

{{ 'common.enum.CriteriaType.Echelle binaire' | translate }}
{{ 'common.enum.CriteriaType.Echelle à 4 valeurs' | translate }}
{{ 'common.enum.CriteriaType.Echelle à 5 valeurs (Likert)' | translate }}
{{ 'common.enum.CriteriaType.NPS' | translate }}
{{ 'common.enum.CriteriaType.Question ouverte' | translate }}
{{ 'common.enum.CriteriaType.Echelle de 0 à 10' | translate }}

{{ 'common.enum.HighlightType.Geste clé' | translate }}

{{ 'common.enum.OpinionType.Collaborateur' | translate }}
{{ 'common.enum.OpinionType.Manager' | translate }}

{{ 'common.enum.Genre.Homme' | translate }}
{{ 'common.enum.Genre.Femme' | translate }}
{{ 'common.enum.Genre.Non précisé' | translate }}

{{ 'common.enum.CampaignType.Normal' | translate }}
{{ 'common.enum.CampaignType.Enquête' | translate }}
{{ 'common.enum.CampaignType.360' | translate }}
{{ 'common.enum.CampaignType.PDI' | translate }}

{{ "common.enum.HijiRelationUser.Dans l'équipe de" | translate }}
{{ 'common.enum.HijiRelationUser.Managé par' | translate }}
{{ "common.enum.HijiRelationUser.Partage l'équipe de" | translate }}
{{ 'common.enum.HijiRelationUser.En attente de validation' | translate }}
{{ 'common.enum.HijiRelationUser.reverse.Manager de' | translate }}
{{ "common.enum.HijiRelationUser.reverse.Membre d'équipe partagé" | translate }}
{{ 'common.enum.HijiRelationUser.reverse.Partage mon équipe' | translate }}
{{ 'common.enum.HijiRelationUser.reverse.En attente de validation' | translate }}

{{ 'common.enum.HijiRight.Admin' | translate }}
{{ 'common.enum.HijiRight.Externe' | translate }}
{{ 'common.enum.HijiRight.Gestionnaire' | translate }}
{{ 'common.enum.HijiRight.Utilisateur' | translate }}
{{ 'common.enum.HijiRight.Consultation Stats' | translate }}
{{ 'common.enum.HijiRight.Traducteur' | translate }}
{{ 'common.enum.HijiRight.Coach' | translate }}

{{ 'common.enum.RapportType.National' | translate }}
{{ 'common.enum.RapportType.Régional' | translate }}
{{ 'common.enum.RapportType.Établissement' | translate }}

{{ 'common.enum.InquiryTargetType.Collaborateur' | translate }}
{{ 'common.enum.InquiryTargetType.Pair' | translate }}
{{ 'common.enum.InquiryTargetType.Manager' | translate }}
{{ 'common.enum.InquiryTargetType.Externe' | translate }}

{{ 'common.enum.FrequenceRapport.hebdomadaire' | translate }}
{{ 'common.enum.FrequenceRapport.mensuel' | translate }}
{{ 'common.enum.PodiumType.Par campagne' | translate }}
{{ 'common.enum.PodiumType.Par référentiel' | translate }}

{{ 'common.enum.OrganisationType.Enseigne' | translate }}
{{ 'common.enum.OrganisationType.Société' | translate }}
{{ 'common.enum.OrganisationType.Pays' | translate }}
{{ 'common.enum.OrganisationType.Région' | translate }}
{{ 'common.enum.OrganisationType.Établissement' | translate }}
{{ 'common.enum.OrganisationType.Direction' | translate }}
{{ 'common.enum.OrganisationType.Service' | translate }}
{{ 'common.enum.OrganisationType.Autre' | translate }}

{{ 'common.enum.ModalityType.Quiz' | translate }}
{{ 'common.enum.ModalityType.Interview' | translate }}
{{ 'common.enum.ModalityType.Roleplay' | translate }}

{{ 'common.enum.QuestionType.QCM' | translate }}
{{ 'common.enum.QuestionType.Question ouverte' | translate }}

{{ 'common.enum.FormationSessionDatePeriod.Matin' | translate }}
{{ 'common.enum.FormationSessionDatePeriod.Après-midi' | translate }}

{{ 'common.enum.HijiEmail.SURVEY_LAUNCH' | translate }}
{{ 'common.enum.HijiEmail.SURVEY_FOLLOW_UP_START' | translate }}
{{ 'common.enum.HijiEmail.SURVEY_FOLLOW_UP_END' | translate }}
{{ 'common.enum.HijiEmail.SURVEY_FOLLOW_UP_LAST_DAY' | translate }}
{{ 'common.enum.HijiEmail.SURVEY_FOLLOW_UP_OVERTIME' | translate }}

{{ 'common.enum.HijiEmail.360_LANCEMENT_CAMPAGNE' | translate }}
{{ 'common.enum.HijiEmail.360_LANCEMENT_INQUIRY' | translate }}
{{ 'common.enum.HijiEmail.360_RELANCE_INQUIRY_NOT_DEPLOYED' | translate }}
{{ 'common.enum.HijiEmail.360_RELANCE_INQUIRY_NOT_AUTOOBS' | translate }}
{{ 'common.enum.HijiEmail.360_RELANCE_INQUIRY_TARGETS_NO_ANSWER' | translate }}
{{ 'common.enum.HijiEmail.360_INQUIRY_TARGET_ANSWER' | translate }}
{{ 'common.enum.HijiEmail.360_INQUIRY_END_NOT_DEPLOYED_ALERT' | translate }}
{{ 'common.enum.HijiEmail.360_INQUIRY_END_TARGET_NO_ANSWER_ALERT' | translate }}
{{ 'common.enum.HijiEmail.360_INQUIRY_END' | translate }}
{{ 'common.enum.HijiEmail.360_INQUIRY_TARGET_DEPLOYED' | translate }}

{{ 'common.enum.HijiEmail.MIF_PARTICIPANT_ACQUISITION_BEFORE' | translate }}
{{ 'common.enum.HijiEmail.MIF_RELANCE_PARTICIPANT_ACQUISITION_BEFORE' | translate }}
{{ 'common.enum.HijiEmail.MIF_MANAGER_FORMATION_INFOS' | translate }}
{{ 'common.enum.HijiEmail.MIF_TRAINER_FORMATION_INFOS' | translate }}
{{ 'common.enum.HijiEmail.MIF_PARTICIPANT_SATISFACTION' | translate }}
{{ 'common.enum.HijiEmail.MIF_RELANCE_PARTICIPANT_SATISFACTION' | translate }}
{{ 'common.enum.HijiEmail.MIF_PARTICIPANT_ACQUISITION_AFTER' | translate }}
{{ 'common.enum.HijiEmail.MIF_RELANCE_PARTICIPANT_ACQUISITION_AFTER' | translate }}
{{ 'common.enum.HijiEmail.MIF_MANAGER_AFTER_FORMATION' | translate }}
{{ 'common.enum.HijiEmail.MIF_PARTICIPANT_EVA_DEFINED' | translate }}
{{ 'common.enum.HijiEmail.MIF_MANAGER_EVA_DEFINED' | translate }}
{{ 'common.enum.HijiEmail.MIF_RELANCE_MANAGER_EVA' | translate }}
{{ 'common.enum.HijiEmail.MIF_LAST_RELANCE_MANAGER_EVA' | translate }}
{{ 'common.enum.HijiEmail.SURVEY_PARTICIPATION' | translate }}
{{ 'common.enum.HijiEmail.FORMATION_QUIZ_PARTICIPANT' | translate }}
{{ 'common.enum.HijiEmail.FORMATION_QUIZ_PARTICIPANT_RELANCE' | translate }}

{{ 'common.enum.GroupType.Équipe directe' | translate }}
{{ 'common.enum.GroupType.Équipe globale' | translate }}

{{ 'common.enum.FeedbackRecipientType.Équipe globale' | translate }}
{{ 'common.enum.FeedbackRecipientType.Équipe directe' | translate }}

{{ 'common.enum.InquiryTargetType.Collaborateur' | translate }}
{{ 'common.enum.InquiryTargetType.Pair' | translate }}
{{ 'common.enum.InquiryTargetType.Manager' | translate }}
{{ 'common.enum.InquiryTargetType.Externe' | translate }}

{{ 'common.enum.FormationSessionDatePeriod.Matin' | translate }}
{{ 'common.enum.FormationSessionDatePeriod.Après-midi' | translate }}

{{ 'common.enum.EMAOGAddReason.Geste clé' | translate }}
{{ 'common.enum.EMAOGAddReason.Choisi lors du dernier entretien' | translate }}
{{ 'common.enum.EMAOGAddReason.Geste maitrisé à confirmer' | translate }}
{{ 'common.enum.EMAOGAddReason.Référentiel métier' | translate }}

{{ 'common.enum.EMAOGRemoveReason.Maitrisé' | translate }}
{{ 'common.enum.EMAOGRemoveReason.Le geste sera utile plus tard' | translate }}
{{ 'common.enum.EMAOGRemoveReason.Le geste est non-applicable' | translate }}

{{ 'common.enum.EMAStep.Comment ça va ?' | translate }}
{{ 'common.enum.EMAStep.Résultats vs Objectifs' | translate }}
{{ 'common.enum.EMAStep.Objectifs futurs' | translate }}
{{ 'common.enum.EMAStep.Observations' | translate }}
{{ 'common.enum.EMAStep.Synthèse' | translate }}
{{ 'common.enum.EMAStep.Choix du référentiel' | translate }}
{{ 'common.enum.EMAStep.Prochain RDV' | translate }}
{{ 'common.enum.EMAStep.Done' | translate }}
{{ 'common.enum.EMAStep.Type' | translate }}

{{ 'common.enum.InquiryTargetType.Collaborateur' | translate }}
{{ 'common.enum.InquiryTargetType.Pair' | translate }}
{{ 'common.enum.InquiryTargetType.Manager' | translate }}
{{ 'common.enum.InquiryTargetType.Externe' | translate }}

{{ 'common.enum.SurveyReportGenerationType.Tous les rapports éligibles équipe globale vs global' | translate }}
{{ 'common.enum.SurveyReportGenerationType.Tous les rapports éligibles équipe globale vs organisation' | translate }}
{{ 'common.enum.SurveyReportGenerationType.Tous les rapports éligibles équipe directe vs global' | translate }}
{{ 'common.enum.SurveyReportGenerationType.Tous les rapports éligibles équipe directe vs organisation' | translate }}
{{ 'common.enum.SurveyReportGenerationType.Tous les rapports éligibles organisation vs global' | translate }}
{{ 'common.enum.SurveyReportGenerationType.Tous les rapports éligibles organisation vs organisation parente' | translate }}
{{ 'common.enum.SurveyReportGenerationType.Un rapport versus avec choix des filtres' | translate }}

{{ 'common.enum.FormationEventAnimationType.Plénière' | translate }}
{{ 'common.enum.FormationEventAnimationType.Atelier' | translate }}

{{ 'common.enum.FormationSessionQuizResultMode.SCORE' | translate }}
{{ 'common.enum.FormationSessionQuizResultMode.SCORE_ANSWERS' | translate }}
{{ 'common.enum.FormationSessionQuizResultMode.NO_RESULT' | translate }}
{{ 'common.enum.FormationSessionQuizResultMode.SCORE_ANSWERS_EXPLANATION' | translate }}

{{ 'common.enum.EMAObjectiveFormula.Résultat / Objectif' | translate }}
{{ 'common.enum.EMAObjectiveFormula.Objectif / Résultat' | translate }}

{{ 'common.enum.FormationState.À effectuer' | translate }}
{{ 'common.enum.FormationState.En cours' | translate }}
{{ 'common.enum.FormationState.Terminée' | translate }}
