import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserEditValidators } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { ResetPwdService } from '../../../ic2/services/ResetPwdService';
import { AuthService } from '../../../services/auth.service';
import { Ic2ToastrService } from '../../../services/ic2-toastr.service';
import { SignupComponent } from '../../signup/signup.component';

@Component({
    selector: 'hiji-reset-password-pincode',
    templateUrl: './reset-password-pincode.component.html',
    styleUrl: './reset-password-pincode.component.scss',
    standalone: false
})
export class ResetPasswordPincodeComponent {
  form: FormGroup = this.fb.group(
    {
      login: [null, [Validators.required]],
      pinCode: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.pattern(SignupComponent.passwordPattern)]],
      passwordConfirm: [null, [Validators.required, Validators.pattern(SignupComponent.passwordPattern)]],
    },
    {
      validator: UserEditValidators.checkPasswords('password', 'passwordConfirm'),
    }
  );
  loading: boolean = false;
  //error: boolean = false;

  constructor(
    private fb: FormBuilder,
    private resetPwdService: ResetPwdService,
    private router: Router,
    private authService: AuthService,
    private ic2ToastrService: Ic2ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.authService.logout();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    this.resetPwdService
      .changePasswordWithPinCode(this.form.value.login, this.form.value.pinCode, this.form.value.password)
      .defaultOnErrorAnd(() => {
        this.loading = false;
      })
      .execute((data) => {
        this.loading = false;
        this.router.navigate(['/login']);
        this.ic2ToastrService.showSuccess(this.translate.instant('common.reset-password-pincode.toast.Votre mot de passe a été modifié avec succès'));
      });
  }
}
