import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss'],
    standalone: false
})
export class NotFoundComponent {
  url;
  constructor(public route: ActivatedRoute) {
    this.url = window.location.pathname;
  }
}
