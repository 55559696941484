// ENTITY fr.hiji.metier.mif.dto.back.formation.FormationBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationFileBODTO } from './FormationFileBODTO';
import { FormationTrainerBODTO } from './FormationTrainerBODTO';
import { FormationTag } from './FormationTag';
import { Formation } from './Formation';

export class FormationBODTO {

  formation: Formation | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  deleteImage: Boolean | null = null;
  repositorySatisfactionParticipantName: MultilingualString | null = new MultilingualString();
  repositorySatisfactionParticipantDel: boolean | null = false;
  repositoryAcquisitionName: MultilingualString | null = new MultilingualString();
  repositoryAcquisitionDel: boolean | null = false;
  trainers: FormationTrainerBODTO[] | null = [];
  files: FormationFileBODTO[] | null = [];
  tags: FormationTag[] | null = [];
  searchLanguagesMatch: string[] | null = [];
  nbSessions: number | null = 0;

  constructor(init?:Partial<FormationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formation.FormationBODTO'] = FormationBODTO;

reverseMapping[FormationBODTO.name] = 'fr.hiji.metier.mif.dto.back.formation.FormationBODTO';

fields['fr.hiji.metier.mif.dto.back.formation.FormationBODTO']  = {
    formation: 'fr.hiji.metier.mif.entities.formation.Formation',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    deleteImage: 'java.lang.Boolean',
    repositorySatisfactionParticipantName: 'com.ic2.sc.MultilingualString',
    repositorySatisfactionParticipantDel: 'java.lang.Boolean',
    repositoryAcquisitionName: 'com.ic2.sc.MultilingualString',
    repositoryAcquisitionDel: 'java.lang.Boolean',
    trainers: 'java.util.List<fr.hiji.metier.mif.dto.back.formation.FormationTrainerBODTO>',
    files: 'java.util.List<fr.hiji.metier.mif.dto.back.formation.FormationFileBODTO>',
    tags: 'java.util.List<fr.hiji.metier.mif.entities.formation.FormationTag>',
    searchLanguagesMatch: 'java.util.List<java.lang.String>',
    nbSessions: 'java.lang.Integer'
};
