// ENTITY fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CertificationStatsTagBODTO } from './CertificationStatsTagBODTO';
import { CertificationStatsObersavableGestureBODTO } from './CertificationStatsObersavableGestureBODTO';
import { CertificationStatsModalityInfoBODTO } from './CertificationStatsModalityInfoBODTO';
import { CertificationVerbatimStatsBODTO } from './CertificationVerbatimStatsBODTO';
import { Criteria } from './Criteria';
import { InterviewStatsQuickQuestionBODTO } from './InterviewStatsQuickQuestionBODTO';

export class InterviewStatsBODTO {

  result: CertificationStatsModalityInfoBODTO | null = null;
  averageSkills: CertificationStatsTagBODTO[] | null = [];
  targetSkills: CertificationStatsTagBODTO[] | null = [];
  observableGestures: CertificationStatsObersavableGestureBODTO[] | null = [];
  verbatims: CertificationVerbatimStatsBODTO[] | null = [];
  criterias: Criteria[] | null = [];
  quickQuestion: InterviewStatsQuickQuestionBODTO[] | null = [];

  constructor(init?:Partial<InterviewStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsBODTO'] = InterviewStatsBODTO;

reverseMapping[InterviewStatsBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsBODTO']  = {
    result: 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityInfoBODTO',
    averageSkills: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.CertificationStatsTagBODTO>',
    targetSkills: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.CertificationStatsTagBODTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.certif.dto.back.CertificationStatsObersavableGestureBODTO>',
    verbatims: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.CertificationVerbatimStatsBODTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    quickQuestion: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsQuickQuestionBODTO>'
};
