// ENTITY fr.hiji.metier.user.dto.back.HijiUserReportSubscriptionBODTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { OrganisationType } from './OrganisationType';

export class HijiUserReportSubscriptionBODTO {

  idOrganisation: number | null = 0;
  organisationName: string | null = '';
  organisationType: OrganisationType | null = null;
  idReport180Config: number | null = 0;
  reportName: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;

  constructor(init?:Partial<HijiUserReportSubscriptionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.HijiUserReportSubscriptionBODTO'] = HijiUserReportSubscriptionBODTO;

reverseMapping[HijiUserReportSubscriptionBODTO.name] = 'fr.hiji.metier.user.dto.back.HijiUserReportSubscriptionBODTO';

fields['fr.hiji.metier.user.dto.back.HijiUserReportSubscriptionBODTO']  = {
    idOrganisation: 'java.lang.Integer',
    organisationName: 'java.lang.String',
    organisationType: 'fr.hiji.metier.reseau.entities.OrganisationType',
    idReport180Config: 'java.lang.Integer',
    reportName: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean'
};
