// fr.hiji.metier.mif.services.front.FormationTrainingPathServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationTrainingPathDTO } from '../entities/FormationTrainingPathDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationTrainingPathService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTrainingPaths(idUser: number): RpcRequestBuilder<FormationTrainingPathDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationTrainingPathService.getTrainingPaths';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
