// ENTITY fr.hiji.metier.referential.dto.back.NbOgsPerTagDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class NbOgsPerTagDTO {

  nbOgPerTag: number | null = 0;
  idTag: number | null = 0;

  constructor(init?:Partial<NbOgsPerTagDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.back.NbOgsPerTagDTO'] = NbOgsPerTagDTO;

reverseMapping[NbOgsPerTagDTO.name] = 'fr.hiji.metier.referential.dto.back.NbOgsPerTagDTO';

fields['fr.hiji.metier.referential.dto.back.NbOgsPerTagDTO']  = {
    nbOgPerTag: 'java.lang.Integer',
    idTag: 'java.lang.Integer'
};
