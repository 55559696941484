<hiji-wavy-container>
  <div class="container bg-white rounded shadow-2 py-3 px-0 px-md-3 m-3 mx-sm-auto">
    <div class="row justify-content-center align-items-center">
      <div class="container-fluid">
        <form #f1="ngForm" [formGroup]="formMail" (submit)="onSubmitEmail()" *ngIf="step === STEP_1">
          <div class="row">
            <div class="col text-center">
              <img class="p-3" style="width: 100px" src="../assets/logo.png" alt="logo" />
              <h5>{{ "common.signup.C'est votre première connexion ?" | translate }}</h5>
              <span class="mt-2">{{ 'common.signup.Créez vite votre compte pour commencer à' | translate }}</span>
              <br />
              <span>{{ 'common.signup.utiliser la plateforme hiji!' | translate }}</span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <input
                class="form-control"
                id="email"
                type="email"
                autocomplete="email"
                [placeholder]="'common.signup.Votre email' | translate"
                formControlName="email"
                aria-describedby="emailHelp"
              />
            </div>
          </div>

          <div class="row mt-4" *ngIf="loginError || (f1.submitted && !formMail.valid)">
            <div class="col">
              <ul class="errors">
                <li *ngIf="loginError">{{ loginError }}.</li>
                <li *ngIf="formMail.hasError('required', 'email')">{{ "common.signup.L'Email est requis" | translate }}</li>
                <li *ngIf="formMail.hasError('email', 'email')">{{ "common.signup.L'Email est invalide" | translate }}</li>
              </ul>
            </div>
          </div>
          <div class="row mt-4 justify-content-between">
            <div class="col-auto align-items-center d-flex">
              <a class="link-dark btn-link" type="button" type="reset" routerLink="/login">{{ 'common.signup.Annuler' | translate }}</a>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary text-uppercase" type="submit" type="submit" [disabled]="loading">
                {{ 'common.signup.Me connecter' | translate }}
                <i class="fa-light fa-spinner spinning" *ngIf="loading"></i>
              </button>
            </div>
          </div>
        </form>
        <form #f2="ngForm" [formGroup]="formUser" (submit)="onSubmitUser()" *ngIf="step === STEP_CREATE">
          <div class="row">
            <div class="col text-center">
              <img class="p-3" style="width: 100px" src="../assets/logo.png" alt="logo" />
              <h5>{{ "common.signup.C'est votre première connexion ?" | translate }}</h5>
              <span class="mt-2">{{ 'common.signup.Créez vite votre compte pour commencer à' | translate }}</span>
              <br />
              <span>{{ 'common.signup.utiliser la plateforme hiji!' | translate }}</span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <input
                class="form-control"
                id="lastName"
                type="text"
                autocomplete="given-name"
                [placeholder]="'common.signup.Votre nom' | translate"
                formControlName="lastName"
                (input)="formUser.value.lastName = formUser.value.lastName.toUpperCase()"
                aria-describedby="lastNameHelp"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <input
                class="form-control"
                id="firstName"
                type="text"
                autocomplete="family-name"
                placeholder="{{ 'common.signup.Votre prénom' | translate }}"
                formControlName="firstName"
                (input)="
                  formUser.value.firstName =
                    formUser.value === null ? null : formUser.value.firstName.charAt(0).toUpperCase() + formUser.value.firstName.slice(1).toLowerCase()
                "
                aria-describedby="firstNameHelp"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <div class="position-relative">
                <input
                  class="form-control input-with-icon"
                  id="password"
                  type="password"
                  [placeholder]="'common.signup.Votre mot de passe' | translate"
                  autocomplete="new-password"
                  formControlName="password"
                  #p
                />
                <div class="input-icon" (click)="p.type === 'password' ? (p.type = 'text') : (p.type = 'password')">
                  <i class="fa-light" [class.fa-eye]="p.type === 'text'" [class.fa-eye-slash]="p.type === 'password'"></i>
                </div>
              </div>
              <div
                class="my-fs-1"
                [class.text-danger]="formUser.get('password').touched && !formUser.hasError('required', 'password') && formUser.hasError('pattern', 'password')"
                [class.text-success]="
                  formUser.get('password').touched && !formUser.hasError('required', 'password') && !formUser.hasError('pattern', 'password')
                "
              >
                <i class="fa-light fa-info-circle"></i>
                {{
                  'common.signup.Au minimum 8 caractères avec au moins 3 des 4 critères suivants : 1 chiffre, 1 majuscule, 1 minuscule, un caractère spécial'
                    | translate
                }}
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <div class="position-relative">
                <input
                  class="form-control input-with-icon"
                  id="password2"
                  type="password"
                  [placeholder]="'common.signup.Confirmation de votre mot de passe' | translate"
                  autocomplete="new-password"
                  formControlName="confirmPassword"
                  #p2
                />
                <div class="input-icon" (click)="p2.type === 'password' ? (p2.type = 'text') : (p2.type = 'password')">
                  <i class="fa-light" [class.fa-eye]="p2.type === 'text'" [class.fa-eye-slash]="p2.type === 'password'"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4" *ngIf="loginError || (f2.submitted && !formUser.valid)">
            <div class="col">
              <ul class="errors">
                <li *ngIf="loginError">{{ loginError }}.</li>
                <li *ngIf="formUser.hasError('required', 'firstName')">{{ 'common.signup.Le nom est requis' | translate }}</li>

                <li *ngIf="formUser.hasError('required', 'lastName')">{{ 'common.signup.Le prénom est requis' | translate }}</li>

                <li *ngIf="formUser.hasError('pattern', 'password')">{{ 'common.signup.Le mot de passe ne respecte pas les contraintes' | translate }}</li>
                <li *ngIf="formUser.hasError('required', 'password')">{{ 'common.signup.Le mot de passe est requis' | translate }}</li>
                <li *ngIf="formUser.hasError('required', 'confirmPassword')">
                  {{ 'common.signup.La confirmation du mot de passe est requise' | translate }}
                </li>
                <li *ngIf="formUser.hasError('notSame')">{{ 'common.signup.Les mots de passe ne correspondent pas' | translate }}</li>
              </ul>
            </div>
          </div>
          <div class="row mt-4 justify-content-between">
            <div class="col-auto align-items-center d-flex">
              <a class="link-dark btn-link" type="button" type="reset" routerLink="/login">{{ 'common.signup.Annuler' | translate }}</a>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary text-uppercase" type="submit" type="submit" [disabled]="loading">
                {{ 'common.signup.Me connecter' | translate }}
                <i class="fa-light fa-spinner spinning" *ngIf="loading"></i>
              </button>
            </div>
          </div>
        </form>

        <form #f3="ngForm" [formGroup]="formPass" (submit)="onSubmitPwd()" *ngIf="step === STEP_PWD">
          <div class="row">
            <div class="col text-center">
              <img class="p-3" style="width: 100px" src="../assets/logo.png" alt="logo" />
              <h5>{{ 'common.signup.Bienvenue' | translate }} {{ data.firstName }} {{ data.lastName }}</h5>
              <div class="mt-2" *ngIf="data.fonctionName !== null && data.fonctionName !== ''">{{ data.fonctionName }}</div>
              <div class="mt-2" *ngIf="data.etablissementNames.length > 0">
                {{ getEtabs() }}
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <div class="position-relative">
                <input
                  class="form-control input-with-icon"
                  id="password"
                  type="password"
                  [placeholder]="'common.signup.Votre mot de passe' | translate"
                  autocomplete="new-password"
                  formControlName="password"
                  #p
                />
                <div class="input-icon" (click)="p.type === 'password' ? (p.type = 'text') : (p.type = 'password')">
                  <i class="fa-light" [class.fa-eye]="p.type === 'text'" [class.fa-eye-slash]="p.type === 'password'"></i>
                </div>
              </div>
              <div
                class="my-fs-1"
                [class.text-danger]="formPass.get('password').touched && !formPass.hasError('required', 'password') && formPass.hasError('pattern', 'password')"
                [class.text-success]="
                  formPass.get('password').touched && !formPass.hasError('required', 'password') && !formPass.hasError('pattern', 'password')
                "
              >
                <i class="fa-light fa-info-circle"></i>
                {{
                  'common.signup.Au minimum 8 caractères avec au moins 3 des 4 critères suivants : 1 chiffre, 1 majuscule, 1 minuscule, un caractère spécial'
                    | translate
                }}
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <div class="position-relative">
                <input
                  class="form-control input-with-icon"
                  id="password2"
                  type="password"
                  [placeholder]="'common.signup.Confirmation de votre mot de passe' | translate"
                  autocomplete="new-password"
                  formControlName="confirmPassword"
                  #p2
                />
                <div class="input-icon" (click)="p2.type === 'password' ? (p2.type = 'text') : (p2.type = 'password')">
                  <i class="fa-light" [class.fa-eye]="p2.type === 'text'" [class.fa-eye-slash]="p2.type === 'password'"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4" *ngIf="loginError || (f3.submitted && !formPass.valid)">
            <div class="col">
              <ul class="errors">
                <li *ngIf="formPass.hasError('minlength', 'password')">{{ 'common.signup.Le mot de passe doit faire 6 caractères minimum' | translate }}</li>
                <li *ngIf="formPass.hasError('pattern', 'password')">{{ 'common.signup.Le mot de passe ne respecte pas les contraintes' | translate }}</li>
                <li *ngIf="formPass.hasError('required', 'password')">{{ 'common.signup.Le mot de passe est requis' | translate }}</li>
                <li *ngIf="formPass.hasError('required', 'confirmPassword')">
                  {{ 'common.signup.La confirmation du mot de passe est requise' | translate }}
                </li>
                <li *ngIf="formPass.hasError('notSame')">{{ 'common.signup.Les mots de passe ne correspondent pas' | translate }}</li>
              </ul>
            </div>
          </div>
          <div class="row mt-4 justify-content-between">
            <div class="col-auto align-items-center d-flex">
              <a class="link-dark btn-link" type="button" type="reset" routerLink="/login">{{ 'common.signup.Annuler' | translate }}</a>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary text-uppercase" type="submit" type="submit" [disabled]="loading">
                {{ 'common.signup.Me connecter' | translate }}
                <i class="fa-light fa-spinner spinning" *ngIf="loading"></i>
              </button>
            </div>
          </div>
        </form>

        <div class="row mt-3">
          <div class="col">
            {{ 'common.signup.hiji vient de collecter des informations vous concernant, pour en savoir plus' | translate }}
            <a class="btn-link link-dark" [routerLink]="null" (click)="showPopup()">{{ 'common.signup.cliquez ici' | translate }}</a>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <a class="btn-link link-dark" routerLink="/out/gdpr">{{ 'common.signup.Politique de confidentialité' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</hiji-wavy-container>
