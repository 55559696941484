import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvService, L } from '@ic2/ic2-lib';
import { HijiBundle } from '../../ic2/entities/HijiBundle';
import { HijiRight } from '../../ic2/entities/HijiRight';
import { TeachOnMarsLoginService } from '../../ic2/services/TeachOnMarsLoginService';
import { AuthService } from '../../services/auth.service';
import { Config } from '../../tools/Config';

@Component({
  selector: 'hiji-tom-auth',
  templateUrl: './tom-auth.component.html',
  styleUrls: ['./tom-auth.component.scss'],
  standalone: false,
})
export class TomAuthComponent implements OnInit {
  error: boolean = false;
  errorNoRight: boolean = false;
  backoffice: boolean = false;
  joinCode: string = null;

  constructor(
    private route: ActivatedRoute,
    private teachOnMarsLoginService: TeachOnMarsLoginService,
    private authService: AuthService,
    private router: Router,
    public env: EnvService<Config>
  ) {
    this.backoffice = this.route.snapshot.data['backoffice'] ?? false;
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((data) => {
      if (data && this.authService.userBundle && (<HijiBundle>this.authService.userBundle.data).hijiUser.matricule === this.route.snapshot.params.learnerId) {
        console.log('already logged in with this learnerId');
        let redirectUrl = '/';
        if (localStorage.getItem('redirectUrl') !== null) {
          redirectUrl = localStorage.getItem('redirectUrl');
          localStorage.removeItem('redirectUrl');
        }
        if (this.route.snapshot.queryParams.joinCode) redirectUrl = '/join/' + this.route.snapshot.queryParams.joinCode;
        this.router.navigate([redirectUrl]);
      } else this.loginToM();
    });
  }

  loginToM() {
    this.authService.logout();
    const params = this.route.snapshot.queryParams;
    if (!params.learnerId || !params.token || !params.instance) {
      this.error = true;
      console.log('Missing parameters', params);
      return;
    }
    this.teachOnMarsLoginService
      .login(params.learnerId, params.token, params.instance, params.version ?? '')
      .onError((err) => {
        this.error = true;
        L.e(err);
      })
      .execute((data) => {
        if (this.backoffice) {
          const loggedIn = data.ub && AuthService.hasAccessToBackoffice(data.ub);
          if (!loggedIn) {
            this.errorNoRight = true;
            return;
          }
        }
        //console.log(data);
        this.authService.loginWith(
          data.ub,
          () => {
            let redirectUrl = '/';
            if (localStorage.getItem('redirectUrl') !== null) {
              redirectUrl = localStorage.getItem('redirectUrl');
              localStorage.removeItem('redirectUrl');
            }
            if (this.backoffice && AuthService.has(data.ub, HijiRight.CONSULTATION_STATS)) redirectUrl = '/campaign';
            if (this.backoffice && AuthService.has(data.ub, HijiRight.TRADUCTEUR)) redirectUrl = '/configuration/translation';
            if (this.route.snapshot.queryParams.joinCode) redirectUrl = '/join/' + this.route.snapshot.queryParams.joinCode;
            this.router.navigate([redirectUrl]);
          },
          false
        );
      });
  }
}
