// ENTITY com.ic2.entity.User generated by ic2 3.0.0-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class User {

  idUser: number | null = 0;
  login: string | null = '';
  password: string | null = '';
  email: string | null = '';
  firstName: string | null = '';
  lastName: string | null = '';
  loginAttemptsFailed: number | null = 0;
  lockedUntil: Date | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<User>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.entity.User'] = User;

reverseMapping[User.name] = 'com.ic2.entity.User';

fields['com.ic2.entity.User']  = {
    idUser: 'java.lang.Integer',
    login: 'java.lang.String',
    password: 'java.lang.String',
    email: 'java.lang.String',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    loginAttemptsFailed: 'java.lang.Integer',
    lockedUntil: 'java.time.Instant',
    del: 'java.lang.Boolean'
};
