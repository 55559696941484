// ENTITY fr.hiji.metier.certif.entities.modality.Modality generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ModalityType } from './ModalityType';

export class Modality {

  idModality: number | null = 0;
  type: ModalityType | null = null;
  name: MultilingualString | null = new MultilingualString();
  withInstructions: boolean | null = false;
  instructions: MultilingualString | null = new MultilingualString();
  needAccessGrantFromEvaluator: boolean | null = false;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<Modality>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.Modality'] = Modality;

reverseMapping[Modality.name] = 'fr.hiji.metier.certif.entities.modality.Modality';

fields['fr.hiji.metier.certif.entities.modality.Modality']  = {
    idModality: 'java.lang.Integer',
    type: 'fr.hiji.metier.certif.entities.modality.ModalityType',
    name: 'com.ic2.sc.MultilingualString',
    withInstructions: 'java.lang.Boolean',
    instructions: 'com.ic2.sc.MultilingualString',
    needAccessGrantFromEvaluator: 'java.lang.Boolean',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
