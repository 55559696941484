// ENTITY fr.hiji.metier.conf.dto.front.ModulesDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Module360DTO } from './Module360DTO';
import { ModuleEventDTO } from './ModuleEventDTO';
import { ModuleSurveyDTO } from './ModuleSurveyDTO';
import { ModuleFBSDTO } from './ModuleFBSDTO';
import { FrontConfigurationDTO } from './FrontConfigurationDTO';
import { ModuleGlobalDTO } from './ModuleGlobalDTO';
import { Module180DTO } from './Module180DTO';
import { ClientSpecificConfigDTO } from './ClientSpecificConfigDTO';
import { ModuleMIFDTO } from './ModuleMIFDTO';
import { ModuleEMADTO } from './ModuleEMADTO';
import { ModuleTMSDTO } from './ModuleTMSDTO';
import { BackConfigurationDTO } from './BackConfigurationDTO';

export class ModulesDTO {

  moduleGlobal: ModuleGlobalDTO | null = null;
  module180: Module180DTO | null = null;
  module360: Module360DTO | null = null;
  moduleSurvey: ModuleSurveyDTO | null = null;
  moduleFBS: ModuleFBSDTO | null = null;
  moduleMIF: ModuleMIFDTO | null = null;
  moduleEvent: ModuleEventDTO | null = null;
  moduleEMA: ModuleEMADTO | null = null;
  moduleTMS: ModuleTMSDTO | null = null;
  frontConfiguration: FrontConfigurationDTO | null = null;
  backConfiguration: BackConfigurationDTO | null = null;
  clientSpecificConfig: ClientSpecificConfigDTO | null = null;
  useCampaignAccessControl: boolean | null = false;
  moduleBilan: boolean | null = false;
  modulePDI: boolean | null = false;
  moduleCertification: boolean | null = false;
  moduleTeamBoard: boolean | null = false;

  constructor(init?:Partial<ModulesDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.front.ModulesDTO'] = ModulesDTO;

reverseMapping[ModulesDTO.name] = 'fr.hiji.metier.conf.dto.front.ModulesDTO';

fields['fr.hiji.metier.conf.dto.front.ModulesDTO']  = {
    moduleGlobal: 'fr.hiji.metier.conf.dto.front.submodules.ModuleGlobalDTO',
    module180: 'fr.hiji.metier.conf.dto.front.submodules.Module180DTO',
    module360: 'fr.hiji.metier.conf.dto.front.submodules.Module360DTO',
    moduleSurvey: 'fr.hiji.metier.conf.dto.front.submodules.ModuleSurveyDTO',
    moduleFBS: 'fr.hiji.metier.conf.dto.front.submodules.ModuleFBSDTO',
    moduleMIF: 'fr.hiji.metier.conf.dto.front.submodules.ModuleMIFDTO',
    moduleEvent: 'fr.hiji.metier.conf.dto.front.submodules.ModuleEventDTO',
    moduleEMA: 'fr.hiji.metier.conf.dto.front.submodules.ModuleEMADTO',
    moduleTMS: 'fr.hiji.metier.conf.dto.front.submodules.ModuleTMSDTO',
    frontConfiguration: 'fr.hiji.metier.conf.dto.front.submodules.FrontConfigurationDTO',
    backConfiguration: 'fr.hiji.metier.conf.dto.front.submodules.BackConfigurationDTO',
    clientSpecificConfig: 'fr.hiji.metier.conf.dto.front.submodules.ClientSpecificConfigDTO',
    useCampaignAccessControl: 'java.lang.Boolean',
    moduleBilan: 'java.lang.Boolean',
    modulePDI: 'java.lang.Boolean',
    moduleCertification: 'java.lang.Boolean',
    moduleTeamBoard: 'java.lang.Boolean'
};
