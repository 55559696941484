// fr.hiji.metier.campaign.services.back.survey.SurveyStatsBOServiceI generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:58:54 UTC 2025
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount } from '@ic2/ic2-lib';
import { SurveyStatsScoreByManagerBODTO } from '../entities/SurveyStatsScoreByManagerBODTO';
import { SurveyStatsScoreByOrganisationBODTO } from '../entities/SurveyStatsScoreByOrganisationBODTO';
import { SurveyReportFilter } from '../entities/SurveyReportFilter';
import { SurveyReportDTO } from '../entities/SurveyReportDTO';

@Injectable({
  providedIn: 'root',
})
export class SurveyStatsBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getVerbatims(filter: SurveyReportFilter, idObservableGesture: number, nb: number, page: number): RpcRequestBuilder<ListWithCount<String>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.getVerbatims';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nb, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  downloadVerbatimCSV(filter: SurveyReportFilter, idObservableGesture: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.downloadVerbatimCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getReportFor(filter: SurveyReportFilter): RpcRequestBuilder<SurveyReportDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.getReportFor';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reporting.dto.survey.SurveyReportDTO');
  }

  generateSurveyDataCSV(filter: SurveyReportFilter, locale: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.generateSurveyDataCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(locale, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  removeFixedData(filter: SurveyReportFilter): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.removeFixedData';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  fixData(filter: SurveyReportFilter): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.fixData';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  fixAllData(idCampaign: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.fixAllData';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getFilter1Values(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.getFilter1Values';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getFilter2Values(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.getFilter2Values';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getFilter3Values(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.getFilter3Values';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getScoreByOrganisation(idCampaign: number): RpcRequestBuilder<SurveyStatsScoreByOrganisationBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.getScoreByOrganisation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getScoreByOrganisationCsv(idCampaign: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.getScoreByOrganisationCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getScoreByManager(idCampaign: number): RpcRequestBuilder<SurveyStatsScoreByManagerBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.getScoreByManager';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getScoreByManagerCsv(idCampaign: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyStatsBOService.getScoreByManagerCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
