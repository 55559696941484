// ENTITY fr.hiji.metier.reporting.dto.back.UserOrganisationReportingEMADTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:01 UTC 2025

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { OrganisationReportingEMADTO } from './OrganisationReportingEMADTO';

export class UserOrganisationReportingEMADTO {

  userFuncAvatarDTO: UserFuncAvatarDTO | null = null;
  organisationReportingEMADTO: OrganisationReportingEMADTO[] | null = [];

  constructor(init?:Partial<UserOrganisationReportingEMADTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.UserOrganisationReportingEMADTO'] = UserOrganisationReportingEMADTO;

reverseMapping[UserOrganisationReportingEMADTO.name] = 'fr.hiji.metier.reporting.dto.back.UserOrganisationReportingEMADTO';

fields['fr.hiji.metier.reporting.dto.back.UserOrganisationReportingEMADTO']  = {
    userFuncAvatarDTO: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    organisationReportingEMADTO: 'java.util.List<fr.hiji.metier.reporting.dto.back.OrganisationReportingEMADTO>'
};
