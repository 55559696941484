// ENTITY fr.hiji.metier.campaign.dto.front.pdi.PDIDTO generated by ic2 0.0.1-SNAPSHOT at Thu Mar 20 09:59:02 UTC 2025

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class PDIDTO {

  idCampaign: number | null = 0;
  campaignName: MultilingualString | null = new MultilingualString();
  campaignDescription: MultilingualString | null = new MultilingualString();
  endDate: Date | null = null;
  startDate: Date | null = null;
  draft: boolean | null = false;
  idObservation: number | null = 0;
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDescription: MultilingualString | null = new MultilingualString();
  startAnsweringDate: Date | null = null;
  finishAnswering: boolean | null = false;
  idUserManager: number | null = 0;
  manager: string | null = '';

  constructor(init?:Partial<PDIDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.pdi.PDIDTO'] = PDIDTO;

reverseMapping[PDIDTO.name] = 'fr.hiji.metier.campaign.dto.front.pdi.PDIDTO';

fields['fr.hiji.metier.campaign.dto.front.pdi.PDIDTO']  = {
    idCampaign: 'java.lang.Integer',
    campaignName: 'com.ic2.sc.MultilingualString',
    campaignDescription: 'com.ic2.sc.MultilingualString',
    endDate: 'java.time.LocalDate',
    startDate: 'java.time.LocalDate',
    draft: 'java.lang.Boolean',
    idObservation: 'java.lang.Integer',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDescription: 'com.ic2.sc.MultilingualString',
    startAnsweringDate: 'java.time.Instant',
    finishAnswering: 'java.lang.Boolean',
    idUserManager: 'java.lang.Integer',
    manager: 'java.lang.String'
};
